import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { IEditablePage } from 'src/models/interfaces/web-services';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component( {
  selector: 'app-legal-notices',
  templateUrl: './legal-notices.component.html',
  styleUrls: [ './legal-notices.component.scss' ],
} )
export class LegalNoticesComponent {

  isLoadingLegal: boolean;
  currentLang: string;
  title: string;
  content: {
    fr: string;
    en: string;
  };

  termsAccepted = false;
  confidentialityAccepted = false;

  constructor(
    private modalCtrl: ModalController,
    private storage: StorageService,
    private translate: TranslateService
  ) {
    this.currentLang = this.translate.currentLang;
    this.recoverLegalNotices();
  }

  recoverLegalNotices(): void {
    this.isLoadingLegal = true;

    this.storage.listenLegalNotices.subscribe( ( legal: IEditablePage ) => {
      if ( !legal ) {
        // TODO Error when no legals are registered
        this.refused();
      } else {
        this.title = legal.title;

        this.content = legal.content[ this.currentLang ];
        this.isLoadingLegal = false;
      }
    },
      ( e: any ) => {
        this.isLoadingLegal = false;
        this.refused();
      } );
  }

  accepted(): void {
    this.storage.saveLegalAcceptance( true ).then( () => {
      this.modalCtrl.dismiss( {
        accepted: true
      } );
    }, () => {
      // TODO Error on save (on browser mainly)
      this.modalCtrl.dismiss( {
        accepted: true
      } );
    } );
  }

  private refused(): void {
    this.modalCtrl.dismiss( {
      accepted: false
    } );
  }

}
