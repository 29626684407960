import { Component, OnInit, Input } from '@angular/core';
import { IVac } from 'src/models/interfaces/vac';
import { IMap } from 'src/models/interfaces/map';
import { VACS_TYPES, MAP_STATUS } from 'src/models/enums';
import { Vac } from 'src/models/classes/vac';
import { TranslateService } from '@ngx-translate/core';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { PdfManagerService } from 'src/app/services/pdf-manager/pdf-manager.service';
import { Desubscribe } from 'src/models/classes/desubscribe';
import { CacheService } from 'src/app/services/cache/cache.service';
declare let cordova: any;

@Component( {
  selector: 'map-item',
  templateUrl: './map-item.component.html',
  styleUrls: [ './map-item.component.scss' ],
} )
export class MapItemComponent extends Desubscribe implements OnInit {

  static ITEM_HEIGHT = 45;

  VACS_TYPES = VACS_TYPES;

  vac: Vac;
  map: IMap;
  @Input() set vacItem( v: IVac ) {
    this.vac = new Vac( v, this.translate.currentLang );
    this.map = v.map;
  }

  isUpdating = false;
  onError = false;

  fromDllAll = MyMapsService.DLL_ALL_VERSION;

  constructor(
    private translate: TranslateService,
    private myMaps: MyMapsService,
    private cache: CacheService,
    private alerts: AlertManagerService,
    private network: NetworkService,
    private pdf: PdfManagerService ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.addSubscription( this.myMaps.getUpdatingByKeyO( this.vac.config.key ).subscribe( ( m: MAP_STATUS ) => {
      this.isUpdating = m === MAP_STATUS.START_UPDATE;
      this.onError = m === MAP_STATUS.STOPPED_ERROR || m === MAP_STATUS.STOPPED;
    } ) );
  }

  openMap(): void {
    // console.log( 'openMap : ' );
    if ( this.isUpdating || this.onError ) { return; }
    if ( this.vac.config.map && this.vac.config.map.downloadedMap && this.vac.config.map.downloadedMap.filePath ) {
      // console.log( 'openMap : ' + cordova.file.dataDirectory + this.vac.config.map.downloadedMap.fileName );
      /*this.vac.config.map.downloadedMap.filePath*/
      this.pdf.openPdf( {
        // XGOU : on utilise toujours le dataDirectory dans le cas de l'openMap d'un map-item
        filePath: cordova.file.dataDirectory + this.vac.config.map.downloadedMap.fileName,
        nameToDisplay: this.vac.config.map.fileName
      } );
    }
  }

  updateMap(): void {
    if ( !this.network.isConnected ) {
      this.alerts.genericNoInternet();
      return;
    }

    const vac: IVac = this.cache.getVac( this.vac.config.key );
    this.myMaps.downloadVac( vac, this.onError ? true : false );
  }

  get isFavorite(): boolean {
    return this.cache.isFavoriteMap( this.vac.config.key );
  }

  toggleFav(): void {
    this.myMaps.changeFav( this.vac.config.key, this.vac.config, !this.isFavorite );
  }

  deleteMap(): void {
    this.alerts.basicChoice( {
      header: 'ALERTS.SUPPRESS.ONE.TITLE',
      message: 'ALERTS.SUPPRESS.ONE.MESSAGE',
      confirm: {
        text: 'ALERTS.BASIC_CHOICE.DELETE',
        class: 'red'
      }
    } ).then( ( b: boolean ) => {
      if ( b ) { this.myMaps.changeDll( this.vac.config.key ); }
    } );
  }

}
