<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button fill="clear" (click)="close()">
        <ion-icon large slot="start" name="arrow-back"></ion-icon>
        <ion-label>{{'GENERAL.BACK' | translate}}</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary">{{'SETTINGS.TITLE' | translate}}</ion-title>
    <ion-menu-button expand="block" class="ion-float-right"></ion-menu-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-split-pane when="lg" contentId="main-settings">
    <ion-menu side="end" menuId="menu-settings" contentId="main-settings" swipe-gesture="true" max-edge-start="50">
      <ion-content id="choices">
        <ion-list class="choice" *ngFor="let c of choices" [ngClass]="{'active': c === activeChoice}">
          <ion-item fill="clear" (click)="toggleButton(c)">
            <ion-label>{{c | translate}}</ion-label>
          </ion-item>
        </ion-list>
        <div class="version">N&deg; interne : {{version}} {{env}}</div>
        <ion-button *ngIf="environment?.isDebug" (click)="onFakeData()">Fake new Data</ion-button>
      </ion-content>
    </ion-menu>

    <ion-nav id="main-settings">
      <div class="ion-page">
        <div class="content-container">
          <profil *ngIf="activeChoice === 'SETTINGS.PROFIL'">
          </profil>
          <editable-page *ngIf="activeChoice !== 'SETTINGS.PROFIL'" [title]="activeChoice" [page]="pages[activeChoice]">
          </editable-page>
        </div>
      </div>
    </ion-nav>
  </ion-split-pane>
</ion-content>