<ion-header>
  <ion-toolbar color="dark">
    <ion-title color="primary">
      {{ 'AZBA_POPUP.TITLE' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row class="description">
    {{
    'AZBA_POPUP.DESCRIPTION'
    | translate: { startDate: startDateDisplay, endDate: endDateDisplay }
    }}
  </ion-row>

  <ion-row>
    <ion-col size="10">
      <ion-row>
        <ion-col size="5">
          <label for="start-datetime-picker">{{
            'AZBA_POPUP.START_LABEL_SHORT' | translate
            }}</label>
        </ion-col>
        <ion-col size="7">
          <!--
              datetime-local is not supported on firefox 92 that can be dowloaded here for mac:
              https://ftp.mozilla.org/pub/firefox/releases/92.0/mac/en-US/

              see here how to support old browsers using the pattern attribute:
              https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#handling_browser_support
            -->
          <input #startInput type="datetime-local" pattern="[0-9]{1,2}/[0-9]{1,2}/[0-9]{4} [0-9]{2}:[0-9]{2}" required
            id="start-datetime-picker" name="start-datetime-picker" [value]="selectedRange.start" [min]="startRange"
            [max]="selectedRange.end" (change)="onSelectStart($event)" />
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="5">
          <label for="end-datetime-picker">{{
            'AZBA_POPUP.END_LABEL_SHORT' | translate
            }}</label>
        </ion-col>
        <ion-col size="7">
          <input #endInput type="datetime-local" pattern="[0-9]{1,2}/[0-9]{1,2}/[0-9]{4} [0-9]{2}:[0-9]{2}" required
            id="end-datetime-picker" name="end-datetime-picker" [value]="selectedRange.end" [min]="selectedRange.start"
            [max]="endRange" (change)="onSelectEnd($event)" />
        </ion-col>
      </ion-row>
      <ion-row style="flex-direction: column" *ngIf="!isDatetimeSupported && !isValid && !isTimeSlotValid">
        <div style="color: red">{{ 'AZBA_POPUP.VALIDATION1' | translate }}</div>
      </ion-row>
      <ion-row style="flex-direction: column" *ngIf="!isDatetimeSupported && !isValid && !isTimeFormatValid">
        <div style="color: red">{{ 'AZBA_POPUP.VALIDATION2' | translate }}</div>
      </ion-row>
      <ion-row style="flex-direction: column" *ngIf="!isDatetimeSupported && !isValid && !isTimeEndBeforeDebutValid">
        <div style="color: red; text-align: center;">{{ 'AZBA_POPUP.VALIDATION3' | translate }}</div>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer>
  <div class="done">
    <ion-button (click)="cancel()">
      {{ 'AZBA_POPUP.CANCEL' | translate }}
    </ion-button>
    <ion-button (click)="select()" [disabled]="!isValid">
      {{ 'AZBA_POPUP.DONE' | translate }}
    </ion-button>
  </div>
</ion-footer>