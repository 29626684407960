import { Injectable } from '@angular/core';
import { ICoords } from 'src/models/interfaces/ground';
import * as turf from '@turf/turf';
import polylabel from 'polylabel';

@Injectable( {
  providedIn: 'root',
} )
export class UtilsService {
  constructor() { }

  static formatCoord( coord: any, type: string ): string {
    try {
      const letter = coord[ coord.length - 1 ];

      let degrees: string;
      let minutes: string;
      let seconds: string;

      if ( type === 'Lon' ) {
        degrees = coord.slice( 0, 3 ) + '°';
        minutes = coord.slice( 3, 5 ) + '\'';
        seconds = coord.slice( 5, 7 ) + '\'\'';
      } else {
        degrees = coord.slice( 0, 2 ) + '°';
        minutes = coord.slice( 2, 4 ) + '\'';
        seconds = coord.slice( 4, 6 ) + '\'\'';
      }
      return degrees + ' ' + minutes + ' ' + seconds + ' ' + letter;
    }
    catch
    {
      return 'N/A';
    }
  }

  deepCopy( o: Object ): any {
    return JSON.parse( JSON.stringify( o ) );
  }

  octetToMo( octets: number ): number {
    return Number.parseFloat( ( octets / 1048576 ).toFixed( 2 ) );
  }

  latLngToCoords( latLng: L.LatLng ): ICoords {
    return { lat: latLng.lat, lon: latLng.lng };
  }

  latLngExpressionToCoords( latLng: L.LatLngExpression ): ICoords {
    return { lat: latLng[ 0 ], lon: latLng[ 1 ] };
  }

  coordsToLatLngExpression( coords: ICoords ): L.LatLngExpression {
    return [ coords.lat, coords.lon ];
  }

  coordsToTurf( A: ICoords ): [ number, number ] {
    return [ A.lon, A.lat ];
  }

  turfToCoord( A: [ number, number ] | turf.Position ): ICoords {
    return { lat: A[ 1 ], lon: A[ 0 ] };
  }

  turfToLatLng( A: number[] ): [ number, number ] {
    return [ A[ 1 ], A[ 0 ] ];
  }

  latLngToTurf( A: [ number, number ] ): number[] {
    return [ A[ 1 ], A[ 0 ] ];
  }

  boundingBox(
    coords: ICoords[],
    extraLength: number = 0
  ): { minLat: number; maxLat: number; minLon: number; maxLon: number } {
    const result = { minLat: 1000, maxLat: -1000, minLon: 1000, maxLon: -1000 };

    coords.forEach( ( c: ICoords ) => {
      result.minLat = Math.min( result.minLat, c.lat );
      result.maxLat = Math.max( result.maxLat, c.lat );
      result.minLon = Math.min( result.minLon, c.lon );
      result.maxLon = Math.max( result.maxLon, c.lon );
    } );

    result.maxLat += extraLength;
    result.minLat -= extraLength;
    result.maxLon += extraLength;
    result.minLon -= extraLength;

    return result;
  }

  /**
   * Create a new polygon sized on a previous one
   * @param coords
   * @param ratio
   */
  scalePolygon( coords: ICoords[], ratio: number = 0.9 ): ICoords[] {
    const newCoords: ICoords[] = [];
    const findNewCoordinate = (
      scale: number[][],
      pro: number[][]
    ): number[][] => {
      const temp: number[][] = [ [ 0 ], [ 0 ] ];
      for ( let i = 0; i < 2; i++ ) {
        for ( let j = 0; j < 1; j++ ) {
          for ( let k = 0; k < 2; k++ ) {
            temp[ i ][ j ] += scale[ i ][ k ] * pro[ k ][ j ];
          }
        }
      }
      pro[ 0 ][ 0 ] = temp[ 0 ][ 0 ];
      pro[ 1 ][ 0 ] = temp[ 1 ][ 0 ];
      return pro;
    };

    // Initializing the Scaling Matrix.
    const s: number[][] = [
      [ ratio, 0 ],
      [ 0, ratio ],
    ];
    let p: number[][] = [ [], [] ];
    const center = { lat: 0, lon: 0 };
    coords.forEach( ( c: ICoords ) => {
      p[ 0 ][ 0 ] = c.lat;
      p[ 1 ][ 0 ] = c.lon;
      center.lat += c.lat;
      center.lon += c.lon;

      p = findNewCoordinate( s, p );

      newCoords.push( { lat: p[ 0 ][ 0 ], lon: p[ 1 ][ 0 ] } );
    } );
    center.lat /= coords.length;
    center.lon /= coords.length;

    // Center the polygon on the center of the first one
    return this.centerPolygon( center, newCoords );
  }

  /**
   * Center a polygon to a new center point
   * @param center
   * @param poly
   */
  centerPolygon( center: ICoords, poly: ICoords[] ): ICoords[] {
    const newCoords: ICoords[] = [];
    const centerOldCoords = { lat: 0, lon: 0 };
    poly.forEach( ( c: ICoords ) => {
      centerOldCoords.lat += c.lat;
      centerOldCoords.lon += c.lon;
    } );
    centerOldCoords.lat /= poly.length;
    centerOldCoords.lon /= poly.length;

    const diffOldNewCenter = {
      lat: center.lat - centerOldCoords.lat,
      lon: center.lon - centerOldCoords.lon,
    };

    poly.forEach( ( c: ICoords ) => {
      newCoords.push( {
        lat: c.lat + diffOldNewCenter.lat,
        lon: c.lon + diffOldNewCenter.lon,
      } );
    } );

    return newCoords;
  }

  /**
   * Using bisectrice equation, create equal length segment from each point of a polygon to create a sub polygon
   *
   * @param {ICoords[]} coords
   * @param {number} [length=0.015]
   * @returns {ICoords[]}
   * @memberof UtilsService
   */
  scaleEqualPolygon( coords: ICoords[], length: number = 0.75 ): ICoords[] {
    const newCoords: ICoords[] = [];

    const center: ICoords = { lat: 0, lon: 0 };
    const turfCoordinates = [];
    coords.forEach( ( c: ICoords ) => {
      center.lat += c.lat;
      center.lon += c.lon;

      turfCoordinates.push( this.coordsToTurf( c ) );
    } );
    center.lat /= coords.length;
    center.lon /= coords.length;

    // let intersections: turf.Feature<turf.LineString>[] = [];
    const polygonTurf = turf.lineString( turfCoordinates );
    coords.forEach( ( c: ICoords, index: number ) => {
      const A = c;
      const B = coords[ index === 0 ? coords.length - 1 : index - 1 ];
      const C = coords[ index === coords.length - 1 ? 0 : index + 1 ];

      ///
      // Fonction de calcul de droite bissectrice
      // const alpha = ( C.lat - A.lat ) * this.distancePoints( A, B ) - ( B.lat - A.lat ) * this.distancePoints( A, C );
      // const beta = ( C.lon - A.lon ) * this.distancePoints( A, B ) - ( B.lon - A.lon ) * this.distancePoints( A, C );

      // const tempNewPoint = { lat: A.lat + 1, lon: ( -alpha * ( A.lat + 1 ) + alpha * A.lat + beta * A.lon ) / beta };

      // if ( Number.isNaN( tempNewPoint.lat ) || tempNewPoint.lat == Infinity || tempNewPoint.lat == -Infinity ) tempNewPoint.lat = 0;
      // if ( Number.isNaN( tempNewPoint.lon ) || tempNewPoint.lon == Infinity || tempNewPoint.lon == -Infinity ) tempNewPoint.lon = 0;

      // // Two points( can be on the right side of the segment, or on the left side )
      // const new1 = this.pointOnSegmentAB( A, tempNewPoint, -length );
      // const new2 = this.pointOnSegmentAB( A, tempNewPoint, length );
      // if ( this.isPointInsidePolygon( new2, coords ) ) {
      //   newCoords.push( new2 );
      // } else {
      //   newCoords.push( new1 );
      // }
      ///

      ///
      const turfA = this.coordsToTurf( A );
      const turfB = this.coordsToTurf( B );
      const turfC = this.coordsToTurf( C );

      let bearingAB = turf.bearing( turfA, turfB );
      let bearingAC = turf.bearing( turfA, turfC );
      // Convert from -180 to 0 to 180 TO 0 to 360
      if ( bearingAB < 0 ) {
        bearingAB = 360 + bearingAB;
      }
      if ( bearingAC < 0 ) {
        bearingAC = 360 + bearingAC;
      }
      let bearing1 = ( bearingAB + bearingAC ) / 2;
      let bearing2 = bearing1 - 180;

      if ( bearing2 < 0 ) {
        bearing2 = 360 + bearing2;
      }

      // Convert invert
      if ( bearing1 > 180 ) {
        bearing1 = bearing1 - 360;
      }
      if ( bearing2 > 180 ) {
        bearing2 = bearing2 - 360;
      }

      // Check if we are drawning on the right side of the polygon
      const testBearing1 = this.turfToCoord(
        turf.destination( turfA, 0.01, bearing1 ).geometry.coordinates
      );
      let bearingToKeep = bearing1;
      if ( !this.isPointInsidePolygon( testBearing1, coords ) ) {
        bearingToKeep = bearing2;
      }
      let turfNewPoint = turf.destination( turfA, length, bearingToKeep );
      // Check if we don't overdraw
      const lineIntersect = turf.lineString( [
        turfA,
        turfNewPoint.geometry.coordinates,
      ] );
      const intersect = turf.lineIntersect( lineIntersect, polygonTurf );

      if ( intersect.features && intersect.features.length === 2 ) {
        intersect.features.forEach( ( f: turf.Feature<turf.Point> ) => {
          if (
            f.geometry.coordinates[ 0 ] !== turfA[ 0 ] &&
            f.geometry.coordinates[ 1 ] !== turfA[ 1 ]
          ) {
            turfNewPoint = f; // turf.midpoint( f, turfA );
          }
        } );
      }
      newCoords.push( this.turfToCoord( turfNewPoint.geometry.coordinates ) );

      //   // Deja teste si les nnewCoords commentés en haut sont au bon endroit puis si les lignes créées sont correctes
    } );

    return newCoords;
  }

  scaleTurfPolygon( coords: ICoords[], length: number = 0.75 ): ICoords[] {
    if ( coords.length < 2 ) { return coords; }

    const turfsCoords = [ [] ];

    coords.forEach( ( c: ICoords ) => {
      turfsCoords[ 0 ].push( this.coordsToTurf( c ) );
    } );
    if ( coords.length > 2 ) { turfsCoords[ 0 ].push( this.coordsToTurf( coords[ 0 ] ) ); }

    const poly =
      coords.length > 2
        ? turf.polygon( turfsCoords )
        : turf.lineString( turfsCoords[ 0 ] );
    const scaledPoly = turf.transformScale( poly, length );

    const result = [];
    if ( coords.length > 2 ) {
      (
        scaledPoly as turf.helpers.Feature<turf.helpers.Polygon>
      ).geometry.coordinates.forEach( ( c: turf.Position[] ) => {
        c.forEach( ( p: turf.Position ) => {
          result.push( this.turfToCoord( p ) );
        } );
      } );
    } else {
      (
        scaledPoly as turf.helpers.Feature<turf.helpers.LineString>
      ).geometry.coordinates.forEach( ( c: turf.Position ) => {
        result.push( this.turfToCoord( c ) );
      } );
    }

    return result;
  }

  distancePoints( A: ICoords, B: ICoords ): number {
    return turf.distance( this.coordsToTurf( A ), this.coordsToTurf( B ) );
    // return Math.sqrt( ( Math.pow( A.lat - B.lat, 2 ) + Math.pow( A.lon - B.lon, 2 ) ) );
  }

  pointOnSegmentAB( A: ICoords, B: ICoords, length: number ): ICoords {
    const aTurf = this.coordsToTurf( A );
    const bTurf = this.coordsToTurf( B );
    const bearing = turf.bearing( aTurf, bTurf );
    const point = turf.destination( aTurf, length, bearing );
    return this.turfToCoord( point.geometry.coordinates );

    // const destination = turf.along( turf.lineString( [ this.coordsToTurf( A ), this.coordsToTurf( B ) ] ), length );
    // return this.turfToCoord( destination.geometry.coordinates );

    // const distAB = this.distancePoints( A, B );
    // return {
    //   lat: A.lat + ( length * ( B.lat - A.lat ) / distAB ),
    //   lon: A.lon + ( length * ( B.lon - A.lon ) / distAB )
    // }
  }

  pointOnBearing( A: ICoords, bearing: number, length: number ): ICoords {
    const aTurf = this.coordsToTurf( A );
    const turfResult = turf.destination( aTurf, length, bearing );

    return this.turfToCoord( turfResult.geometry.coordinates );
  }

  polylabel( coords: ICoords[] ): ICoords {
    const turfCoords: [ number, number ][] = [];
    coords.forEach( ( c: ICoords ) => {
      turfCoords.push( this.coordsToTurf( c ) );
    } );
    turfCoords.push( turfCoords[ 0 ] );

    const p = polylabel( [ turfCoords ], 1 );
    return this.turfToCoord( p );
  }

  centroidOfPolygons( coords: ICoords[] ): ICoords {
    const turfCoords: [ number, number ][] = [];
    coords.forEach( ( c: ICoords ) => {
      turfCoords.push( this.coordsToTurf( c ) );
    } );
    turfCoords.push( turfCoords[ 0 ] );

    const polygon = turf.polygon( [ turfCoords ] );
    const centroid = turf.centroid( polygon );
    return this.turfToCoord( centroid.geometry.coordinates );
  }

  // pointOnDroiteDistanceA( A: ICoords, distance: number, eqDroite: { a: number, b: number, k?: number } ): ICoords {
  //   if ( eqDroite.k !== null && eqDroite.k !== undefined ) {
  //     return { lat: A.lat + distance, lon: A.lon }
  //   }
  //   const B: ICoords = { lat: A.lat + 1, lon: ( eqDroite.a * ( A.lat + 1 ) ) + eqDroite.b };
  //   return this.pointOnSegmentAB( A, B, distance );
  // }

  // anglesOnTriangle( A: ICoords, B: ICoords, C: ICoords ): { angleA: number, angleB: number, angleC: number } {
  //   const calculateAngle = ( a: ICoords, b: ICoords, c: ICoords ): number => {
  //     const distOpposedA = this.distancePoints( b, c );
  //     const distOpposedB = this.distancePoints( a, c );
  //     const distOpposedC = this.distancePoints( b, a );
  //     return ( Math.acos( ( Math.pow( distOpposedB, 2 ) + Math.pow( distOpposedC, 2 ) - Math.pow( distOpposedA, 2 ) ) / ( 2 * distOpposedB * distOpposedC ) ) ) * ( 180 / Math.PI );
  //   };

  //   const angleA = calculateAngle( A, B, C );
  //   const angleB = calculateAngle( B, C, A );
  //   const angleC = calculateAngle( C, A, B );

  //   return { angleA, angleB, angleC };
  // }

  isPointInsidePolygon( point: ICoords, polygon: ICoords[] ): boolean {
    // let isInside = false;
    // let minX = polygon[ 0 ].lat, maxX = polygon[ 0 ].lat;
    // let minY = polygon[ 0 ].lat, maxY = polygon[ 0 ].lon;
    // for ( let n = 1; n < polygon.length; n++ ) {
    //   let q = polygon[ n ];
    //   minX = Math.min( q.lat, minX );
    //   maxX = Math.max( q.lat, maxX );
    //   minY = Math.min( q.lon, minY );
    //   maxY = Math.max( q.lon, maxY );
    // }

    // if ( point.lat < minX || point.lat > maxX || point.lon < minY || point.lon > maxY ) {
    //   return false;
    // }

    // for ( let i = 0, j = polygon.length - 1; i < polygon.length; j = i++ ) {
    //   if ( ( polygon[ i ].lon > point.lon ) != ( polygon[ j ].lon > point.lon ) &&
    //     point.lat < ( polygon[ j ].lat - polygon[ i ].lat ) * ( point.lon - polygon[ i ].lon ) / ( polygon[ j ].lon - polygon[ i ].lon ) + polygon[ i ].lat ) {
    //     isInside = !isInside;
    //   }
    // }

    // return isInside;

    ////////////////////////
    const x = point.lat,
      y = point.lon;

    let inside = false;
    for ( let i = 0, j = polygon.length - 1; i < polygon.length; j = i++ ) {
      const xi = polygon[ i ].lat,
        yi = polygon[ i ].lon;
      const xj = polygon[ j ].lat,
        yj = polygon[ j ].lon;

      const intersect =
        yi > y !== yj > y && x < ( ( xj - xi ) * ( y - yi ) ) / ( yj - yi ) + xi;
      if ( intersect ) { inside = !inside; }
    }

    return inside;
  }

  // // k pour les droites // aux ordonnées
  // equationDroite( A: ICoords, B: ICoords ): { a: number, b: number, k?: number } {
  //   if ( A.lon === B.lon ) {
  //     return { a: 0, b: 0, k: A.lon }
  //   }
  //   let a = ( B.lon - A.lon ) / ( B.lat - A.lat );
  //   if ( Number.isNaN( a ) || a === Infinity || a === -Infinity ) { a = 0; }
  //   let b = A.lon - ( a * A.lat );
  //   return { a, b };
  // }

  // equationDroitePerpendiculaire( A: ICoords, eqDroite: { a: number, b: number, k?: number } ): { a: number, b: number, k?: number } {
  //   if ( eqDroite.k !== null && eqDroite.k !== undefined ) {
  //     return { a: 0, b: A.lat }
  //   }
  //   if ( eqDroite.a === 0 ) {
  //     return { a: 0, b: 0, k: A.lat }
  //   }
  //   let a = -1 / eqDroite.a;
  //   if ( Number.isNaN( a ) || a === Infinity || a === -Infinity ) { a = 0; }
  //   let b = A.lon - ( a * A.lat );
  //   return { a, b };
  // }

  pad( num: number ) {
    if ( num < 10 ) {
      return '0' + num;
    }
    return num;
  }

  nowUTC() {
    const date = new Date();
    return (
      date.getUTCFullYear() +
      '-' +
      this.pad( date.getUTCMonth() + 1 ) +
      '-' +
      this.pad( date.getUTCDate() ) +
      'T' +
      this.pad( date.getUTCHours() ) +
      ':' +
      this.pad( date.getUTCMinutes() ) +
      ':00+00:00'
    );
  }

  dateUTC( date: Date ) {
    return (
      date.getUTCFullYear() +
      '-' +
      this.pad( date.getUTCMonth() + 1 ) +
      '-' +
      this.pad( date.getUTCDate() ) +
      'T' +
      this.pad( date.getUTCHours() ) +
      ':' +
      this.pad( date.getUTCMinutes() ) +
      ':00'
    );
  }
}
