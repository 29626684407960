import { Injectable } from '@angular/core';
import { IVac } from 'src/models/interfaces/vac';
import { ICoords } from 'src/models/interfaces/ground';

@Injectable( {
  providedIn: 'root'
} )
export class DistanceService {
  private static EARTH_RADIUS = 6371;
  private static MILES_TO_KM = 1.852;
  constructor() {
  }

  // Converts from degrees to radians.
  private radians( degrees: number ) {
    return degrees * Math.PI / 180;
  }

  // Converts from radians to degrees.
  private degrees( radians: number ) {
    return radians * 180 / Math.PI;
  }

  calculateHaversineDistanceVacs( vacA: IVac, vacB: IVac ): number {
    if ( !vacA.ground || !vacB.ground ) return 0;
    return this.calculateHaversinDistance( vacA.ground.coords, vacB.ground.coords );
  }

  /**
   * Return the haversine distance between two points
   *
   * @private
   * @param {ICoords} coordA
   * @param {ICoords} coordB
   * @returns {number}
   * @memberof AroundSearchProvider
   */
  calculateHaversinDistance( coordA: ICoords, coordB: ICoords, miles: boolean = true ): number {

    const coordARad = {
      lat: this.radians( coordA.lat ),
      lon: this.radians( coordA.lon )
    };

    const coordBRad = {
      lat: this.radians( coordB.lat ),
      lon: this.radians( coordB.lon )
    };

    const radius = 2 * DistanceService.EARTH_RADIUS;

    const firstSinus = Math.pow( Math.sin( ( ( coordBRad.lat - coordARad.lat ) / 2 ) ), 2 );
    const firstCos = Math.cos( coordARad.lat );
    const secondCos = Math.cos( coordBRad.lat );
    const secondSinus = Math.pow( Math.sin( ( ( coordBRad.lon - coordARad.lon ) / 2 ) ), 2 );

    let distance = radius * Math.asin( Math.sqrt( firstSinus + ( firstCos * secondCos * secondSinus ) ) );

    if ( miles ) {
      distance = distance / DistanceService.MILES_TO_KM;
    }

    return distance;
  }
}
