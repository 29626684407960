import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Range } from '../../../../models/interfaces/range';
import { AppearMenuComponent } from '../appear-menu.component';
import { TranslateService } from '@ngx-translate/core';
import { AzbaManagerService } from 'src/app/services/azba/azba-manager.service';
import { ModalManagerService } from './../../../services/modal-manager/modal-manager.service';

@Component({
  selector: 'azba-menu',
  templateUrl: './azba-menu.component.html',
  styleUrls: ['./azba-menu.component.scss'],
})
export class AzbaMenuComponent extends AppearMenuComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private azbaManager: AzbaManagerService,
    private modalManagerService: ModalManagerService
  ) {
    super();
  }

  public availableRange: Range = this.azbaManager.availableRange;

  public current: Range = this.azbaManager;

  public env: any = environment;

  monthNames: string[] = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  pickerOptions: any = {
    cssClass: ['azba-date-picker'],
  };

  public isDesktopPlatform: Boolean = false;

  ngOnInit() {}

  /**
   * Reset filters and close menu
   *
   * @memberof GroundsMenuComponent
   */
  return(): void {
    super.closeFilters();
  }

  onSelect(data: CustomEvent, type: string) {
    let date: string = data.detail.value;

    if (type == 'start') {
      this.azbaManager.start = date;
    } else if (type == 'end') {
      this.azbaManager.end = date;
    }
  }

  async openDatesPopup() {
    const result = await this.modalManagerService.presentDesktopAzbaDates({
      availableRange: this.availableRange,
      current: { start: this.current.start, end: this.current.end },
    });
    if (result !== null && result.data !== null) {
      this.azbaManager.currentRange = result.data;
    }
  }
}
