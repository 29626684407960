import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'orderByParam'
} )
export class OrderByParamPipe implements PipeTransform {

  /**
   * Return, from an array of values, all the value sorted by one of their params
   *
   * @param {any[]} value
   * @param {...any[][]} args
   * @returns {*}
   * @memberof FilterByPipe
   */
  transform( values: any[], ...args: any[] ): any {
    if ( !values ) { return values; }

    const attributesToSortOn = args[ 0 ];

    values.sort( ( a: any, b: any ) => {
      return a[ attributesToSortOn ] > b[ attributesToSortOn ] ? 1 : -1;
    } );

    return values;
  }
}
