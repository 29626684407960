import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MyMapsComponent } from './my-maps.component';
import { MapItemComponent } from './map-item/map-item.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule( {
    declarations: [
        MyMapsComponent,
        MapItemComponent
    ],
    entryComponents: [
        MyMapsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        PipesModule,
        ScrollingModule
    ],
    providers: [
    ],
    exports: [
        MyMapsComponent,
        MapItemComponent
    ]
} )
export class MyMapsModule { }
