import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Desubscribe } from 'src/models/classes/desubscribe';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { IVac, key } from 'src/models/interfaces/vac';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { MapToArrayPipe } from 'src/app/pipes/map-to-array/map-to-array.pipe';
import { AttributeContainsPipe } from 'src/app/pipes/attribute-contains/attribute-contains.pipe';
import { OrderByParamPipe } from 'src/app/pipes/order-by-param/order-by-param.pipe';
import { AttributeExactPipe } from 'src/app/pipes/attribute-exact/attribute-exact.pipe';
import { MapSizes } from 'src/models/types';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/app/services/cache/cache.service';
import { MapItemComponent } from './map-item/map-item.component';

@Component( {
  selector: 'app-my-maps',
  templateUrl: './my-maps.component.html',
  styleUrls: [ './my-maps.component.scss' ],
} )
export class MyMapsComponent extends Desubscribe {

  nbMo = 0;
  nbToDll = 0;

  searchTerm = '';

  vacWithDownloadedMap: IVac[] = [];
  favMapsDisplay: IVac[] = [];
  dllMapsDisplay: IVac[] = [];

  noMaps = true;
  noFav = true;
  noFavToUpdate = true;
  noDll = true;
  noUpdate = true;

  isUpdatingAll = false;

  pipes: {
    mapToArray: MapToArrayPipe,
    attributeContains: AttributeContainsPipe,
    orderByParam: OrderByParamPipe,
    attributeExact: AttributeExactPipe
  } = {
      mapToArray: new MapToArrayPipe(),
      attributeContains: new AttributeContainsPipe(),
      orderByParam: new OrderByParamPipe(),
      attributeExact: new AttributeExactPipe()
    };

  expandFav = true;
  expandDll = true;

  heightContainerFav: number = MapItemComponent.ITEM_HEIGHT;
  heightFav = 0;
  heightContainerDll: number = MapItemComponent.ITEM_HEIGHT;
  heightDll: number = MapItemComponent.ITEM_HEIGHT;

  constructor(
    private modalCtrl: ModalController,
    private myMaps: MyMapsService,
    private alerts: AlertManagerService,
    private network: NetworkService,
    private translate: TranslateService,
    private cache: CacheService
  ) {
    super();
  }

  ngOnInit() {

    this.addSubscription( this.myMaps.mapSizes.subscribe( ( s: MapSizes ) => {
      this.nbMo = ( s.deltaToDownload < 0.1 && s.nbToDll > 0 ) ? 0.1 : Number.parseFloat( ( s.deltaToDownload ).toFixed( 1 ) );
      this.nbToDll = s.nbToDll;
    } ) );

    // Get the up to date Maps
    this.refresh();

    // Keep the maps up to date but don't create new array (to keep scroll on the view)
    this.addSubscription( this.cache.downloaded_maps$.subscribe( () => {
      this.refresh();
    } ) );

    this.addSubscription( this.myMaps.dllMaps.subscribe( ( dlls: Map<key, IVac> ) => {
      this.refresh();
    } ) );

    // Get the delete all event
    // TODO CHECK IF NEED ?
    this.addSubscription( this.myMaps.deleteAllO.subscribe( ( deleteAll: boolean ) => {
      if ( deleteAll === true ) {
        this.computeArrays();
      }
    } ) );

    this.addSubscription( this.myMaps.isDownloadingAll.subscribe( ( b: boolean ) => {
      this.isUpdatingAll = b;
    } ) );

    this.myMaps.calculateMapSize();
  }

  refresh() {
    this.vacWithDownloadedMap = this.cache.getVacsWithDownloadedMap();
    this.computeArrays();
    this.checkSizeFavsAndDll();

  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  /**
   * Check informations on the number of maps (favs, dll...)
   *
   * @private
   * @memberof MyMapsComponent
   */
  private checkSizeFavsAndDll(): void {
    this.noMaps = !this.vacWithDownloadedMap.length;
    this.noFav = !this.favMapsDisplay.length;
    this.noFavToUpdate = true;

    this.favMapsDisplay.forEach( ( v: IVac ) => {
      if ( v.map.downloadedMap && v.map.version !== v.map.downloadedMap.version
        && v.map.downloadedMap.version !== MyMapsService.DLL_ALL_VERSION ) {
        this.noFavToUpdate = false;
      }
    } );

    this.noDll = !this.dllMapsDisplay.length;
    this.noUpdate = this.noFavToUpdate;
    if ( this.noUpdate ) {
      this.dllMapsDisplay.forEach( ( v: IVac ) => {
        if ( v.map.downloadedMap && v.map.version !== v.map.downloadedMap.version
          && v.map.downloadedMap.version !== MyMapsService.DLL_ALL_VERSION ) {
          this.noUpdate = false;
        }
      } );
    }
  }

  /**
   * Transform the Map to filtered arrays, used here and not in the html because we want to set some logic (the booleans)
   *
   * @memberof MyMapsComponent
   */
  computeArrays(): void {
    const firstFilter: IVac[] = this.pipes.orderByParam.transform( this.vacWithDownloadedMap, 'city' ) || [];

    this.favMapsDisplay = [];
    this.dllMapsDisplay = [];

    firstFilter.forEach( ( v ) => {
      if ( this.cache.isFavoriteMap( v.key ) ) {
        this.favMapsDisplay.push( v );
      } else {
        this.dllMapsDisplay.push( v );
      }
    } );

    this.heightFav = Math.min( 500, MapItemComponent.ITEM_HEIGHT * this.favMapsDisplay.length );
    this.heightContainerFav = Math.min( 600, MapItemComponent.ITEM_HEIGHT + this.heightFav );

    this.heightDll = Math.min( 500, MapItemComponent.ITEM_HEIGHT * this.dllMapsDisplay.length );
    this.heightContainerDll = Math.min( 600, MapItemComponent.ITEM_HEIGHT + this.heightDll );

    this.checkSizeFavsAndDll();
  }

  /**
   * Check if internet is up and if no update is already in progress
   *
   * @private
   * @returns {boolean}
   * @memberof MyMapsComponent
   */
  private checkForAvailability(): boolean {
    if ( !this.network.isConnected ) {
      this.alerts.genericNoInternet();
      return false;
    }
    if ( this.isUpdatingAll ) {
      return false;
    } else {
      this.isUpdatingAll = true;
      return true;
    }
  }

  downloadAll(): void {
    this.alerts.basicChoice( {
      header: 'ALERTS.DLL.ALL.TITLE',
      message: this.translate.instant( 'ALERTS.DLL.ALL.MESSAGE', { nbMaps: this.nbToDll, mo: this.nbMo } ),
      confirm: {
        text: 'ALERTS.BASIC_CHOICE.CONFIRM',
        class: 'red'
      }
    } ).then( ( b: boolean ) => {
      if ( b && this.checkForAvailability() ) { this.myMaps.bulkDownload(); }
    } );
  }

  updateAllF(e: Event, justFav: boolean = false ): void {
    e.stopPropagation();
    if ( this.checkForAvailability() ) { this.myMaps.updateAll( justFav, true, this.searchTerm ); }
  }

  deleteAll(e: Event, favToo: boolean = false ): void {
    e.stopPropagation();
    this.alerts.basicChoice( {
      header: 'ALERTS.SUPPRESS.ALL.TITLE',
      message: 'ALERTS.SUPPRESS.ALL.MESSAGE',
      confirm: {
        text: 'ALERTS.BASIC_CHOICE.DELETE',
        class: 'red'
      }
    } ).then( ( b: boolean ) => {
      if ( b ) { this.isUpdatingAll = false; this.myMaps.deleteAllDll( favToo, this.searchTerm ); }
    } );
  }

  toggleExpand( which: 'fav' | 'dll' ): void {
    if ( which === 'fav' ) {
      this.expandFav = !this.expandFav;
    } else {
      this.expandDll = !this.expandDll;
    }
  }

  indexTrackFn( index: number, item: IVac ) {
    return item ? item.key : index;
  }
}
