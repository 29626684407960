import * as L from 'leaflet';
import { ICoords } from '../interfaces/ground';
import { Label } from '../interfaces/leaf';

export class Zone {
  // Elements to add to the map
  leafletElement:
    | L.Polygon
    | L.Polyline
    | L.Polygon[]
    | L.Polyline[]
    | (L.Polyline | L.Polygon)[];
  isMultipleElements: boolean = false;

  bounds: { NE: ICoords; SW: ICoords; NW: ICoords; SE: ICoords } = {
    NE: { lat: 0, lon: 0 },
    SW: { lat: 0, lon: 0 },
    NW: { lat: 0, lon: 0 },
    SE: { lat: 0, lon: 0 },
  };

  center: ICoords;
  markerCenter: L.Marker;
  textMarker: L.Marker;
  firstLeafletElement: L.Polygon | L.Polyline;

  constructor(
    leafEl:
      | L.Polygon
      | L.Polyline
      | L.Polygon[]
      | L.Polyline[]
      | (L.Polyline | L.Polygon)[],
    label?: Label,
    popin?: string
  ) {
    this.leafletElement = leafEl;
    this.isMultipleElements = leafEl instanceof Array;

    // Create the boundaries
    let boundsTemps: {
      maxLat: number;
      minLat: number;
      maxLon: number;
      minLon: number;
    } = {
      maxLat: -1000,
      minLat: 1000,
      maxLon: -1000,
      minLon: 1000,
    };

    if (this.leafletElement instanceof Array) {
      this.firstLeafletElement = this.leafletElement[0];

      this.leafletElement.forEach((l: L.Polygon) => {
        const bounds = l.getBounds();
        boundsTemps.maxLat = Math.max(
          boundsTemps.maxLat,
          bounds.getNorthEast().lat
        );
        boundsTemps.minLat = Math.min(
          boundsTemps.minLat,
          bounds.getSouthWest().lat
        );
        boundsTemps.maxLon = Math.max(
          boundsTemps.maxLon,
          bounds.getNorthEast().lng
        );
        boundsTemps.minLon = Math.min(
          boundsTemps.minLon,
          bounds.getSouthWest().lng
        );
      });
    } else {
      this.firstLeafletElement = this.leafletElement;

      const bounds = (this.leafletElement as L.Polygon).getBounds();
      boundsTemps.maxLat = bounds.getNorthEast().lat;
      boundsTemps.minLat = bounds.getSouthWest().lat;
      boundsTemps.maxLon = bounds.getNorthEast().lng;
      boundsTemps.minLon = bounds.getSouthWest().lng;
    }

    this.bounds.NE = {
      lat: boundsTemps.maxLat,
      lon: boundsTemps.maxLon,
    };
    this.bounds.NW = {
      lat: boundsTemps.maxLat,
      lon: boundsTemps.minLon,
    };
    this.bounds.SE = {
      lat: boundsTemps.minLat,
      lon: boundsTemps.maxLon,
    };
    this.bounds.SW = {
      lat: boundsTemps.minLat,
      lon: boundsTemps.minLon,
    };

    // Temp
    this.center = {
      lat: (this.bounds.NE.lat + this.bounds.SW.lat) / 2,
      lon: (this.bounds.NE.lon + this.bounds.SW.lon) / 2,
    };
    this.markerCenter = new L.Marker([this.center.lat, this.center.lon]);

    // Text Marker
    if (label) {
      let cssClass = `text-marker ${
        label.cssClass ? label.cssClass.join(' ') : ''
      }`;
      let options: L.TooltipOptions = {
        permanent: true,
        interactive: true,
        direction: label.direction,
        className: cssClass,
      };

      const ref = label.position ? label.position : this.center;
      this.textMarker = new L.Marker([ref.lat, ref.lon], {
        opacity: 0,
      }).bindTooltip(label.text, options);
      if (popin) {
        this.textMarker
          .bindPopup(popin, {
            className: 'layer-zone rtba-zone',
            closeButton: false,
          })
          .openPopup();
      }
    }
  }
}
