export enum VACS_TYPES {
  VAC = 'AD', // Aérodromes
  HEL = 'HP', // Héliports
  SIV = 'SIV', // Drones
}

export function toVacType( type: string ): VACS_TYPES {
  return type.toUpperCase() === 'AD' ? VACS_TYPES.VAC : VACS_TYPES.HEL;
}

export enum GROUND_TYPES {
  GRASS = 'GRASS',
  HARD = 'HARD',
}

export enum MAP_TYPES {
  CLASSIC = 'CLASSIC',
  SATELLITE = 'SATELLITE',
  /*TERRAIN = 'TERRAIN',*/
  OSM = 'OSM'
}

export enum LENGTH_TYPES {
  LENGTH_SMALL,
  LENGTH_MEDIUM,
  LENGTH_LARGE,
}

export enum MAP_STATUS {
  START_UPDATE,
  FINISH_UPDATE,
  STOPPED,
  STOPPED_ERROR,
}

export enum ALERTS_TYPE {
  GENERIC_ERROR,
  GENERIC_WEB,
  GENERIC_INTERNET,
  BASIC_ERROR,
  BASIC_CHOICE,
}

export enum ZONES_TYPE {
  FIR = 'FIR',
  SIV = 'SIV',
  TMA = 'TMA',
  CTR = 'CTR',
  ZONES_R = 'ZONES_R',
  ZONES_D = 'ZONES_D',
  ZONES_P = 'ZONES_P',
  RTBA = 'RTBA',
}

export enum TMA_TYPE {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export enum POINTS_TYPE {
  VFR = 'VFR',
  VOR = 'VOR',
  VOR_DME = 'VOR_DME',
  OBS = 'OBS',
}
