import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { MapService } from 'src/app/services/map/map.service';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TilesService } from 'src/app/services/tiles/tiles.service';
import { VACS_TYPES } from 'src/models/enums';
import { ITilesZone } from 'src/models/interfaces/tiles-zone';

@Component( {
  selector: 'profil',
  templateUrl: './profil.component.html',
  styleUrls: [ './profil.component.scss' ],
} )
export class ProfilComponent implements OnInit, OnDestroy {
  currentLang: string;
  airportsVisible: boolean;
  heliportsVisible: boolean;
  tilesDownloaded: boolean;
  autoUpdateFav: boolean;
  autoUpdateAll: boolean;
  zonesAvailable: ITilesZone[];
  leftZones: ITilesZone[] = [];
  rightZones: ITilesZone[] = [];
  zonesSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private myMapService: MyMapsService,
    private storage: StorageService,
    private tilesService: TilesService,
    private readonly mapService: MapService,
    private alerts: AlertManagerService,
    public network: NetworkService
  ) {
    this.init();
  }

  ngOnInit(): void {
    this.zonesSubscription = this.tilesService.zonesAvailable$.subscribe(
      ( zones ) => {
        this.zonesAvailable = zones;
        this.createZones();
      }
    );
  }

  ngOnDestroy(): void {
    this.zonesSubscription.unsubscribe();
  }

  async init() {
    this.currentLang = this.translate.currentLang;
    this.airportsVisible = this.myMapService.airportsVisible;
    this.heliportsVisible = this.myMapService.heliportsVisible;
    // console.log( 'init : ' + this.storage.autoUpdate.all + '/' + this.storage.autoUpdate.fav );
    if ( this.storage.autoUpdate ) {
      this.autoUpdateFav = this.storage.autoUpdate.fav;
      this.autoUpdateAll = this.storage.autoUpdate.all;
    }
  }

  createZones() {
    const len = this.zonesAvailable.length;
    const mid = Math.ceil( len / 2 ) - 1;
    this.leftZones = this.zonesAvailable.slice( 0, mid + 1 );
    this.rightZones = this.zonesAvailable.slice( mid + 1, len );
  }

  setLang( lang: string ): void {
    this.currentLang = lang;
    this.translate.use( this.currentLang );
    this.storage.saveLanguage( this.currentLang );
  }

  // TODO temporary, it's always true
  async setTilesDll( zone: ITilesZone ) {
    if ( !zone.active || zone.isDownloading ) { return; }

    if ( zone.downloaded !== true ) {
      if ( this.network.isConnected ) {
        // launch the tiles download
        this.tilesDownloaded = await this.tilesService.askForDownload( zone );
      } else {
        // Display a message error
        this.alerts.basicError(
          'ALERTS.GENERIC.INTERNET.SUB_TITLE',
          'ALERTS.GENERIC.INTERNET.MESSAGE'
        );
      }
    }
  }

  toggleAirports(): void {
    // console.log( 'this.airportsVisible:', this.airportsVisible );
    this.myMapService.airportsVisible = this.airportsVisible;
    this.mapService.airportsVisible = this.airportsVisible;
    this.storeTypes();
  }

  toggleHeliports(): void {
    this.myMapService.heliportsVisible = this.heliportsVisible;
    this.mapService.heliportsVisible = this.heliportsVisible;
    this.storeTypes();
  }

  toggleAutoUpdate(): void {
    if ( this.autoUpdateAll ) {
      this.autoUpdateFav = true;
    }
    this.storage.autoUpdate = {
      fav: this.autoUpdateFav,
      all: this.autoUpdateAll,
    };
  }

  storeTypes(): void {
    const authorizedTypes = [];
    if ( this.airportsVisible ) {
      authorizedTypes.push( VACS_TYPES.VAC );
    }
    if ( this.heliportsVisible ) {
      authorizedTypes.push( VACS_TYPES.HEL );
    }
    this.storage.saveFilters( { authorizedTypes } );
  }
}
