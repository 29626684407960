<ion-header>
  <ion-toolbar color="dark">
    <ion-title color="primary">
      <div class="flex">
        <div class="title">
          {{ 'TILES.INFO.CHOOSE_TILES_ZONE' | translate }}
        </div>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="list-tiles">
    <ion-radio-group (ionChange)="radioGroupChange($event)">
      <ion-item *ngFor="let zone of zones" [disabled]="!zone.active">
        <ion-label>{{ zone.label | translate }}</ion-label>
        <ion-radio [value]="zone"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer>
  <div class="more-infos">
    <ion-button (click)="cancel()">
      {{ 'ALERTS.BASIC_CHOICE.CANCEL' | translate }}
    </ion-button>
    <ion-button (click)="select()" [disabled]="selectedRadioGroup === null">
      {{ 'ALERTS.BASIC_CHOICE.OK' | translate }}
    </ion-button>
  </div>
</ion-footer>