<div class="container-result">
  <ion-grid style="padding-top: 0px !important">
    <ion-row>
      <ion-col size="1">
        <div class="icon" [ngClass]="vac.type"></div>
      </ion-col>
      <ion-col size="{{ geolocActive ? 7 : 9 }}">
        <div class="airport" innerHTML="{{ vac.city | highLightSearch: searchTerm }}"></div>
      </ion-col>
      <ion-col size="2">
        <div class="oaci">{{ vac.oaci }}</div>
      </ion-col>
      <ion-col *ngIf="geolocActive" size="2">
        <div class="miles">{{ vac?.ground?.coords | distanceToUser }} NM</div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>