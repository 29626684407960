import { Pipe, PipeTransform } from '@angular/core';
import { ICoords } from 'src/models/interfaces/ground';

@Pipe( {
  name: 'ddToDms'
} )
export class DdToDmsPipe implements PipeTransform {

  /**
   * Return the coordinates conversion string from DD to DMS
   *
   * @param {ICoords} value
   * @param {*} args
   * @returns
   * @memberof DDtoDmsPipe
   */
  transform( value: ICoords, ...args ) {
    const getDms = ( val: number ): string => {
      let valDeg, valMin, valSec: number;
      let result: string;

      val = Math.abs( val );

      valDeg = Math.floor( val );
      result = valDeg + 'º';

      valMin = Math.floor( ( val - valDeg ) * 60 );
      result += valMin + '\'';

      valSec = Math.round( ( val - valDeg - valMin / 60 ) * 3600 * 1000 ) / 1000;
      result += valSec + '"';

      return result;
    };

    const lat = value.lat;
    const lng = value.lon;

    let latResult, lngResult, dmsResult;


    // Call to getDms(lat) function for the coordinates of Latitude in DMS.
    // The result is stored in latResult variable.
    latResult = getDms( lat );
    latResult += ( lat >= 0 ) ? 'N' : 'S';

    // Call to getDms(lng) function for the coordinates of Longitude in DMS.
    // The result is stored in lngResult variable.
    lngResult = getDms( lng );
    lngResult += ( lng >= 0 ) ? 'E' : 'W';

    // Joining both variables and separate them with a space.
    dmsResult = latResult + ' ' + lngResult;

    return dmsResult;
  }

}
