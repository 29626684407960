import { Component, Input } from '@angular/core';

@Component( {
  selector: 'nb-filters',
  templateUrl: './nb-filters.component.html',
  styleUrls: [ './nb-filters.component.scss' ],
} )
export class NbFiltersComponent {

  nbFilters: number;

  @Input() set setNbFilters( n: number ) {
    this.nbFilters = n;
  }

  constructor() { }

}
