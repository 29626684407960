import { Pipe, PipeTransform } from '@angular/core';
import { DistanceService } from 'src/app/services/distance/distance.service';
import { GeolocationService } from 'src/app/services/geolocation/geolocation.service';

@Pipe( {
  name: 'distanceToUser'
} )
export class DistanceToUserPipe implements PipeTransform {
  constructor( private geo: GeolocationService, private distance: DistanceService ) {

  }

  transform( value: any, ...args: any[] ): any {
    if ( this.geo.coords ) {
      return this.distance.calculateHaversinDistance( value, this.geo.coords ).toFixed( 0 );
    } else {
      return 0;
    }
  }

}
