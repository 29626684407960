import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component( {
  selector: 'next-update',
  templateUrl: './next-update.component.html',
  styleUrls: [ './next-update.component.scss' ],
} )
export class NextUpdateComponent implements OnInit {

  static TWENTY_EIGHT_DAY = 2419200000;
  dateNext: Date;
  dateDone: Date;
  visible = false;
  upToDate = false;
  dateReached = false;

  currentLang: string;

  constructor( private translate: TranslateService, private storage: StorageService ) {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe( () => {
      this.currentLang = this.translate.currentLang;
    } );
  }

  ngOnInit() {
    this.storage.configRecovered().subscribe( ( b: boolean ) => {
      if ( !b ) {
        this.storage.listenSavedLastUpdate().subscribe( ( savedLastUpdate: string ) => {
          // console.log('dateDone : ' + savedLastUpdate);
          if ( savedLastUpdate && savedLastUpdate !== '' ) { this.dateDone = new Date( savedLastUpdate ); }
          this.calculateDateReached();
        } );
        this.storage.listenSavedNextUpdate().subscribe( ( savedNextUpdate: string ) => {
          if ( savedNextUpdate && savedNextUpdate !== '' ) { this.dateNext = new Date( savedNextUpdate ); }
          this.calculateDateReached();
        } );
      } else {
        this.dateNext = this.storage.latestServerDates.nextUpdate;
        this.dateDone = this.storage.latestServerDates.lastUpdate;
        this.calculateDateReached();
      }
    } );
  }

  calculateDateReached(): void {
    if ( this.dateDone ) {
      this.visible = true;
      this.dateReached = ( this.dateDone.getTime() + NextUpdateComponent.TWENTY_EIGHT_DAY ) < new Date().getTime();
      this.upToDate = !this.dateReached;
    }
  }

  getNextRefreshHTML(): string {
    let toReturn = '<span>';
    if ( this.dateReached ) {
      toReturn += this.translate.instant( 'MAP.NOT_UPDATED' );
    } else {
      toReturn += this.translate.instant( 'MAP.REFRESH_DATE_MAP' ) + '&nbsp;' +
        ( new DatePipe( this.currentLang || 'fr' ) ).transform( this.dateNext, this.currentLang === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy' );
    }
    toReturn += '</span>';
    return toReturn;
  }
}
