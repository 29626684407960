import { VACS_TYPES, GROUND_TYPES } from '../enums';
import { IGround } from './ground';
import { IRunway } from './runway';
import { IMap } from './map';
import { IFrequencie } from './frequencie';
import { IInformations } from './informations';

export interface key {
    readonly [ Symbol.toStringTag ]: string;
}

export function getKey( oaci: string, type: string ): key {
    return ( `${ oaci }_${ type }` as any ) as key;
}

export function keyToString( key: key ): string {
    return key as any as string;
}

export function cleanWhiteSpace( v: string ): string {
    return v.replace( /\s+/ig, ' ' ).replace( /AD$/ig, '' ).trim();
}

export function orderByCity( a: IVac, b: IVac ) {
    return a.type > b.city ? 1 : -1;
}

export interface IVac {
    key: key;
    oaci: string;
    city: string;
    type: VACS_TYPES;
    ground: IGround;
    runways: IRunway[];
    map: IMap;
    frequencies: IFrequencie[];
    informations: IInformations[];
}

export interface IDistanceVac {
    distance: number;
    vac: IVac;
}


export interface IFilters {
    groundType: GROUND_TYPES[];
    airstripLength: number;
}
