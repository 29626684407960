import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloading',
  templateUrl: './downloading.component.html',
  styleUrls: ['./downloading.component.scss'],
})
export class DownloadingComponent {
  @Input() percent: number = 0;

  constructor() {}
}
