<div class="grounds-menu">
  <div class="header">
    <ion-icon name="close" color="light" (click)="closeFilters()"></ion-icon>
    <div class="title">{{'GROUNDS_MENU.TITLE' | translate}}</div>
  </div>

  <div class="container">
    <div class="surfaces">
      <div class="title">{{'GROUNDS_MENU.SURFACE_TYPE' | translate}}</div>
      <div [ngClass]="{'active': isHardActive}" (click)="toggleGround(groundTypes.HARD)">
        <ion-button class="option" fill="clear">
          {{'GROUNDS_MENU.HARD' | translate}}
        </ion-button>
      </div>

      <hr>

      <div [ngClass]="{'active': isGrassActive}" (click)="toggleGround(groundTypes.GRASS)">
        <ion-button class="option" fill="clear" class="option">
          {{'GROUNDS_MENU.GRASS' | translate}}
        </ion-button>
      </div>
      <hr>
    </div>

    <div class="lengths">
      <div class="title">{{'GROUNDS_MENU.AIRSTRIP_LENGTH' | translate}} :&nbsp;
        <span class="value">
          <span *ngIf="lengthSlider.value === 600">
            {{'GROUNDS_MENU.ALL' | translate}}
          </span>
          <span *ngIf="lengthSlider.value !== 600">
            {{lengthSlider.value}} m
          </span>
        </span>
      </div>
      <div class="slider-container">
        <ngx-slider [(value)]="lengthSlider.value" [options]="lengthSlider.options" (userChange)="toggleGround()" [manualRefresh]="lengthSlider.manualRefresh"
          *ngIf="FLAG">
        </ngx-slider>
      </div>

    </div>
  </div>
</div>
