import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

@Pipe( {
  name: 'attributeContains'
} )
export class AttributeContainsPipe implements PipeTransform {

  /**
   *  Return an array with all the values have the searchTerm containing the searchValues (multiple possible and && or || use)
   * @param value 
   * @param args 
   */
  transform( value: any, ...args: any[] ): any {
    let searchTerm = args[ 0 ];
    let searchValue = args[ 1 ];
    let all = args[ 2 ];

    if ( !value ) return [];
    if ( !searchTerm || !searchValue ) return value;

    // Observable (for async pipe)
    if ( value.pipe ) {
      return value.pipe( map( ( values: any ) => {
        return values.filter( ( v: any ) => {
          if ( typeof searchTerm === 'string' ) {
            let searchOn = v[ searchTerm ];
            if ( searchOn.toUpperCase().includes( searchValue.toUpperCase() ) ) {
              return true;
            }
          } else {
            let toKeep = 0;
            searchTerm.forEach( ( s: string, i: number ) => {
              let searchOn = v[ s ];
              let sV = searchValue;
              if ( typeof searchValue !== 'string' ) {
                sV = searchValue[ i ];
              }
              if ( searchOn.toUpperCase().includes( sV.toUpperCase() ) ) {
                toKeep++;
              }
            } );
            if ( ( all && toKeep === searchTerm.length ) || ( !all && toKeep > 0 ) ) {
              return true;
            }
          }
          return false;
        } );
      } ) )
    } else { // Array
      return this.filterArray( value, searchTerm, searchValue, all );
    }
  }

  private filterArray( value: any, searchTerm: any, searchValue: any, all: boolean ): any {
    let result = [];
    value.forEach( ( v: any ) => {
      if ( typeof searchTerm === 'string' ) {
        let searchOn = v[ searchTerm ];
        if ( searchOn.toUpperCase().includes( searchValue.toUpperCase() ) ) {
          result.push( v );
        }
      } else {
        let toKeep = 0;
        searchTerm.forEach( ( s: string, i: number ) => {
          let searchOn = v[ s ];
          let sV = searchValue;
          if ( typeof searchValue !== 'string' ) {
            sV = searchValue[ i ];
          }
          if ( searchOn.toUpperCase().includes( sV.toUpperCase() ) ) {
            toKeep++;
          }
        } );
        if ( ( all && toKeep === searchTerm.length ) || ( !all && toKeep > 0 ) ) {
          result.push( v );
        }
      }
    } );
    return result;
  }
}
