import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'mapToArray'
} )
export class MapToArrayPipe implements PipeTransform {

  /**
   * Return an array of values
   */
  transform( map: Map<any, any>, ...args: any[] ): Array<any> {
    let result = [];
    if ( map ) {
      map.forEach( ( v: any ) => {
        result.push( v );
      } )
    }
    return result;
  }

}
