import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { IAlert } from 'src/models/interfaces/alert';
import { IVac, key } from 'src/models/interfaces/vac';
import { IEditablePage } from 'src/models/interfaces/web-services';
import { IZone, IPoint, IPointObs } from 'src/models/interfaces/points';
import { IDownloadedMap } from 'src/models/interfaces/map';
import { IFavorite } from 'src/models/interfaces/favorite';

export enum TABLES_NAMES {
  VAC = 'vacs',
  ALERT = 'alerts',
  DOWNLOADED_MAP = 'downloaded_maps',
  FAVORITES_MAP = 'favorite_maps',
  EDITABLE = 'editables',
  ZONES = 'zones',
  POINTS = 'points',
  POINTS_OBS = 'points_obs'
}

// Ne mettre ici que les données dont on veut un index.
// N'envoyer vers dexie.js QUE des propriétés d'objet qu'on souhaite stocker

/*
Note: ++id (or id++) on the primary key means that it will be auto-incremented
Note2: You only need to specify properties that you wish to index.
       The object store will allow any properties on your stored objects
       but you can only query them by indexed properties
*/
export const TABLES_CONFIG = [
  {
    name: TABLES_NAMES.ALERT,
    indexes: '++id'
  },
  {
    name: TABLES_NAMES.VAC,
    indexes: 'key'
  },
  {
    name: TABLES_NAMES.DOWNLOADED_MAP,
    indexes: 'key'
  },
  {
    name: TABLES_NAMES.FAVORITES_MAP,
    indexes: 'key'
  },
  {
    name: TABLES_NAMES.EDITABLE,
    indexes: '++id,title'
  },
  {
    name: TABLES_NAMES.ZONES,
    indexes: '++uid'
  },
  {
    name: TABLES_NAMES.POINTS,
    indexes: '++uid'
  },
  {
    name: TABLES_NAMES.POINTS_OBS,
    indexes: '++uid'
  }
];

export enum TRANSACTIONS_TYPES {
  READWRITE = 'rw',
  READ = 'r'
}

@Injectable( {
  providedIn: 'root'
} )
export class DexieConfigService extends Dexie {

  private alerts: Dexie.Table<IAlert, number>;
  private vacs: Dexie.Table<IVac, key>;
  private favorite_maps: Dexie.Table<IFavorite, key>;
  private downloaded_maps: Dexie.Table<IDownloadedMap, key>;
  private editables: Dexie.Table<IEditablePage, number>;
  private zones: Dexie.Table<IZone, number>;
  private points: Dexie.Table<IPoint, number>;
  private points_obs: Dexie.Table<IPointObs, number>;

  get alertsTable(): Dexie.Table<IAlert, number> {
    return this.alerts;
  }

  get vacsTable(): Dexie.Table<IVac, key> {
    return this.vacs;
  }

  get downloadedMapsTable(): Dexie.Table<IDownloadedMap, key> {
    return this.downloaded_maps;
  }

  get favoriteMapsTable(): Dexie.Table<IFavorite, key> {
    return this.favorite_maps;
  }

  get editablesTable(): Dexie.Table<IEditablePage, number> {
    return this.editables;
  }

  get zonesTable(): Dexie.Table<IZone, number> {
    return this.zones;
  }

  get pointsTable(): Dexie.Table<IPoint, number> {
    return this.points;
  }

  get pointsObsTable(): Dexie.Table<IPointObs, number> {
    return this.points_obs;
  }

  constructor() {
    super( 'DGAC_db' );

    TABLES_CONFIG.forEach( ( value: { name: string, indexes: string } ) => {
      const tableToCreate = {};
      tableToCreate[ value.name ] = value.indexes;
      /*
        We need to use versions to avoid errors.
        And the version 1 must be kept.
        https://github.com/dfahlander/Dexie.js/issues/156
       */
      this.version( 1 ).stores( tableToCreate );
      this.version( 2 ).stores( tableToCreate );
    } );
  }

  /**
   * Generic function to execute request on database
   *
   * @private
   * @param {TRANSACTIONS_TYPES} type
   * @param {Dexie.Table<any, any>} table
   * @param {() => Promise<any>} onSuccess
   * @param {( error?: any ) => any} [onError]
   * @memberof DexieConfigService
   */
  private executeOperation(
    type: TRANSACTIONS_TYPES,
    table: Dexie.Table<any, any>,
    onSuccess: () => Promise<any>,
    onError?: ( error?: any ) => any
  ): void {
    this.transaction( type, table, async () => onSuccess() )
      .catch( e => {
        if ( onError ) { onError( e.stack || e ); }
      } );
  }
}
