import { Component, ViewChild, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { UserAgent } from '@ionic-native/user-agent/ngx';
import { MapService } from './services/map/map.service';
import { environment } from 'src/environments/environment';
import { Router, UrlTree } from '@angular/router';
import { Globalization } from '@ionic-native/globalization/ngx';
import { NetworkService } from './services/network/network.service';
import { DataSetCheckerService } from './services/dataset-checker/dataset-checker.service';

declare const window: any;

@Component( {
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [ 'app.component.scss' ],
} )
export class AppComponent {
  @ViewChild('datetimeSupportChecker') datetimeSupportChecker: ElementRef;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private userAgent: UserAgent,
    private router: Router,
    private globalization: Globalization,
    private network: NetworkService,
    private datasetChecker: DataSetCheckerService
  ) {
    this.initializeApp();
  }

  ngAfterViewInit() {
    const checker = this.datetimeSupportChecker as any;
    const elem = checker.nativeElement;
    (window as any).isDatetimeLocalSupported = elem.value === '';
  }

  applyLanguage( lang: string ) {
    lang = ( lang || environment.defaultLanguage ).toLowerCase();
    //console.warn( `apply:language: ${ lang }` );
    if ( lang === 'fr-fr' || lang === 'fr' ) {
      this.translate.use( 'fr' );
    } else if ( lang === 'en-us' || lang === 'en-gb' || lang === 'en' ) {
      this.translate.use( 'en' );
    } else {
      this.translate.use( environment.defaultLanguage );
    }
  }

  initializeApp() {

    //console.warn( 'ENV', environment );

    this.platform.ready().then( () => {
      if ( this.platform.is( 'cordova' ) ) {
        this.statusBar.overlaysWebView( false );
        this.statusBar.backgroundColorByHexString( '#004e94' );

        this.splashScreen.hide();
      }

      document.addEventListener( 'resume', () => {
        if ( this.platform.is( 'cordova' ) ) {
          this.network.refresh();
          this.datasetChecker.checkDataSet();
        }
      } );

      // TODO make a service to manage translations
      this.translate.setDefaultLang( environment.defaultLanguage );

      if ( this.platform.is( 'cordova' ) ) {
        this.globalization.getPreferredLanguage().then(
          ( lang: { value: string } ) => {
            this.applyLanguage( lang.value );
          },
          () => this.translate.use( environment.defaultLanguage )
        );
      } else {
        const urlTree: UrlTree = this.router.getCurrentNavigation().extractedUrl;
        if ( urlTree && urlTree.queryParams ) { this.applyLanguage( urlTree.queryParams.lang ); }
      }

      if ( this.platform.is( 'cordova' ) ) {
        this.userAgent.set( MapService.USER_AGENT ); // Not working at runtime on iOS, so we use the config.xml too OverrideUserAgent
      }

      this.router.navigate( [ '' ] );
    } );
  }
}
