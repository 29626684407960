import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'dmsToDd'
} )
export class DmsToDdPipe implements PipeTransform {

  transform( value: string ): number {

    const ConvertDMSToDD = ( degrees: string, minutes: string, seconds: string, direction: string ): number => {
      let dd = Number( degrees ) + Number( minutes ) / 60 + Number( seconds ) / ( 60 * 60 );

      if ( direction === 'S' || direction === 'W' ) {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    };

    const direction = value.slice( value.length - 1 );
    value = value.slice( 0, value.length - 1 );

    let result = 0;
    const isLat = direction === 'S' || direction === 'N';

    const tokens = value.split( '.' );
    const integers = tokens[ 0 ];
    let decimals = '0';
    if ( tokens[ 1 ] ) {
      decimals = tokens[ 1 ];
    }

    let degree = '0';
    let minute = '0';
    let seconds = '0';
    if ( isLat ) {
      if ( integers.length === 6 ) {
        degree = integers[ 0 ] + integers[ 1 ];
        minute = integers[ 2 ] + integers[ 3 ];
        seconds = integers[ 4 ] + integers[ 5 ] + '.' + decimals;
      } else if ( integers.length === 4 ) {
        degree = integers[ 0 ] + integers[ 1 ];
        minute = integers[ 2 ] + integers[ 3 ] + '.' + decimals;
      } else if ( integers.length === 2 ) {
        degree = integers[ 0 ] + integers[ 1 ] + '.' + decimals;
      }
    } else {
      if ( integers.length === 7 ) {
        degree = integers[ 0 ] + integers[ 1 ] + integers[ 2 ];
        minute = integers[ 3 ] + integers[ 4 ];
        seconds = integers[ 5 ] + integers[ 6 ] + '.' + decimals;
      } else if ( integers.length === 5 ) {
        degree = integers[ 0 ] + integers[ 1 ] + integers[ 2 ];
        minute = integers[ 3 ] + integers[ 4 ] + '.' + decimals;
      } else if ( integers.length === 3 ) {
        degree = integers[ 0 ] + integers[ 1 ] + integers[ 2 ]; + '.' + decimals;
      }
    }
    result = ConvertDMSToDD( degree, minute, seconds, direction );

    // if ( isLat ) {
    //   let degree = value[ 0 ] + value[ 1 ];
    //   let minute = value[ 2 ] + value[ 3 ];
    //   let second = value[ 4 ] + value[ 5 ] + value[ 6 ] + value[ 7 ] + value[ 8 ];
    //   result = ConvertDMSToDD( degree, minute, second, direction );
    // } else {
    //   let degree = value[ 0 ] + value[ 1 ] + value[ 2 ];
    //   let minute = value[ 3 ] + value[ 4 ];
    //   let second = value[ 5 ] + value[ 6 ] + value[ 7 ] + value[ 8 ] + value[ 9 ];
    //   result = ConvertDMSToDD( degree, minute, second, direction );
    // }

    return result;
  }
}
