<div class="maps-menu">
  <div class="header">
    <ion-icon name="close" color="light" (click)="closeFilters()"></ion-icon>
    <div class="title">{{ 'MAPS_MENU.TITLE' | translate }}</div>
  </div>

  <div class="maps">
    <ion-radio-group [value]="selected">
      <ion-item *ngFor="let map of maps | keyvalue">
        <ion-label>{{ map.value.label | translate }}</ion-label>
        <ion-radio
          [value]="map.value.type"
          (click)="toggleMap(map.value.type)"
        ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
</div>
