import { Component, Input } from '@angular/core';
import { IVac } from 'src/models/interfaces/vac';

@Component( {
  selector: 'result-vac',
  templateUrl: './result-vac.component.html',
  styleUrls: [ './result-vac.component.scss' ],
} )
export class ResultVacComponent {

  @Input() searchTerm: string;
  @Input() vac: IVac;

  geolocActive: boolean;

  constructor() { }
}
