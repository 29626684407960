import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'loading-screen', pathMatch: 'full' },
  {
    path: 'map',
    loadChildren: () => import( './pages/map/map.module' ).then( m => m.MapPageModule )
  },
  {
    path: 'loading-screen',
    loadChildren: () => import( './pages/loading-screen/loading-screen.module' ).then( m => m.LoadingScreenPageModule )
  },
  {
    path: 'settings',
    loadChildren: () => import( './pages/settings/settings.module' ).then( m => m.SettingsPageModule )
  },
  {
    path: 'informations',
    loadChildren: () => import( './pages/informations/informations.module' ).then( m => m.InformationsPageModule )
  },
];

@NgModule( {
  imports: [
    RouterModule.forRoot( routes, { preloadingStrategy: PreloadAllModules } )
  ],
  exports: [ RouterModule ]
} )
export class AppRoutingModule { }
