import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITilesZone } from 'src/models/interfaces/tiles-zone';

@Component({
  selector: 'app-choose-tiles-zone',
  templateUrl: './choose-tiles-zone.component.html',
  styleUrls: ['./choose-tiles-zone.component.scss'],
})
export class ChooseTilesZoneComponent implements OnInit {
  zones: ITilesZone[];
  selectedRadioGroup: any = null;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  radioGroupChange(event) {
    this.selectedRadioGroup = event.detail.value;
  }

  cancel() {
    this.close();
  }

  select() {
    this.close(this.selectedRadioGroup);
  }

  close(value: any = null) {
    this.modalCtrl.dismiss({ zone: value });
  }
}
