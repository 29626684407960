import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { orderByCity, IVac, key, keyToString } from 'src/models/interfaces/vac';
import { PdfManagerService } from '../pdf-manager/pdf-manager.service';
import { IDownloadedMap, IMap } from 'src/models/interfaces/map';
import { WebServices } from '../webservices/web-services.service';
import { LoadingController, Platform } from '@ionic/angular';
import { FileEntry, File } from '@ionic-native/file/ngx';
import { MAP_STATUS, VACS_TYPES } from 'src/models/enums';
import { AlertManagerService } from '../alert-manager/alert-manager.service';
import { NetworkService } from '../network/network.service';
import { MapSizes } from 'src/models/types';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CacheService } from '../cache/cache.service';
import { DatabaseService } from '../database/database.service';
import { saveAs } from 'file-saver';

/**
 * Favorites and DLL maps manipulation
 *
 * @export
 * @class MyMapsService
 */
@Injectable( {
  providedIn: 'root',
} )
export class MyMapsService {
  get authorizedTypes(): Observable<VACS_TYPES[]> {
    return this.authorizedTypesP.asObservable();
  }
  set airportsVisible( b: boolean ) {
    this.airportsActivated = b;
    this.notifyAuthorizedTypes();
  }
  get airportsVisible(): boolean {
    return this.airportsActivated;
  }
  set heliportsVisible( b: boolean ) {
    this.heliportsActivated = b;
    this.notifyAuthorizedTypes();
  }
  get heliportsVisible(): boolean {
    return this.heliportsActivated;
  }
  get dllMaps(): Observable<Map<key, IVac>> {
    return this.dllMapsBSP.asObservable();
  }
  get mapSizes(): Observable<MapSizes> {
    return this.mapSizesBSP.asObservable();
  }
  get lastChange(): Observable<{ key: key; vac: IVac }> {
    return this.lastChangeBSP.asObservable();
  }
  get isDownloadingAll(): Observable<boolean> {
    return this._isDownloadingAll.asObservable();
  }
  get deleteAllO(): Observable<boolean> {
    return this.deleteAllOP.asObservable();
  }

  constructor(
    private storage: StorageService,
    private cache: CacheService,
    private db: DatabaseService,
    private utils: UtilsService,
    private pdfManager: PdfManagerService,
    private webServices: WebServices,
    private loader: LoadingController,
    private alerts: AlertManagerService,
    private network: NetworkService,
    private router: Router,
    private platform: Platform,
    private file: File
  ) {
    this.platform.ready().then( () => {
      this.storage
        .recoverFilters()
        .then( ( savedFilters: { authorizedTypes?: VACS_TYPES[] } ) => {
          if ( savedFilters && savedFilters.authorizedTypes ) {
            this.airportsActivated = savedFilters.authorizedTypes.includes(
              VACS_TYPES.VAC
            );
            this.heliportsActivated = savedFilters.authorizedTypes.includes(
              VACS_TYPES.HEL
            );
            this.notifyAuthorizedTypes();
          }
        } );
    } );

    // Quand la liste des téléchargements a changée
    this.cache.downloaded_maps$.subscribe( () => {
      this.calculateMapSize();
    } );

    this.storage.listDllMaps.subscribe( ( m: Map<key, IVac> ) => {
      this.dllMapsP = m;
      m.forEach( ( v: IVac ) => {
        if (
          v.map &&
          v.map.downloadedMap &&
          v.map.downloadedMap.version === MyMapsService.DLL_ALL_VERSION
        ) {
          this.dllMapsP.delete( v.key );
        } else {
          const bs = new BehaviorSubject<MAP_STATUS>( MAP_STATUS.FINISH_UPDATE );
          this.updatingByKeyBSP.set( v.key, bs );
        }
      } );
      this.dllMapsBSP.next( this.dllMapsP );

      this.checkForAutoUpdate();
    } );

    this.network.checkConnection().subscribe( ( connected: boolean ) => {
      // If a reconnect after the first recovery of data has failed, relaunch the loading-screen
      if ( this.firstRecoveryFailed && connected ) {
        this.storage.recoverConfigs().subscribe( () => {
          this.firstRecoveryFailed = false;
          if (
            !this.storage.latestServerDates.lastUpdate ||
            !this.storage.savedLastUpdate
          ) {
            return;
          }
          // Auto update because VACs weren't changed
          if (
            this.storage.latestServerDates.lastUpdate.toISOString() ===
            this.storage.savedLastUpdate
          ) {
            this.checkForAutoUpdate();
          } else {
            // Redll VACs
            this.alerts
              .basicChoice( {
                header: 'ALERTS.TRY_REDLL.TITLE',
                message: 'ALERTS.TRY_REDLL.MESSAGE',
              } )
              .then( ( b: boolean ) => {
                if ( b ) { this.router.navigateByUrl( 'loading-screen' ); }
              } );
          }
        } );
      }

      // Alert the user that maps being downloaded are lost
      if ( !connected && this._nbMapsUpdating.size ) {
        this.alerts.basicError(
          'ALERTS.STOPPED_DLL.TITLE',
          'ALERTS.STOPPED_DLL.MESSAGE'
        );
        this._nbMapsUpdating.forEach( ( active: boolean, key: key ) => {
          if ( active ) { this.deleteDllMap( key ); }
        } );
      }
    } );
  }
  static DLL_ALL_VERSION = 'dllAll';

  private authorizedTypesP: BehaviorSubject<VACS_TYPES[]> = new BehaviorSubject<
    VACS_TYPES[]
  >( [ VACS_TYPES.VAC, VACS_TYPES.HEL ] );
  private airportsActivated = true;
  private heliportsActivated = true;

  /** Store the dll maps */
  private dllMapsP: Map<key, IVac> = new Map<key, IVac>();
  private dllMapsBSP: BehaviorSubject<Map<key, IVac>> = new BehaviorSubject<
    Map<key, IVac>
  >( this.dllMapsP );

  /** Store the total size of the map and the size of the dll maps */
  private mapSizesP: MapSizes = {
    total: 0,
    dll: 0,
    nbFavToUpdate: 0,
    favToDll: 0,
    nbMapDll: 0,
    nbMapTotal: 0,
    deltaToDownload: 0,
    nbToDll: 0,
  };
  private mapSizesBSP: BehaviorSubject<MapSizes> =
    new BehaviorSubject<MapSizes>( this.mapSizesP );

  /** Store an observable with the status of the updating maps */
  private updatingByKeyBSP: Map<key, BehaviorSubject<MAP_STATUS>> = new Map<
    key,
    BehaviorSubject<MAP_STATUS>
  >();
  private updatingSubcriptionByKey: Map<key, Subscription> = new Map<
    key,
    Subscription
  >();

  /** Store the number of map being updated right now */
  private _nbMapsUpdating: Map<key, boolean> = new Map<key, boolean>();

  /** Emit the last map that change */
  private lastChangeBSP: BehaviorSubject<{ key: key; vac: IVac }> =
    new BehaviorSubject<{ key: key; vac: IVac }>( null );

  private _isDownloadingAll: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>( false );

  private stopAllDownload = false;
  private timeoutUpdateRecur: NodeJS.Timeout;
  private deleteAllOP: Subject<boolean> = new Subject<boolean>();

  firstRecoveryFailed = false;
  getDllMap( key: key ): IVac {
    return this.dllMapsP.get( key );
  }
  getDllMaps(): Map<key, IVac> {
    return new Map( this.dllMapsP.entries() );
  }
  getUpdatingByKeyO( key: key ) {
    return this.updatingByKeyBSP.get( key ).asObservable();
  }
  getUpdatingByKey( key: key ) {
    return this.updatingByKeyBSP.get( key ).value;
  }

  /**
   * Send the authorized VacTypes through an observable
   *
   * @private
   * @memberof MapService
   */
  private notifyAuthorizedTypes(): void {
    const toSend: VACS_TYPES[] = [];
    if ( this.airportsVisible ) {
      toSend.push( VACS_TYPES.VAC );
    }
    if ( this.heliportsVisible ) {
      toSend.push( VACS_TYPES.HEL );
    }
    this.authorizedTypesP.next( toSend );
    this.calculateMapSize();
  }

  /**
   * Check and launch if needed the update of the maps
   *
   * @memberof MyMapsService
   */
  checkForAutoUpdate(): void {
    this.platform.ready().then( () => {
      if ( this.platform.is( 'cordova' ) ) {
        const onDone = () => {
          if ( this.storage.autoUpdate.fav || this.storage.autoUpdate.all ) {
            this.updateAll( !this.storage.autoUpdate.all, true );
          }
          this.calculateMapSize();
        };

        if ( this.storage.autoUpdate === undefined ) {
          this.storage.recoverAutoUpdate().then( () => onDone() );
        } else {
          onDone();
        }
      }
    } );
  }

  /**
   * Fill mapSizesP with the size of the dll and the not-dll maps, notify the BS
   *
   * @memberof MyMapsService
   */
  calculateMapSize(): void {
    let octetsAll = 0,
      nbAllToDll = 0,
      octetsDll = 0,
      nbFavToUpdate = 0,
      octetsFavToDll = 0,
      deltaToDownload = 0,
      nbToDll = 0;

    let map: IMap;
    const vacs: IVac[] = this.cache.getVacs();

    this.dllMapsP.forEach( ( vac: IVac ) => {
      octetsDll += vac.map.downloadedMap.fileSize;

      if ( this.cache.isFavoriteMap( vac.key ) ) {
        if ( vac.map.downloadedMap.version !== vac.map.version ) {
          nbFavToUpdate++;
          octetsFavToDll += vac.map.fileSize;
        }
      }
    } );

    vacs.forEach( ( v: IVac ) => {
      if ( v.map ) {
        map = v.map;
        // si la carte n'est pas téléchargée, ou que la version est differente, on la note pour téléchargement
        if ( this.isDownloadEligible( v ) ) {
          // on ajoute la taille de la carte qui se trouve coté serveur
          deltaToDownload += map.fileSize;
          nbToDll++;
          octetsAll += v.map.fileSize;
          nbAllToDll++;
        }
      }
    } );
    this.mapSizesP.total = this.utils.octetToMo( octetsAll );
    this.mapSizesP.nbMapTotal = nbAllToDll;
    this.mapSizesP.dll = this.utils.octetToMo( octetsDll );
    this.mapSizesP.nbFavToUpdate = nbFavToUpdate;
    this.mapSizesP.favToDll = this.utils.octetToMo( octetsFavToDll );
    this.mapSizesP.nbMapDll = this.dllMapsP.size;
    this.mapSizesP.nbToDll = nbToDll;
    this.mapSizesP.deltaToDownload = this.utils.octetToMo( deltaToDownload );
    this.mapSizesBSP.next( this.mapSizesP );
  }

  /**
   * Change the status of a favorite IVac, then add/delete it from the list
   *
   * @param {string} key
   * @param {IVac} fav
   * @param {boolean} b
   * @memberof MyMapsService
   */
  async changeFav( key: key, fav: IVac, b: boolean ): Promise<void> {
    const dllMap = this.dllMapsP.get( key );
    await this.db.setFavorite( key, b );
    if ( dllMap ) {
      return this.changeDll( key, dllMap );
    } else {
      return this.changeDll( key, fav );
    }
  }

  /**
   * Change the status of a dll IVac, then add/delete it from the list
   *
   * @param {string} key
   * @param {IVac} [dllMap]
   * @memberof MyMapsService
   */
  changeDll( key: key, dllMap?: IVac ): Promise<void> {
    return new Promise( ( resolve, reject ) => {
      const onFinish = () => {
        this.storage.changeDllMap( this.dllMapsP ).finally( () => {
          this.dllMapsBSP.next( this.dllMapsP );
          this.lastChangeBSP.next( { key, vac: dllMap } );
          resolve();
        } );
      };

      if ( dllMap ) {
        this.dllMapsP.set( key, dllMap );

        if ( !this.updatingByKeyBSP.get( key ) ) {
          const bs = new BehaviorSubject<MAP_STATUS>( MAP_STATUS.FINISH_UPDATE );
          this.updatingByKeyBSP.set( key, bs );
        }
        onFinish();
      } else {
        this.deleteDllMap( key ).finally( () => {
          onFinish();
        } );
      }
    } );
  }

  private deleteDllMap( key: key ): Promise<any> {
    const dllMap = this.dllMapsP.get( key );
    // Remove the subscription from the pdf recovery
    const s = this.updatingSubcriptionByKey.get( key );
    if ( s ) {
      s.unsubscribe();
      this.updatingSubcriptionByKey.delete( key );
    }
    // Remove the observables listening to update
    const bs = this.updatingByKeyBSP.get( key );
    if ( bs ) {
      bs.next( MAP_STATUS.STOPPED );
      bs.complete();
      this.changeMapOnNbUpdating( key, true );
    }
    // Remove the map
    this.dllMapsP.delete( key );
    return this.deletePdf( dllMap.map.downloadedMap );
  }

  /**
   * Create the IDownloadedMap interface then add it to a Vac in order to save the filePath and fileSize of a new dll map
   *
   * @param {IVac} vac
   * @param {FileEntry} f
   * @returns {Vac}
   * @memberof MyMapsService
   */
  savePdf( vac: IVac, f: FileEntry ): IVac {
    const map = vac.map;

    const downloadedMap: IDownloadedMap = {
      key: vac.key,
      version: map.version,
      fileSize: map.fileSize,
      filePath: f.nativeURL,
      folderPath: f.nativeURL.split( f.name )[ 0 ],
      fileName: f.name,
    };

    // console.log( 'savePdf : ' + f.nativeURL );
    vac.map.downloadedMap = downloadedMap;
    this.changeDll( vac.key, vac ).then( ( e ) => {
      this.db.putDownloadedMap( downloadedMap ).then( () => { } );
    } );
    return vac;
  }

  private deletePdf( downloadedMap: IDownloadedMap ): Promise<any> {
    this.db.removeDownloadedMap( downloadedMap.key ).finally( () => { } );
    return this.pdfManager.removePdf(
      downloadedMap.folderPath,
      downloadedMap.fileName
    );
  }

  /**
   * Delete all the DLL maps, with or without the fav one
   *
   * @param {boolean} [favToo=false]
   * @param {string} [searchTerm='']
   * @memberof MyMapsService
   */
  async deleteAllDll(
    favToo: boolean = false,
    searchTerm: string = ''
  ): Promise<void> {
    this.stopAllDownload = true;
    clearTimeout( this.timeoutUpdateRecur );

    const keysToDelete: key[] = [];

    this.dllMapsP.forEach( ( v: IVac ) => {
      if ( favToo || ( !favToo && !this.cache.isFavoriteMap( v.key ) ) ) {
        if ( searchTerm && searchTerm !== '' ) {
          if (
            keyToString( v.key ).includes( searchTerm ) ||
            v.city.includes( searchTerm )
          ) {
            keysToDelete.push( v.key );
          }
        } else {
          keysToDelete.push( v.key );
        }
      }
    } );

    keysToDelete.forEach( ( k: key ) => {
      this.deleteDllMap( k );
      this.lastChangeBSP.next( { key: k, vac: null } );
    } );

    this.storage.changeDllMap( this.dllMapsP ).finally( () => {
      this.dllMapsBSP.next( this.dllMapsP );
      this.calculateMapSize();
      this.deleteAllOP.next( true );
      this.stopAllDownload = false;
    } );

    /*
    for (let i = 0, len = keysToDelete.length; i < len; i++) {
      await this.changeDll(keysToDelete[i]);
    }
    */
  }

  private changeMapOnNbUpdating( key: key, remove: boolean = false ): void {
    if ( remove ) {
      this._nbMapsUpdating.delete( key );
    } else {
      this._nbMapsUpdating.set( key, true );
    }
  }

  /**
   * Update a specific map based on key, create the status of isUpdating and the subscription to the pdf recovery
   *
   * @param {key} key
   * @param {boolean} [noAlerts=false]
   * @returns {void}
   * @memberof MyMapsService
   */
  updateMap(
    key: key,
    noAlerts: boolean = false,
    mapOnError: IVac = null
  ): Promise<void> {
    return new Promise( ( resolve, reject ) => {
      const serverMap: IMap = this.cache.getVac( key ).map;
      const currentDownloadedMap: IDownloadedMap =
        this.cache.getDownloadedMap( key );

      let dllMap = this.dllMapsP.get( key );
      if ( !dllMap ) {
        if ( mapOnError ) {
          dllMap = mapOnError;
        } else {
          resolve();
          return;
        }
      }

      if (
        currentDownloadedMap &&
        serverMap.version === currentDownloadedMap.version
      ) {
        resolve();
        return;
      }
      if ( this.updatingByKeyBSP.get( key ).value === MAP_STATUS.START_UPDATE ) {
        resolve();
        return;
      }

      const onError = ( reason ) => {
        // console.log( 'onError', reason );
        if ( !noAlerts ) {
          this.alerts.basicError(
            'ALERTS.PDF.ERROR.SUB_TITLE',
            'ALERTS.PDF.ERROR.MESSAGE'
          );
        }
        const subscription = this.updatingSubcriptionByKey.get( key );
        if ( subscription ) { subscription.unsubscribe(); }
        this.updatingByKeyBSP.get( key ).next( MAP_STATUS.STOPPED_ERROR );
        this.changeMapOnNbUpdating( key, true );

        // Case of dll all
        if (
          dllMap.map.downloadedMap.version === MyMapsService.DLL_ALL_VERSION
        ) {
          this.changeDll( key );
        }

        reject( reason );
      };

      // In case of unexpected failure
      const timeOut = setTimeout( () => {
        onError( 'updateMap::timeout' );
      }, 180000 );

      this.updatingByKeyBSP.get( key ).next( MAP_STATUS.START_UPDATE );
      this.changeMapOnNbUpdating( key );
      const vac: IVac = this.cache.getVac( key );
      this.updatingSubcriptionByKey.set(
        key,
        this.webServices.getPdf( vac.oaci, vac.type, false ).subscribe(
          ( f: FileEntry ) => {
            clearTimeout( timeOut );
            this.updatingSubcriptionByKey.get( key ).unsubscribe();
            this.updatingByKeyBSP.get( key ).next( MAP_STATUS.FINISH_UPDATE );
            this.changeMapOnNbUpdating( key, true );
            this.savePdf( dllMap, f );
            resolve();
          },
          ( err ) => {
            clearTimeout( timeOut );
            onError( err );
          }
        )
      );
    } );
  }

  /**
   * Launch the dll of the map and then register it or open it
   *
   * @param {Vac} vac
   * @param {boolean} [temp=true]
   * @param {boolean} [open=true]
   * @param {boolean} [loader=true]
   * @param {boolean} [alert=true]
   * @returns {Promise<Vac>}
   * @memberof MyMapsService
   */
  async downloadPDF(
    vac: IVac,
    temp: boolean = true,
    open: boolean = true,
    loader: boolean = true,
    alert: boolean = true
  ): Promise<IVac> {
    const result = new Promise<IVac>( async ( resolve, reject ) => {
      if ( !vac.map ) {
        if ( alert ) {
          this.alerts.basicAlert( {
            header: ' ',
            message: 'ALERTS.PDF.NO_MAP.MESSAGE',
            backdropDismiss: false,
          } );
        }
        reject();
        return;
      }

      let loading: HTMLIonLoadingElement;
      if ( loader ) { loading = await this.loader.create( {} ); }
      if ( loader ) { await loading.present(); }

      if ( this.platform.is( 'cordova' ) ) {
        this.webServices.getPdf( vac.oaci, vac.type, temp ).subscribe(
          ( f: FileEntry ) => {
            if ( !temp ) {
              resolve( this.savePdf( vac, f ) );
            }
            if ( open ) {
              // console.log( 'open pdf : ' + f.nativeURL + '/' + this.file.dataDirectory + '/' + this.file.documentsDirectory );
              this.pdfManager.openPdf( {
                filePath: f.nativeURL,
                nameToDisplay: vac.map.fileName,
              } );
              resolve( vac );
            }
            if ( loader ) { loading.dismiss(); }
          },
          ( error ) => {
            // console.log( 'PDF error:', error );
            if ( alert ) {
              this.alerts.basicError(
                'ALERTS.PDF.ERROR.SUB_TITLE',
                'ALERTS.PDF.ERROR.MESSAGE'
              );
            }
            if ( loader ) { loading.dismiss(); }
            reject();
          }
        );
      } else {
        this.webServices.openFromBrowser( vac.oaci, vac.type ).subscribe(
          ( res: Blob ) => {
            if ( open ) {
              if ( environment.isWeb ) {
                saveAs( res, vac.map.fileName );
              } else {
                const fileURL = URL.createObjectURL( res );
                window.open( fileURL, '_blank' );
              }
            } else {
              console.log(
                `*** downloaded ${ vac.key }[${ vac.map.type }] => ${ res.type } [${ ( res.size / 1024 ) >> 0
                } Ko]`
              );
            }
            if ( loader ) { loading.dismiss(); }
            resolve( vac );
          },
          ( error ) => {
            console.error( error );
            if ( loader ) { loading.dismiss(); }
            reject();
          }
        );
      }
    } );
    return result;
  }

  /**
   * Download all maps eligible
   * @param updateOnly  if it's true, update only
   */
  bulkDownload( mapsToDownload: IVac[] = null, noAlerts: boolean = true ): void {
    const slot = 3;
    let maps_to_download: IVac[] = [];
    let queue: IterableIterator<IVac>;
    let isFinished = false;

    // indique qu'un processus de téléchargement par lot est en cours
    this._isDownloadingAll.next( true );

    if ( mapsToDownload ) {
      maps_to_download = mapsToDownload;
    } else {
      // récupère la liste de tous les Ivac
      const vacs: IVac[] = this.cache.getVacs();

      // génère la file d'attente des téléchargements
      vacs.forEach( ( vac ) => {
        if ( this.isDownloadEligible( vac ) ) {
          maps_to_download.push( vac );
        }
      } );
    }

    maps_to_download.sort( orderByCity );

    queue = maps_to_download.values();

    const requestNextDownload = () => {
      const next = queue.next();

      if ( this.stopAllDownload ) {
        finished( 'abort by user' );
      } else if ( !this.network.isConnected ) {
        finished( 'no connection' );
      } else if ( next.done ) {
        finished( 'no more download' );
      } else {
        const vac: IVac = next.value;

        this.downloadVac( vac, noAlerts ).finally( () => {
          requestNextDownload();
        } );
      }
    };

    const finished = ( reason: string ) => {
      if ( isFinished ) { return; }
      isFinished = true;
      this.stopAllDownload = false;
      this._isDownloadingAll.next( false );
      if ( environment.isDebug ) { console.warn( `QUEUE ENDED : ${ reason }` ); }
    };

    // nombre de téléchargements en //
    for ( let i = 0; i < slot; i++ ) {
      if ( !isFinished ) { requestNextDownload(); }
    }
  }

  downloadVac( vac: IVac, noAlerts: boolean = true ): Promise<void> {
    return new Promise( ( resolve, reject ) => {
      vac.map.downloadedMap = {
        key: vac.key,
        version: MyMapsService.DLL_ALL_VERSION,
        fileName: '',
        filePath: '',
        fileSize: 0,
        folderPath: '',
      };

      if ( this.platform.is( 'cordova' ) ) {
        this.changeDll( vac.key, vac ).then( () => {
          this.updateMap( vac.key, noAlerts ).then( ( b ) => {
            resolve();
          } );
        } );
      } else {
        this.downloadPDF( vac, false, false, false, false )
          .then(
            ( v: IVac ) => {
              // console.log( "success on ", v.id );
              // TODO -> put in downloaded table ?
            },
            ( error ) => {
              console.log( 'error on ', vac.key, error );
            }
          )
          .finally( () => {
            resolve();
          } );
      }
    } );
  }

  /**
   * Update all the maps
   *
   * @param {boolean} [justFav=false]
   * @param {boolean} [noAlerts=false]
   * @param {string} [searchTerm='']
   * @memberof MyMapsService
   */
  updateAll(
    justFav: boolean = false,
    noAlerts: boolean = false,
    searchTerm: string = ''
  ): void {
    const queue: IVac[] = [];
    this.cache.getVacsWithDownloadedMap().forEach( ( v: IVac ) => {
      if ( ( justFav && this.cache.isFavoriteMap( v.key ) ) || !justFav ) {
        if ( searchTerm && searchTerm !== '' ) {
          if (
            keyToString( v.key ).includes( searchTerm ) ||
            v.city.includes( searchTerm )
          ) {
            if ( this.isDownloadEligible( v, true ) ) { queue.push( v ); }
          }
        } else {
          if ( this.isDownloadEligible( v, true ) ) { queue.push( v ); }
        }
      }
    } );
    this.bulkDownload( queue, noAlerts );
  }

  /**
   * Détermine si une carte doit être téléchargée (mise à jour ou téléchargement complet)
   * @param vac
   * @param updateOnly  Si ce paramètre est passé, ne vérifie que si une carte doit être mise à jour
   */
  private isDownloadEligible( vac: IVac, updateOnly: boolean = false ): boolean {
    if ( !vac || !vac.map ) { return false; }
    const downloadedMap: IDownloadedMap = this.cache.getDownloadedMap( vac.key );

    if (
      // MAP NOT DOWNLOADED AND TYPE ASKED BY USER
      ( !downloadedMap &&
        !updateOnly &&
        this.authorizedTypesP.value.indexOf( vac.type ) !== -1 ) ||
      // MAP NOT UPDATED
      ( downloadedMap !== undefined && downloadedMap.version !== vac.map.version )
    ) {
      return true;
    }
  }
}
