import { Injectable } from '@angular/core';
import { Zone } from 'src/models/classes/zone';
import { IZone } from 'src/models/interfaces/points';
import { ZONES_TYPE, TMA_TYPE } from 'src/models/enums';
import { LeafletUtilsService } from '../../leaflet-utils/leaflet-utils.service';
import { PolylineLeafOption } from 'src/models/types';
import { DatabaseService } from '../../database/database.service';
import { UtilsService } from '../../utils/utils.service';
import { AzbaManagerService } from '../../azba/azba-manager.service';
import { environment } from 'src/environments/environment';
import {
  Direction,
  IRTBAPolylineOptions,
  Label,
} from 'src/models/interfaces/leaf';
import { toZoneInfo } from 'src/models/interfaces/web-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable( {
  providedIn: 'root',
} )
export class ZonesService {

  constructor(
    private leafUtils: LeafletUtilsService,
    private db: DatabaseService,
    private utils: UtilsService,
    private azbaManager: AzbaManagerService,
    private translate: TranslateService
  ) {
    this.initProperties();
    // TODO when DGAC is ok with the vizualization, transfer this right after the recovery of data in order to avoir the calculation at each launch
    this.db.getZones().then( ( zones: IZone[] ) => {
      const firs = [];
      const sivs = [];
      const tmas = [];
      const ctrs = [];
      const zonesRs = [];
      const zonesDs = [];
      const zonesPs = [];
      zones.forEach( ( z: IZone ) => {
        // 'Type : ' + z.type);
        switch ( z.type ) {
          case ZONES_TYPE.RTBA:
            this._rtba.push( z );
            break;
          case ZONES_TYPE.FIR:
            firs.push( z );
            break;
          case ZONES_TYPE.SIV:
            sivs.push( z );
            break;
          case ZONES_TYPE.TMA:
            tmas.push( z );
            break;
          case ZONES_TYPE.CTR:
            ctrs.push( z );
            break;
          case ZONES_TYPE.ZONES_R:
            zonesRs.push( z );
            break;
          case ZONES_TYPE.ZONES_D:
            zonesDs.push( z );
            break;
          case ZONES_TYPE.ZONES_P:
            zonesPs.push( z );
            break;
          default:
            console.error( '*** zone non reconnue', z );
        }
      } );

      this.alreadyDrawn = {};
      this.alreadyDrawn[ ZONES_TYPE.FIR ] = [];

      this.populateZones( firs, this.FIRs, ZONES_TYPE.FIR, this._cb );

      this.populateZones( sivs, this.SIVs, ZONES_TYPE.SIV, this._cb );

      this.populateZones( tmas, this.TMAs, ZONES_TYPE.TMA, this._cb );

      this.populateZones( ctrs, this.CTRs, ZONES_TYPE.CTR, this._cb );

      this.populateZones( zonesRs, this.ZONES_Rs, ZONES_TYPE.ZONES_R, this._cb );

      this.populateZones( zonesDs, this.ZONES_Ds, ZONES_TYPE.ZONES_D, this._cb );

      this.populateZones( zonesPs, this.ZONES_Ps, ZONES_TYPE.ZONES_P, this._cb );
    } );
  }
  RTBAs: Map<string, Zone> = new Map<string, Zone>();
  FIRs: Map<string, Zone> = new Map<string, Zone>();
  SIVs: Map<string, Zone> = new Map<string, Zone>();
  TMAs: Map<string, Zone> = new Map<string, Zone>();
  CTRs: Map<string, Zone> = new Map<string, Zone>();
  ZONES_Rs: Map<string, Zone> = new Map<string, Zone>();
  ZONES_Ds: Map<string, Zone> = new Map<string, Zone>();
  ZONES_Ps: Map<string, Zone> = new Map<string, Zone>();

  private colors: Map<ZONES_TYPE, string> = new Map<ZONES_TYPE, string>();
  private dashArrays: Map<ZONES_TYPE, string> = new Map<ZONES_TYPE, string>();
  private weights: Map<ZONES_TYPE, number> = new Map<ZONES_TYPE, number>();
  private classes: Map<ZONES_TYPE, string> = new Map<ZONES_TYPE, string>();

  private _rtba = [];

  // To avoid having overlapping line between zones
  alreadyDrawn: any;

  private _cb = function ( param: { id: string; zone: Zone; popin?: L.Popup } ) {
    if ( param.popin ) {
      if ( param.popin.getPopup() ) {
        param.popin = param.popin.getPopup();
      }
      if ( param.popin.isOpen() ) {
        param.popin.closePopup();
      } else {
        setTimeout( () => param.popin.openPopup() );
      }
    }
  };

  private initProperties(): void {
    this.colors.set( ZONES_TYPE.RTBA, '#dedede' );
    this.colors.set( ZONES_TYPE.FIR, '#8e8e93' );
    this.colors.set( ZONES_TYPE.SIV, '#00713b' );
    this.colors.set( ZONES_TYPE.TMA, '#0b4ea2' ); // '#ee1c25' );
    this.colors.set( ZONES_TYPE.CTR, '#0b4ea2' );
    this.colors.set( ZONES_TYPE.ZONES_R, '#ee1c25' );
    this.colors.set( ZONES_TYPE.ZONES_D, '#ee1c25' );
    this.colors.set( ZONES_TYPE.ZONES_P, '#ee1c25' );

    this.dashArrays.set( ZONES_TYPE.SIV, '10' );
    this.dashArrays.set( ZONES_TYPE.CTR, '20' );

    this.weights.set( ZONES_TYPE.RTBA, 1 );
    this.weights.set( ZONES_TYPE.FIR, 6 );
    this.weights.set( ZONES_TYPE.SIV, 3 );
    this.weights.set( ZONES_TYPE.TMA, 3 );
    this.weights.set( ZONES_TYPE.CTR, 3 );
    this.weights.set( ZONES_TYPE.ZONES_R, 3 );
    this.weights.set( ZONES_TYPE.ZONES_D, 3 );
    this.weights.set( ZONES_TYPE.ZONES_P, 3 );

    this.classes.set( ZONES_TYPE.RTBA, 'rtba-zone' );
    this.classes.set( ZONES_TYPE.FIR, 'fir-zone' );
    this.classes.set( ZONES_TYPE.SIV, 'siv-zone' );
    this.classes.set( ZONES_TYPE.TMA, 'tma-zone' );
    this.classes.set( ZONES_TYPE.CTR, 'ctr-zone' );
    this.classes.set( ZONES_TYPE.ZONES_R, 'r-zone' );
    this.classes.set( ZONES_TYPE.ZONES_D, 'd-zone' );
    this.classes.set( ZONES_TYPE.ZONES_P, 'p-zone' );
  }

  private generateOptions( zoneType: ZONES_TYPE ): PolylineLeafOption {
    const options: PolylineLeafOption = {
      polylineOptions: {
        color: this.colors.get( zoneType ),
        dashArray: this.dashArrays.get( zoneType ),
        weight: this.weights.get( zoneType ),
        fill: false,
      },
      popinOptions: {
        content: '',
        closeButton: false,
        closeOnClick: true,
        autoClose: true,
        className: 'layer-zone ' + this.classes.get( zoneType ),
        autoPan: false,
      },
    };

    if (
      zoneType === ZONES_TYPE.ZONES_R ||
      zoneType === ZONES_TYPE.ZONES_D ||
      zoneType === ZONES_TYPE.ZONES_P
    ) {
      options.polylineOptions.fillOpacity = 1;
      ( options.polylineOptions as any ).fill =
        zoneType === ZONES_TYPE.ZONES_P
          ? 'url(/assets/maps/zones-p.gif)'
          : 'url(/assets/maps/zones-rd.gif)';
    }

    if ( zoneType === ZONES_TYPE.RTBA ) {
      options.polylineOptions.fill = true;
      options.polylineOptions.fillOpacity = 0.7;
    }

    return options;
  }

  private generateContent( z: IZone ): string {
    let name = z.name;
    if (
      ( z.type === ZONES_TYPE.RTBA ||
        z.type === ZONES_TYPE.ZONES_R ||
        z.type === ZONES_TYPE.ZONES_D ||
        z.type === ZONES_TYPE.ZONES_P ) &&
      z.initialCodeType
    ) {
      name = z.initialCodeType.replace( 'RTBA', 'R' ) + ' ' + z.name;
    }

    let codeClassContent = '';
    let cptCodeClass = 1;
    if ( z.codeClass ) {
      codeClassContent += `<div class="code-class code-class-${ cptCodeClass }">
          <span>${ z.codeClass }</span>
        </div>
        `;
    }

    let infosContent = `<div class="zone-infos-container">
        <span class="title">
          ${ name }
        </span>
        <br>
        <div class="infos">
          ${ toZoneInfo( z, false ) }
          <hr>
          ${ toZoneInfo( z, true ) }
        `;

    if ( z.subZones && z.subZones.length ) {
      z.subZones.forEach( ( subZ: IZone ) => {
        if ( subZ.codeClass ) {
          cptCodeClass++;
          codeClassContent += `<div class="code-class code-class-${ cptCodeClass }">
            <span>${ subZ.codeClass }</span>
          </div>`;
        }
        infosContent += `<hr>
        ${ toZoneInfo( subZ, true ) }`;
      } );
    }

    if ( z.type === ZONES_TYPE.RTBA ) {
      const azbaData = this.azbaManager.getActivatedAZBA( z.id );
      let currentPeriod: string = null;
      let period: string = null;

      if ( azbaData && azbaData.timeSlots && azbaData.timeSlots.length ) {
        infosContent += `<div class="rtba">`;

        azbaData.timeSlots.forEach( ( timeslot ) => {
          period = `${ timeslot.startDate } ${ timeslot.endDate }`;
          // display period line when changing
          if ( period !== currentPeriod ) {
            currentPeriod = period;
            infosContent += `<br>${ this.translate.instant(
              'AZBA.ACTIVE_POPUP_LABEL',
              {
                start: timeslot.startDate,
                end: timeslot.endDate,
              }
            ) }<br>`;
          }

          // display timeslot
          infosContent += `${ timeslot.startHour } - ${ timeslot.endHour }<br>`;
        } );

        infosContent += `</div>`;
      }
    }

    infosContent += ` </div>
    </div>`;

    let frequency = '';
    if ( z.type === ZONES_TYPE.CTR && z.frequency && z.frequency.VDF ) {
      frequency += `
      <div class="zone-frequency">
      ${ z.frequency.VDF } MHZ
      </div>`;
    }

    return codeClassContent + infosContent + frequency;
  }

  private generateZones(
    z: IZone,
    id: string,
    oldOptions: PolylineLeafOption,
    callback: Function
  ): ( L.Polyline | L.Polygon )[] {
    const options: PolylineLeafOption = this.utils.deepCopy(
      oldOptions
    ) as PolylineLeafOption;
    const zoneType = z.type;

    let newZones = [];

    switch ( zoneType ) {
      case ZONES_TYPE.RTBA:
        const isActive: boolean = this.azbaManager.getActivatedAZBA( z.id ) != null;
        let config: IRTBAPolylineOptions;

        if ( isActive ) {
          // Rouge
          config =
            z.valDistVerLower > 0
              ? AzbaManagerService.RTBA_ACTIVE
              : AzbaManagerService.RTBA_ACTIVE_FLOOR;
        } else {
          // Bleu
          config =
            z.valDistVerLower > 0
              ? AzbaManagerService.RTBA_UNACTIVE
              : AzbaManagerService.RTBA_UNACTIVE_FLOOR;
        }

        options.polylineOptions.fill = true;
        options.polylineOptions.fillColor = config.fillColor;
        options.polylineOptions.color = config.color;
        options.polylineOptions.fillOpacity = config.fillOpacity;

        newZones.push(
          this.leafUtils.createPolylineOrPolygon(
            {
              id,
              coords: z.coords,
              options,
              callback,
              completed: true,
            },
            false
          )
        );
        break;

      case ZONES_TYPE.FIR:
        newZones = this.leafUtils.createPolygonPerpendicularDash( {
          id,
          coords: z.coords,
          options,
          callback,
          completed: true,
          alternate: true,
          alreadyDrawn: this.alreadyDrawn[ zoneType ],
        } );
        break;

      case ZONES_TYPE.SIV:
        newZones.push(
          this.leafUtils.createPolylineOrPolygon( {
            id,
            coords: z.coords,
            options,
            callback,
            completed: true,
          } )
        );
        break;

      case ZONES_TYPE.TMA:
        options.popinOptions.className =
          'layer-zone ' + this.classes.get( zoneType ) + ' tma-' + z.codeClass;
        switch ( z.codeClass ) {
          case TMA_TYPE.A:
            options.polylineOptions.color = '#ee1c25';
            break;
          case TMA_TYPE.B:
          case TMA_TYPE.C:
          case TMA_TYPE.D:
            options.polylineOptions.color = '#0b4ea2';
            break;
          case TMA_TYPE.E:
            options.polylineOptions.color = '#6c8cc6';
            break;
        }
      case ZONES_TYPE.CTR:
        newZones = this.leafUtils.createPolygonBordered(
          {
            id,
            coords: z.coords,
            options,
            callback,
          },
          z.codeClass === TMA_TYPE.E && z.type === ZONES_TYPE.TMA ? 1.5 : 0.75
        );
        break;

      case ZONES_TYPE.ZONES_R:
      case ZONES_TYPE.ZONES_D:
      case ZONES_TYPE.ZONES_P:
        newZones = this.leafUtils.createPolygonSVGBorder( {
          id,
          coords: z.coords,
          options,
          callback,
        } );
        break;
    }
    newZones.push(
      this.leafUtils.createExtendedInvisible( {
        id,
        coords: z.coords,
        options,
        callback,
        completed: true,
      } )
    );
    return newZones;
  }

  refreshRTBA(): void {
    console.warn( '*** refreshRTBA' );

    this.RTBAs.clear();

    let zoneInRouge = [];
    let zoneInBleu = [];

    this._rtba.forEach( ( z: IZone ) => {
      const isActive: boolean = this.azbaManager.getActivatedAZBA( z.id ) != null;

      if ( isActive ) {
        // Rouge
        zoneInRouge.push( z );
      } else {
        // Bleu
        zoneInBleu.push( z );
      }
    } );

    // this.populateZones( this._rtba, this.RTBAs, ZONES_TYPE.RTBA, this._cb );
    this.populateZones( zoneInBleu, this.RTBAs, ZONES_TYPE.RTBA, this._cb );
    this.populateZones( zoneInRouge, this.RTBAs, ZONES_TYPE.RTBA, this._cb );
  }

  private populateZones(
    zoneIn: IZone[],
    zoneOut: Map<string, Zone>,
    zoneType: ZONES_TYPE,
    callback: Function
  ): void {
    const options = this.generateOptions( zoneType );

    /**
     * Trie les zones pour les traiter dans cet ordre :
     * 1 - Les zones inactives et avec un plancher > 0
     * 2 - Les zones inactives et avec un plancher = 0
     * 3 - Les zones actives et avec un plancher > 0
     * 4 - Les zones actives et avec un plancher = 0
     * (Demande de la DGAC)
     */
    zoneIn.sort( ( a: IZone, b: IZone ) => {
      let isActiveA: boolean = this.azbaManager.getActivatedAZBA( a.id ) != null;
      let isActiveB: boolean = this.azbaManager.getActivatedAZBA( b.id ) != null;

      let aFloor = a.valDistVerLower;
      let bFloor = b.valDistVerLower;

      if ( isActiveA && !isActiveB ) {
        return 1;
      } else if ( !isActiveA && isActiveB ) {
        return -1;
      } else {
        if ( aFloor < bFloor ) {
          return 1;
        } else if ( aFloor > bFloor ) {
          return -1;
        }
        else {
          return 0;
        }
      }

    } );

    zoneIn.forEach( ( z: IZone ) => {
      // console.log('popuplateZone : ' + JSON.stringify(z));
      // avoid zone duplication (R and RTBA) because RTBA is always drawn on web version
      if ( z.type === ZONES_TYPE.ZONES_R && environment.isWeb && z.isRTBA ) {
        // console.log('filter out zone R if RTBA and WEB');
        return null;
      }

      options.popinOptions.content = this.generateContent( z );

      let subZones = [];
      if ( z.subZones && z.subZones.length ) {
        z.subZones.forEach( ( subZ: IZone ) => {
          subZones = subZones.concat(
            this.generateZones( subZ, z.id, options, callback )
          );
        } );
      }

      let label: Label;

      if ( zoneType === ZONES_TYPE.RTBA ) {
        const cssClass = [ z.id ];
        if ( this.azbaManager.getActivatedAZBA( z.id ) ) { cssClass.push( 'active' ); }

        label = {
          text: 'R ' + z.name,
          direction: z.valDistVerLower > 0 ? Direction.left : Direction.right,

          // polylabel is better than centroid for concave polygones (crescent)
          position: this.utils.polylabel( z.coords ),

          cssClass,
        };
      }
      const zone: Zone = new Zone(
        subZones.concat( this.generateZones( z, z.id, options, callback ) ),
        label,
        options.popinOptions.content
      );
      zoneOut.set( z.id, zone );
    } );
  }
}
