<div class="update" *ngIf="visible" [ngClass]="{'green': upToDate }" tooltip='' arrow="true"
  tooltipHtml="{{getNextRefreshHTML()}}" positionV="top" mobileEvent="click" desktopEvent="hover">
  <ion-icon name="map-outline"></ion-icon>
  <div class="update-text">
    <span>
      {{ 'MAP.UPDATED'| translate}}
    </span>
    <span class="date">
      {{dateDone | date:(currentLang === 'fr' ?'dd/MM/yyyy' :'MM/dd/yyyy' )}}
    </span>
  </div>
</div>