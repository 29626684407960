import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class TokenInterceptorService implements HttpInterceptor {

  constructor() { }

  /**
   * Intercept the HttpRequest and add to it the new header's parameters
   *
   * @param {HttpRequest<any>} request HttpRequest<any> to improve
   * @param {HttpHandler} next HttpHandler the next interceptor to occur
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenInterceptorProvider
   */
  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const newHeader = {
      Authorization: 'Basic ' + btoa( 'api:L4b6P!d9+YuiG8-M' )
    };

    request.params.append( 'withCredentials', 'false' );

    request = request.clone( {
      setHeaders: newHeader
    } );

    return next.handle( request );
  }
}
