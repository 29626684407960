import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';

@Injectable( {
  providedIn: 'root',
} )
export class NetworkService {
  private onConnect: Subscription;
  private onDisconnect: Subscription;

  private isConnectedP = true;
  private isConnectedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>( this.isConnectedP );

  constructor( private network: Network, private platform: Platform ) {
    this.platform.ready().then( () => {
      this.isConnectedSubject.subscribe( ( b: boolean ) => {
        this.isConnectedP = b;
      } );
      this.refresh();
      if ( this.platform.is( 'cordova' ) ) {
        this.onConnect = this.network.onConnect().subscribe( () => {
          console.log( 'NETWORK CONNECTED' );
          this.isConnectedSubject.next( true );
        } );

        this.onDisconnect = this.network.onDisconnect().subscribe( () => {
          console.log( 'NETWORK DISCONNECTED' );
          this.isConnectedSubject.next( false );
        } );
      } else {
        window.addEventListener( 'offline', () => {
          console.log( 'offline' );
          this.isConnectedSubject.next( false );
        } );

        window.addEventListener( 'online', () => {
          console.log( 'online' );
          this.isConnectedSubject.next( true );
        } );
      }
    } );
  }

  refresh() {
    // check network connection only on mobile device
    this.isConnectedP = this.platform.is( 'cordova' )
      ? this.network.type !== 'none'
      : window.navigator.onLine;
    this.isConnectedSubject.next( this.isConnectedP );
  }

  checkConnection(): Observable<boolean> {
    return this.isConnectedSubject.asObservable();
  }

  get isConnected(): boolean {
    return this.isConnectedP;
  }
}
