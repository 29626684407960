import { Component, Input } from '@angular/core';
import { IEditablePage } from 'src/models/interfaces/web-services';
import { TranslateService } from '@ngx-translate/core';

@Component( {
  selector: 'editable-page',
  templateUrl: './editable-page.component.html',
  styleUrls: [ './editable-page.component.scss' ],
} )
export class EditablePageComponent {

  @Input() title: string;
  @Input() page: IEditablePage;
  language: string;

  constructor( private translate: TranslateService ) {
    this.language = this.translate.currentLang;
  }
}
