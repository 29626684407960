import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'attributeExact'
} )
export class AttributeExactPipe implements PipeTransform {

  /**
   * Return a array from the original 'value' array, where all element have a properties searchTerm stricly equal to searchValue
   *
   * @param {any[]} value
   * @param {...any[]} args
   * @returns {*}
   * @memberof AttributeExactPipe
   */
  transform( value: any[], ...args: any[] ): any {
    let searchTerm = args[ 0 ];
    let searchValue = args[ 1 ];

    let result = [];
    if ( !value || !searchTerm ) return result

    value.forEach( ( v: any ) => {
      if ( v[ searchTerm ] === searchValue ) {
        result.push( v );
      }
    } );

    return result;
  }

}
