import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'filterBy'
} )
export class FilterByPipe implements PipeTransform {

  /**
   * Return, from an array of values, all the value where one at least of the attributesToSearch includes searchTerm
   *
   * @param {any[]} value
   * @param {...any[][]} args
   * @returns {*}
   * @memberof FilterByPipe
   */
  transform( values: any[], ...args: ( string[] | string )[] ): any {
    if ( !args || !args[ 0 ] || !args[ 1 ] || !values ) { return values; }
    const searchTerm = args[ 0 ];
    const isSearchTermArray = searchTerm instanceof Array;
    const attributesToSearch = args[ 1 ] as string[];

    const result = [];
    values.forEach( ( v: any ) => {
      let toKeep = false;
      attributesToSearch.forEach( ( arg: string ) => {
        if ( isSearchTermArray ) {
          ( searchTerm as string[] ).forEach( ( sT: any ) => {
            if ( v[ arg ].includes( sT ) ) { toKeep = true; }
          } );
        } else if ( v[ arg ].includes( searchTerm ) ) { toKeep = true; }
      } );

      if ( toKeep ) { result.push( v ); }
    } );
    return result;
  }

}
