import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { ICoords } from 'src/models/interfaces/ground';
import { IIconLeafOption, IPopupLeafOption } from 'src/models/interfaces/leaf';
import { MarkerLeafOption, PolylineLeafOption } from 'src/models/types';
import { UtilsService } from '../utils/utils.service';
import * as turf from '@turf/turf';

@Injectable( {
  providedIn: 'root',
} )
export class LeafletUtilsService {
  static LENGTH_INNER_BOUNDS = 0.01;

  createIcon(
    iconUrl: string,
    iconSize: [ number, number ] = [ 40, 40 ],
    iconAnchor: [ number, number ] = [ 20, 20 ],
    className: string = ''
  ): L.Icon {
    return new L.Icon( {
      iconUrl,
      iconSize,
      iconAnchor,
      className,
    } );
  }

  get defaultIcon(): L.Icon {
    return this.createIcon( './assets/icon/favicon.png' );
  }
  get userIcon(): L.Icon {
    return this.createIcon( './assets/icon/icon-location-two.png' );
  }
  get aerodromeIcon(): L.Icon {
    return this.createIcon(
      './assets/icon/aerodrome.png',
      [ 40, 40 ],
      [ 20, 20 ],
      'aerodrome-img'
    );
  }
  get helistationIcon(): L.Icon {
    return this.createIcon( './assets/icon/helistation.png' );
  }
  get vfrIcon(): L.Icon {
    return this.createIcon(
      './assets/icon/layers/VFR-blue.png',
      [ 42, 25 ],
      [ 21, 12.5 ]
    );
  }
  get vorIcon(): L.Icon {
    return this.createIcon(
      './assets/icon/layers/VOR-blue.png',
      [ 30, 25 ],
      [ 15, 12.5 ]
    );
  }
  get vorDmeIcon(): L.Icon {
    return this.createIcon(
      './assets/icon/layers/VOR-DME-blue.png',
      [ 30, 25 ],
      [ 15, 12.5 ]
    );
  }

  constructor( private utils: UtilsService ) {
    this.overrideSVG();
  }

  // Used to insert SVG to polygon
  private overrideSVG() {
    if ( L.Browser.svg ) {
      L.SVG.include( {
        _updateStyle( layer ) {
          const path = layer._path,
            options = layer.options;

          if ( !path ) {
            return;
          }

          if ( options.stroke ) {
            path.setAttribute( 'stroke', options.color );
            path.setAttribute( 'stroke-opacity', options.opacity );
            path.setAttribute( 'stroke-width', options.weight );
            path.setAttribute( 'stroke-linecap', options.lineCap );
            path.setAttribute( 'stroke-linejoin', options.lineJoin );

            if ( options.dashArray ) {
              path.setAttribute( 'stroke-dasharray', options.dashArray );
            } else {
              path.removeAttribute( 'stroke-dasharray' );
            }

            if ( options.dashOffset ) {
              path.setAttribute( 'stroke-dashoffset', options.dashOffset );
            } else {
              path.removeAttribute( 'stroke-dashoffset' );
            }
          } else {
            path.setAttribute( 'stroke', 'none' );
          }

          if ( options.fill ) {
            if (
              typeof options.fill === 'string' &&
              options.fill.match( /^url\(/ )
            ) {
              // here what we add
              this.__fillPattern( layer );
            } else {
              path.setAttribute( 'fill', options.fillColor || options.color );
            }
            path.setAttribute( 'fill-opacity', options.fillOpacity );
            path.setAttribute( 'fill-rule', options.fillRule || 'evenodd' );
          } else {
            path.setAttribute( 'fill', 'none' );
          }
        },

        __fillPattern( layer ) {
          const path = layer._path,
            options = layer.options;

          if ( !this._defs ) {
            this._defs = L.SVG.create( 'defs' );
            this._container.appendChild( this._defs );
          }
          const _img_url = options.fill.substring( 4, options.fill.length - 1 );
          //  var _ref_id = _img_url + ( Math.random() * Math.pow( 10, 17 ) + Math.random() * Math.pow( 10, 17 ) );
          // var _ref_id = ( Math.random() * Math.pow( 10, 17 ) + Math.random() * Math.pow( 10, 17 ) ).toString();
          const _ref_id = _img_url;
          // _ref_id += new Date().getUTCMilliseconds();
          let _p: any = document.getElementById( _ref_id );
          if ( !_p ) {
            const _im = new Image();
            _im.src = _img_url;

            _p = L.SVG.create( 'pattern' );
            _p.setAttribute( 'id', _ref_id );
            _p.setAttribute( 'x', '0' );
            _p.setAttribute( 'y', '0' );
            _p.setAttribute( 'patternUnits', 'userSpaceOnUse' );
            _p.setAttribute( 'width', '24' );
            _p.setAttribute( 'height', '24' );
            const _rect = L.SVG.create( 'rect' );
            _rect.setAttribute( 'width', '24' );
            _rect.setAttribute( 'height', '24' );
            _rect.setAttribute( 'x', '0' );
            _rect.setAttribute( 'x', '0' );
            _rect.setAttribute( 'fill', 'transparent' ); // options.fillColor || options.color );

            _p.appendChild( _rect );
            this._defs.appendChild( _p );

            const _img = L.SVG.create( 'image' );
            _img.setAttribute( 'x', '0' );
            _img.setAttribute( 'y', '0' );
            _img.setAttributeNS(
              'http://www.w3.org/1999/xlink',
              'href',
              _img_url
            );
            _img.setAttribute( 'width', '24' );
            _img.setAttribute( 'height', '24' );
            _p.appendChild( _img );

            _im.onload = function () {
              _p.setAttribute( 'width', _im.width.toString() );
              _p.setAttribute( 'height', _im.height.toString() );
              _img.setAttribute( 'width', _im.width.toString() );
              _img.setAttribute( 'height', _im.height.toString() );
            };
          }
          // For Safari, lame, we need the full path of the pattern
          path.setAttribute(
            'fill',
            'url(' +
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            '#' +
            _ref_id +
            ')'
          );
        },
      } );
    }
  }

  /**
   * Create marker
   *
   * @param {{ id: string, coords: ICoords, options?: MarkerLeafOption, callback: Function }} params
   * @returns {L.Marker}
   * @memberof LeafletUtilsService
   */
  createMarker( params: {
    id: string;
    coords: ICoords;
    options?: MarkerLeafOption;
    callback: Function;
  } ): L.Marker {
    let markerOptions: IIconLeafOption = {
      icon: this.defaultIcon,
      opacity: 1,
    };
    let popinOptions: IPopupLeafOption = null;

    if ( params.options && params.options.markerOptions ) {
      markerOptions = params.options.markerOptions;
    }
    if ( params.options && params.options.popinOptions ) {
      popinOptions = params.options.popinOptions;
    }

    // Add new marker on the map
    const newMarker = new L.Marker(
      [ params.coords.lat, params.coords.lon ],
      markerOptions
    );

    const functionCallback = () => {
      params.callback( { id: params.id, marker: newMarker } );
    };

    const addClickActionToMarker = () => {
      newMarker.removeEventListener( 'click' ); // HACK: To remove odd comportement that removed the popin
      newMarker.on( 'click', () => {
        functionCallback();
      } );
    };

    // Add popin with event listener
    if ( popinOptions ) {
      this.addPopin(
        popinOptions,
        params.id,
        newMarker,
        functionCallback,
        addClickActionToMarker,
        true
      );
    } else {
      addClickActionToMarker();
    }

    return newMarker;
  }

  /**
   * Add a leaflet popin to a leaflet element
   *
   * @param {IPopupLeafOption} popinOptions
   * @param {string} id
   * @param {(L.Marker | L.Polyline | L.Polygon)} leafElement
   * @param {Function} functionCallback
   * @param {Function} [functionOnParent]
   * @returns {L.Popup}
   * @memberof LeafletUtilsService
   */
  addPopin(
    popinOptions: IPopupLeafOption,
    id: string,
    leafElement: L.Marker | L.Polyline | L.Polygon,
    functionCallback: Function,
    functionOnParent?: Function,
    needOpen?: boolean
  ): L.Popup {
    const popin = new L.Popup( popinOptions );
    const idName = 'popin' + id;
    popin.setContent(
      '<div id="' + idName + '">' + popinOptions.content + '</div>'
    );
    leafElement.on( 'add', () => {
      const p = leafElement.bindPopup( popin ).on( 'popupopen', () => {
        popin.update();
        const htmlElement = document.getElementById( idName );
        if ( htmlElement ) {
          htmlElement.addEventListener( 'click', () => functionCallback( p ) );
        }
      } );
      if ( needOpen ) { p.openPopup(); }
      if ( functionOnParent ) { functionOnParent( p ); }
    } );

    // Destroy all listener when marker is removed from the map
    leafElement.on( 'remove', () => {
      leafElement.off( 'popupopen' );
    } );

    return popin;
  }

  ///////
  /**
   * Create a polyline or polygon
   * @param params
   * @param isPolyline
   */
  createPolylineOrPolygon(
    params: {
      id: string;
      coords: ICoords[];
      options: PolylineLeafOption;
      callback: Function;
      completed?: boolean;
    },
    isPolyline: boolean = true
  ): L.Polyline | L.Polygon {
    const latLng = [];
    params.coords.forEach( ( c: ICoords ) => {
      latLng.push( this.utils.coordsToLatLngExpression( c ) );
    } );
    if ( params.completed && isPolyline ) {
      latLng.push( latLng[ 0 ] );
    }

    const newZone = isPolyline
      ? new L.Polyline( latLng, params.options.polylineOptions )
      : new L.Polygon( latLng, params.options.polylineOptions );

    const functionCallback = ( popin ) => {
      params.callback( { id: params.id, zone: newZone, popin } );
    };

    if ( params.options.popinOptions ) {
      const newPopin = this.addPopin(
        params.options.popinOptions,
        params.id,
        newZone,
        functionCallback
      ); // , addClickActionToZone );
      newZone.removeEventListener( 'click' );
      newZone.on( 'click', () => functionCallback( newPopin ) );
    }
    // else {
    //   addClickActionToZone( null );
    // }

    return newZone;
  }

  /**
   * A very smart creation to add a large invisible polygon responding to the callback of a much smaller polygon
   * @param params
   * @param isPolyline
   */
  createExtendedInvisible(
    params: {
      id: string;
      coords: ICoords[];
      options: PolylineLeafOption;
      callback: Function;
      completed?: boolean;
    },
    isPolyline: boolean = true
  ): L.Polyline | L.Polygon {
    const copiedParams = this.utils.deepCopy( params );

    const newParams = {
      id: copiedParams.id,
      coords: copiedParams.coords,
      options: {
        popinOptions: copiedParams.options.popinOptions,
        polylineOptions: {
          color: '#777777',
          opacity: 0,
          dashArray: null,
          weight: 20,
          fill: false,
          stroke: true,
        },
      },
      callback: params.callback,
      completed: true,
    };

    return this.createPolylineOrPolygon( newParams, true );
  }

  /**
   * Simple polygon with a lighter colored border
   * @param params
   * @param lenghtBorder
   */
  createPolygonBordered(
    params: {
      id: string;
      coords: ICoords[];
      options: PolylineLeafOption;
      callback: Function;
      completed?: boolean;
    },
    lenghtBorder?: number
  ): ( L.Polyline | L.Polygon )[] {
    const innerCoords: ICoords[] = this.utils.scaleEqualPolygon(
      params.coords,
      lenghtBorder
    ); // this.utils.scalePolygon( params.coords );//

    const polygons: ( L.Polyline | L.Polygon )[] = [];

    const newParams = this.utils.deepCopy( params );
    const commonPopinOptions = newParams.options.popinOptions;
    newParams.options.popinOptions = null;

    newParams.completed = true;
    const newLine = this.createPolylineOrPolygon( newParams );
    const functionCallback = ( popin ) => {
      params.callback( { id: newParams.id, zone: newLine, popin } );
    };
    const commonPopin = this.addPopin(
      commonPopinOptions,
      newParams.id,
      newLine,
      functionCallback
    );

    newLine.removeEventListener( 'click' );
    newLine.on( 'click', () => functionCallback( commonPopin ) );
    polygons.push( newLine );

    // Create 4 points polygon for each side
    params.coords.forEach( ( c: ICoords, index: number ) => {
      const indexToNext = index !== params.coords.length - 1 ? index + 1 : 0;
      const cNext = this.utils.coordsToLatLngExpression(
        params.coords[ indexToNext ]
      );
      const CCoords = this.utils.coordsToLatLngExpression( innerCoords[ index ] );
      const DCoords = this.utils.coordsToLatLngExpression(
        innerCoords[ indexToNext ]
      );

      const newPoly = new L.Polygon(
        [ this.utils.coordsToLatLngExpression( c ), cNext, DCoords, CCoords ],
        {
          color: params.options.polylineOptions.color,
          fill: true,
          stroke: false,
        }
      );
      const newPopin = this.addPopin(
        commonPopinOptions,
        newParams.id,
        newPoly,
        functionCallback
      );
      newPoly.removeEventListener( 'click' );
      newPoly.on( 'click', () => functionCallback( newPopin ) );
      polygons.push( newPoly );
    } );

    return polygons;
  }

  /**
   * Create a polygon with dash at regular distance
   * @param params
   */
  createPolygonPerpendicularDash( params: {
    id: string;
    coords: ICoords[];
    options: PolylineLeafOption;
    callback: Function;
    completed?: boolean;
    alternate?: boolean;
    alreadyDrawn?: Array<turf.Feature<turf.LineString>>;
  } ): ( L.Polygon | L.Polyline )[] {
    const distanceBetweenDash = 4;
    const lengthDash = 2;
    let orientation = 1; // Used to alternate direction of dashes

    const functionCallback = ( popin ) => {
      params.callback( { id: newParams.id, zone: null, popin } );
    };

    const addPopinToLast = ( newPoly ) => {
      const newPopin = this.addPopin(
        params.options.popinOptions,
        params.id,
        newPoly,
        functionCallback
      );
      newPoly.removeEventListener( 'click' );
      newPoly.on( 'click', () => functionCallback( newPopin ) );
    };

    const newZones: ( L.Polygon<any> | L.Polyline<any> )[] = [];
    const dashOptions = this.utils.deepCopy( params.options.polylineOptions );
    dashOptions.fill = true;
    dashOptions.fillColor = dashOptions.color;
    dashOptions.fillOpacity = 1;
    dashOptions.weight = params.options.polylineOptions.weight * 0.75;

    let nextMovingLength = distanceBetweenDash;
    params.coords.forEach( ( c: ICoords, index: number ) => {
      const A = c;
      const B =
        params.coords[ index === params.coords.length - 1 ? 0 : index + 1 ];
      const turfB = this.utils.coordsToTurf( B );
      const distanceAB = this.utils.distancePoints( A, B );
      let movingLenght = nextMovingLength;
      let oldMovingPoint = A;

      if ( params.alreadyDrawn ) {
        let passThiOne = false;
        const tempLine = turf.lineString( [ this.utils.coordsToTurf( A ), turfB ] );
        params.alreadyDrawn.forEach( ( line: turf.Feature<turf.LineString> ) => {
          const intersectLines = turf.lineOverlap( tempLine, line );
          if ( intersectLines.features && intersectLines.features.length ) {
            passThiOne = true;
          }
        } );
        if ( !passThiOne ) {
          params.alreadyDrawn.push( tempLine );
        } else {
          return;
        }
      }

      while ( movingLenght < distanceAB ) {
        // Segment from each movingPoint to another
        const movingPoint = this.utils.pointOnSegmentAB( A, B, movingLenght );
        const turfMovingPoint = this.utils.coordsToTurf( movingPoint );
        const miniJunction = new L.Polyline(
          [
            this.utils.coordsToLatLngExpression( oldMovingPoint ),
            this.utils.coordsToLatLngExpression( movingPoint ),
          ],
          dashOptions
        );
        newZones.push( miniJunction );
        oldMovingPoint = movingPoint;
        addPopinToLast( miniJunction );

        // Segment perpendicular to each movingPoint
        const bearingOriginal = turf.rhumbBearing( turfMovingPoint, turfB );
        const bearingNew = bearingOriginal + 90 * orientation;
        const newPointPerpendiculaire = turf.rhumbDestination(
          turfMovingPoint,
          lengthDash,
          bearingNew
        );
        const perpen = new L.Polyline(
          [
            this.utils.turfToLatLng( turfMovingPoint ),
            this.utils.turfToLatLng(
              newPointPerpendiculaire.geometry.coordinates
            ),
          ],
          dashOptions
        );
        newZones.push( perpen );
        addPopinToLast( perpen );

        if ( params.alternate ) { orientation *= -1; }
        movingLenght += distanceBetweenDash;
      }
      // Finish the junction;
      const junction = new L.Polyline(
        [
          this.utils.coordsToLatLngExpression( oldMovingPoint ),
          this.utils.coordsToLatLngExpression( B ),
        ],
        dashOptions
      );
      newZones.push( junction );
      addPopinToLast( junction );

      nextMovingLength = movingLenght - distanceAB;
    } );
    // Add the popin, hack to remove the stroke in order to not get a new polygon
    const newParams = this.utils.deepCopy( params );
    newParams.options.polylineOptions.stroke = null;
    newZones.push( this.createPolylineOrPolygon( newParams, false ) );

    return newZones;
  }

  /**
   * Create a polygon with a SVG as a border, used for hatchs and streaks models
   * @param params
   */
  createPolygonSVGBorder( params: {
    id: string;
    coords: ICoords[];
    options: PolylineLeafOption;
    callback: Function;
    completed?: boolean;
  } ): ( L.Polyline | L.Polygon )[] {
    const innerCoords: ICoords[] = this.utils.scaleEqualPolygon( params.coords );

    const polygons: ( L.Polyline<any> | L.Polygon<any> )[] = [];

    const crossedParams: {
      id: string;
      coords: ICoords[];
      options: PolylineLeafOption;
      callback: Function;
      completed?: boolean;
    } = this.utils.deepCopy( params );

    const commonPopinOptions = crossedParams.options.popinOptions;
    crossedParams.options.popinOptions = null;
    const tempFill = crossedParams.options.polylineOptions.fill;
    crossedParams.options.polylineOptions.fill = false;
    const newLine = this.createPolylineOrPolygon( crossedParams, false );
    const functionCallback = ( popin ) => {
      params.callback( { id: crossedParams.id, zone: newLine, popin } );
    };
    const commonPopin = this.addPopin(
      commonPopinOptions,
      crossedParams.id,
      newLine,
      functionCallback
    );
    newLine.removeEventListener( 'click' );
    newLine.on( 'click', () => functionCallback( commonPopin ) );
    polygons.push( newLine );

    crossedParams.options.polylineOptions.color = null;
    crossedParams.options.polylineOptions.fillColor = null;
    crossedParams.options.polylineOptions.stroke = null;
    crossedParams.options.polylineOptions.fill = tempFill;

    params.coords.forEach( ( c: ICoords, i: number ) => {
      const A = this.utils.coordsToLatLngExpression( c );
      const B =
        i === params.coords.length - 1
          ? this.utils.coordsToLatLngExpression( params.coords[ 0 ] )
          : this.utils.coordsToLatLngExpression( params.coords[ i + 1 ] );
      const C = this.utils.coordsToLatLngExpression( innerCoords[ i ] );
      const D =
        i === params.coords.length - 1
          ? this.utils.coordsToLatLngExpression( innerCoords[ 0 ] )
          : this.utils.coordsToLatLngExpression( innerCoords[ i + 1 ] );

      const newPoly = new L.Polygon(
        [ A, B, D, C, A ],
        crossedParams.options.polylineOptions
      );
      const newPopin = this.addPopin(
        commonPopinOptions,
        crossedParams.id,
        newPoly,
        functionCallback
      );
      newPoly.removeEventListener( 'click' );
      newPoly.on( 'click', () => functionCallback( newPopin ) );
      polygons.push( newPoly );
    } );

    return polygons;
  }
}
