import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'noMoreNine'
} )
export class NoMoreNinePipe implements PipeTransform {

  /**
   * Display 9+ when over 9
   *
   * @param {*} value
   * @param {...any[]} args
   * @returns {*}
   * @memberof NoMoreNinePipe
   */
  transform( value: number, ...args: any[] ): string | number {
    if ( value && value > 9 ) {
      return '9+';
    } else { return value; }
  }

}
