import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertManagerService } from '../alert-manager/alert-manager.service';
import { StorageService } from '../storage/storage.service';

@Injectable( {
    providedIn: 'root'
} )
export class DataSetCheckerService {

    private static CHECK_INTERVAL: number = environment.dataset_check_interval;

    private _interval: any;

    constructor(
        private storage: StorageService,
        private alerts: AlertManagerService
    ) {
    }

    public start(): void {
        console.warn( 'dataset-checker::start' );
        this.stop();
        this._interval = setInterval( () => {
            this.checkDataSet();
        }, DataSetCheckerService.CHECK_INTERVAL * 1000 );

    }

    public stop(): void {
        if ( this._interval ) {
            console.warn( 'dataset-checker::stop' );
            clearInterval( this._interval );
            this._interval = undefined;
        }
    }

    public checkDataSet(): void {
        console.warn( 'dataset-checker::onCheck' );
        this.checkLastImport().then( () => {
            console.log( 'checkLastImport:finished' );
            if ( this.isOutOfDate ) {
                this.stop();
                this.alerts.basicAlert( {
                    header: 'ALERTS.DATASET_OUTDATED.TITLE',
                    message: 'ALERTS.DATASET_OUTDATED.MESSAGE',
                    backdropDismiss: false,
                } ).then( () => {
                    window.location.href = '/';
                } );
            }
        } );
    }

    private checkLastImport(): Promise<void> {
        // console.log('checkLast');
        return this.storage.recoverConfigs().toPromise();
    }

    public get isOutOfDate(): boolean {
        // console.log('isOutOfDate ? ' + (this.storage.latestServerDates.importDate + '--' + this.storage.savedImportDate));
        return this.storage.latestServerDates.importDate !== this.storage.savedImportDate;
    }
}
