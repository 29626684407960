import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly file: File) {}

  get dataDirectory() {
    return this.file.dataDirectory;
  }

  get cacheDirectory() {
    return this.file.cacheDirectory;
  }
}
