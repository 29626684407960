import { Component } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { WebServices } from 'src/app/services/webservices/web-services.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { TranslateService } from '@ngx-translate/core';
import { PdfManagerService } from 'src/app/services/pdf-manager/pdf-manager.service';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { IPointObs } from '../../../models/interfaces/points';
import { UtilsService } from '../../services/utils/utils.service';

/*
  raw data of a point
  @id: "/api/obstacles/52624"
  @type: "Obstacle"
  altitude: "318"
  codeLight: false
  geoLat:  " 482936.00N"
  geoLong: "0015526.00W"
  height: "167"
  isGroup: false
  name: "22033"
  reference: "U"
  txtRmq: null
  type: "Pylône"
  typeLight: null
  uomDistVer: "FT"

  uom = unité utilisée

type
name
altitude
heights
codeLight
typeLight
txtRmq
isGroup si yes

Au tap sur un obstacle on affichera son type, sa description, son altitude et sa hauteur en précisant son type de balisage, ainsi que les informations complémentaires <txtDescrLgt> et enfin regroupement si <codegroup> est Yes sinon pas de mention.
*/
@Component( {
  selector: 'obstacles-details-popin',
  templateUrl: './obstacles-details-popin.component.html',
  styleUrls: [ './obstacles-details-popin.component.scss' ],
} )
export class ObsDetailsPopinComponent {
  point: IPointObs;

  constructor(
    protected pdfManager: PdfManagerService,
    protected loader: LoadingController,
    protected modalCtrl: ModalController,
    protected network: NetworkService,
    protected translate: TranslateService,
    protected webServices: WebServices,
    protected myMaps: MyMapsService,
    protected alerts: AlertManagerService
  ) {
  }

  ngOnInit() {
  }

  moreInfos(): void {
    this.modalCtrl.dismiss();
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  formatCoord( coord: any, type: string ) {
    return UtilsService.formatCoord( coord, type );
  }
}
