import { IGN_WEB_DEV } from './app_ign_keys';
import { AppPlatform } from './app_platform';
import { WEB_LIMIT_VERSION } from './app_version';

export const environment = {
    production: false,
    isAZBA: true,
    isWeb: true,
    isDebug: false,
    bypass_dataset: false,
    check_dataset: false,
    dataset_check_interval: 3600, // seconds
    notifications_interval: 9999, // seconds
    env: 'BAC A SABLE 1 (DGAC)',
    bundleId: 'com.dgac.sofia-vac',
    platform: AppPlatform.web,
    version: WEB_LIMIT_VERSION,
    defaultLanguage: 'fr',
    baseUrl: 'https://bac-a-sable-sofia-vac.sia-france.fr/api/',
    mockUrl: 'assets/mocks/',
    mockExtension: '.json',
    isMock: false,
    ign_api_key: IGN_WEB_DEV.key,
    ign_api_map_type: IGN_WEB_DEV.mapType,
    ign_api_user_agent: IGN_WEB_DEV.userAgent,
    itemsPerPage: 800,
    share_secret: 'Th1sIsMyS&cr3t+fdg3213fg213gfh32f1g3gf21h3gf21hds56f4d5s64f',
    urlTiles: '',
    tilesZones: [
        {
            label: 'TILES.ZONE.FRANCE',
            active: true,
            data: 'france.mbtiles',
            size: 180,
        },
        {
            label: 'TILES.ZONE.GUADELOUPE',
            active: false,
            data: 'guadeloupe.mbtiles',
            size: 0,
        },
        {
            label: 'TILES.ZONE.GUYANE',
            active: false,
            data: 'guyane.mbtiles',
            size: 0,
        },
        {
            label: 'TILES.ZONE.REUNION',
            active: false,
            data: 'reunion.mbtiles',
            size: 0,
        },
        {
            label: 'TILES.ZONE.MARTINIQUE',
            active: false,
            data: 'martinique.mbtiles',
            size: 0,
        },
        {
            label: 'TILES.ZONE.MAYOTTE',
            active: false,
            data: 'mayotte.mbtiles',
            size: 0,
        },
        {
            label: 'TILES.ZONE.MIQUELON',
            active: false,
            data: 'miquelon.mbtiles',
            size: 0,
        },
    ],
    azbaApiVersion: 'v2/',
    sofiaApiVersion: 'v1/',
};
