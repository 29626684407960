import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MAP_TYPES } from 'src/models/enums';
import { IFilters } from 'src/models/interfaces/vac';
import { AppearMenuComponent } from '../appear-menu.component';
import { TranslateService } from '@ngx-translate/core';
import { IMaps } from 'src/models/interfaces/map';

@Component( {
  selector: 'maps-menu',
  templateUrl: './maps-menu.component.html',
  styleUrls: [ './maps-menu.component.scss' ],
} )
export class MapsMenuComponent extends AppearMenuComponent implements OnInit {
  @Input() maps: IMaps;
  @Input() selected: MAP_TYPES = MAP_TYPES.CLASSIC;

  constructor( private translate: TranslateService ) {
    super();
    this.filtersUpdated = new EventEmitter<IFilters>();
  }

  ngOnInit() {
    console.log( 'maps:', this.maps );
  }

  /**
   * Reset filters and close menu
   */
  closeFilters(): void {
    super.closeFilters();
  }

  /**
   * Send new filters to the parent component
   */
  toggleMap( type?: MAP_TYPES ): void {
    this.filtersUpdated.emit( type );
  }
}
