import { IInformations } from '../interfaces/informations';
import { IRunway } from '../interfaces/runway';
import { IVac } from '../interfaces/vac';
import { GROUND_TYPES } from '../enums';

export class Vac {
    private configP: IVac;
    private longestAirstripP: IRunway;
    private currentLang: string;

    constructor( config: IVac, lang: string ) {
        this.configP = config;
        this.currentLang = lang;
        this.calculateLongestAirstrip();
    }

    get config(): IVac {
        return this.configP;
    }

    get longestAirstrip(): IRunway {
        return this.longestAirstripP;
    }

    get infosByLng(): IInformations {
        return this.configP.informations.find( el => el.language === this.currentLang );
    }

    get isMapUpdated(): boolean {
        let map = this.config.map;
        if ( !map ) return false;
        let dllMap = map.downloadedMap;
        if ( !dllMap ) return false;

        if ( map.version !== dllMap.version ) {
            return false
        } else {
            return true;
        }
    }

    private calculateLongestAirstrip(): void {
        if ( this.configP.runways && this.configP.runways.length ) {
            this.longestAirstripP = this.configP.runways.reduce(
                ( longest: IRunway, current: IRunway ) => (
                    current.length > longest.length || ( current.length === longest.length && current.type === GROUND_TYPES.HARD )
                        ? current
                        : longest
                ),
                this.configP.runways[ 0 ]
            );
        }
    }
}
