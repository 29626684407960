import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFavorite } from 'src/models/interfaces/favorite';
import { IDownloadedMap } from 'src/models/interfaces/map';
import { IVac, key } from 'src/models/interfaces/vac';

@Injectable( {
    providedIn: 'root'
} )
export class CacheService {

    constructor() {
        this._downloaded_maps$ = new BehaviorSubject( null );
    }

    /**
     * Listen to downloaded maps to get data in real time
     */
    public get downloaded_maps$(): Observable<void> {
        return this._downloaded_maps$;
    }

    private _cached_vacs: Map<key, IVac> = new Map<key, IVac>();
    private _cached_downloaded_maps: Map<key, IDownloadedMap> = new Map<key, IDownloadedMap>();

    // indexed when downloaded map cache is changing
    private _indexed_vacs_with_downloaded_map: Map<key, IVac> = new Map<key, IVac>();

    // computed when index is updated
    private _computed_vacs_with_downloaded_map: IVac[] = [];

    private _downloaded_maps$: BehaviorSubject<void>;

    // --- Favorites -------------------------------------------------------------------------------

    private _cached_favorite_maps: Map<key, boolean> = new Map<key, boolean>();

    public updateVacs( vacs: IVac[] ) {
        this._cached_vacs.clear();
        if ( vacs ) { vacs.forEach( ( vac ) => {
            this.updateVac( vac );
        } );
        }
    }

    public updateVac( vac: IVac ) {
        this._cached_vacs.set( vac.key, vac );
    }

    public getVac( key: key ): IVac {
        return this._cached_vacs.get( key );
    }

    public getVacs(): IVac[] {
        return [ ...this._cached_vacs.values() ];
    }

    /**
     * set favorite map to cache (values should come from db service)
     * @param favoriteMaps
     */
    public updateFavoriteMaps( favoriteMaps: IFavorite[] ) {
        this._cached_favorite_maps.clear();

        if ( favoriteMaps ) {
            favoriteMaps.forEach( ( favoriteMap ) => {
                this._cached_favorite_maps.set( favoriteMap.key, true );
            } );
        }
    }

    /**
     * get Favorite state directly from cache (without requesting db)
     * @param key
     */
    public isFavoriteMap( key: key ): boolean {
        return this._cached_favorite_maps.get( key );
    }

    /**
     * update favorite map
     * @param key
     * @param state
     */
    public setFavoriteMap( key: key, state: boolean ) {
        this._cached_favorite_maps.set( key, state );
    }

    // --- Downloaded Maps -------------------------------------------------------------------------

    private computeVacsWithDownloadedVacs(): void {
        this._computed_vacs_with_downloaded_map = [ ...this._indexed_vacs_with_downloaded_map.values() ];
        this._computed_vacs_with_downloaded_map.forEach( ( vac ) => {
            if ( vac.map ) { vac.map.downloadedMap = this._cached_downloaded_maps.get( vac.key ); }
        } );
    }

    public updateDownloadedMaps( downloadedMaps: IDownloadedMap[] ) {
        this._cached_downloaded_maps.clear();
        this._indexed_vacs_with_downloaded_map.clear();
        if ( downloadedMaps ) {
            downloadedMaps.forEach( ( downloadedMap ) => {
                this.updateDownloadedMap( downloadedMap, true );
            } );
        }
        this.computeVacsWithDownloadedVacs();
        this.dispatchDownloadedMap();
        this.cleanOrphans();
    }

    public updateDownloadedMap( downloadedMap: IDownloadedMap, isBulk: boolean = false ) {
        // console.log( 'updateDownloadedMap ' + downloadedMap.fileName + ' -:: ' + downloadedMap.filePath + ' -:: ' + downloadedMap.folderPath );
        this._cached_downloaded_maps.set( downloadedMap.key, downloadedMap );
        const vac: IVac = this.getVac( downloadedMap.key );
        if ( vac ) {
            this._indexed_vacs_with_downloaded_map.set( downloadedMap.key, vac );
        } else { console.error( '[FIXME] orphelin downloaded map !!', vac ); }
        if ( !isBulk ) {
            this.computeVacsWithDownloadedVacs();
            this.dispatchDownloadedMap();
        }
    }

    public getDownloadedMap( key: key ): IDownloadedMap {
        return this._cached_downloaded_maps.get( key );
    }

    /**
     * Return only Vacs which maps are downloaded
     */
    public getVacsWithDownloadedMap(): IVac[] {
        return this._computed_vacs_with_downloaded_map;
    }

    /**
     * Return downloaded maps at the moment
     */
    public getDownloadedMaps(): IDownloadedMap[] {
        return [ ...this._cached_downloaded_maps.values() ];
    }

    public deleteMap( key: key ): void {
        this._cached_downloaded_maps.delete( key );
        this._indexed_vacs_with_downloaded_map.delete( key );
        this.computeVacsWithDownloadedVacs();
        this.dispatchDownloadedMap();
    }

    public cleanOrphans(): void {
        this._cached_downloaded_maps.forEach( ( dl ) => {
            const vac: IVac = this._cached_vacs.get( dl.key );
            if ( !vac || !vac.map ) {
                console.warn( '[FIXED] cleaned orphan ', dl.key, 'because ' + ( !vac ? ' vac deleted !' : 'no map anymore !' ) );
                this.deleteMap( dl.key );
            }
        } );
    }

    private dispatchDownloadedMap(): void {
        this._downloaded_maps$.next();
    }

}
