<div class="profil-container">
  <ion-grid>
    <!--
    <ion-row>
      <div class="title">
        {{ 'PROFIL.ACCOUNT' | translate | uppercase }}
      </div>
      <div class="content account">
        <div class="ion-text-center">
          <ion-button fill="clear" disabled="true">
            {{ 'PROFIL.LOGIN' | translate | uppercase }}
          </ion-button>
        </div>
        <div class="ion-text-center">
          {{ 'GENERAL.NEW_FUNCTIONNALITY' | translate }}
        </div>
      </div>
    </ion-row>
  -->

    <ion-row>
      <div class="title">
        {{ 'PROFIL.LANGUAGE' | translate | uppercase }}
      </div>
      <div class="content languages">
        <div class="mid" (click)="setLang('fr')">
          <div class="ion-text-center">
            <img src="./assets/imgs/french-flag.png" />
            <ion-checkbox class="ion-checkbox-inline" color="primary" [checked]="currentLang === 'fr'" disabled>
            </ion-checkbox>
          </div>
        </div>
        <div class="mid" (click)="setLang('en')">
          <div class="ion-text-center">
            <img src="./assets/imgs/english-flag.png" />
            <ion-checkbox class="ion-checkbox-inline" color="primary" [checked]="currentLang === 'en'" disabled>
            </ion-checkbox>
          </div>
        </div>
      </div>
    </ion-row>

    <ion-row>
      <div class="title">
        {{ 'PROFIL.DOWNLOAD_TILES' | translate | uppercase }}
      </div>
      <div class="content languages zones-carto">
        <div class="mid">
          <ion-item lines="none" *ngFor="let zone of leftZones" (click)="setTilesDll(zone)">
            <ion-label>{{ zone.label | translate }} ({{ zone.size }} Mo)</ion-label>
            <ion-checkbox *ngIf="!zone.isDownloading" slot="end" [checked]="zone.downloaded === true" readonly
              [disabled]="!zone.active"></ion-checkbox>
            <ion-spinner slot="end" *ngIf="zone.isDownloading"></ion-spinner>
          </ion-item>
        </div>
        <div class="mid right">
          <ion-item lines="none" *ngFor="let zone of rightZones" (click)="setTilesDll(zone)">
            <ion-label>{{ zone.label | translate }} ({{ zone.size }} Mo)</ion-label>
            <ion-checkbox *ngIf="!zone.isDownloading" slot="end" [checked]="zone.downloaded === true" readonly
              [disabled]="!zone.active"></ion-checkbox>
            <ion-spinner slot="end" *ngIf="zone.isDownloading"></ion-spinner>
          </ion-item>
        </div>
      </div>
    </ion-row>

    <ion-row>
      <div class="title">
        {{ 'PROFIL.GROUNDS_TO_DISPLAY' | translate | uppercase }}
      </div>
      <div class="content grounds">
        <div class="mid">
          <ion-grid>
            <ion-row>
              <ion-col size="3">
                <img src="./assets/imgs/plane-home.png" />
              </ion-col>
              <ion-col size="6" class="title">
                {{ 'PROFIL.AIRPORTS' | translate }}
              </ion-col>
              <ion-col size="3">
                <ion-toggle [(ngModel)]="airportsVisible" (ionChange)="toggleAirports()"></ion-toggle>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="mid">
          <ion-grid>
            <ion-row>
              <ion-col size="3">
                <img src="./assets/imgs/helicopter-home.png" />
              </ion-col>
              <ion-col size="6" class="title">
                {{ 'PROFIL.HELIPORTS' | translate }}
              </ion-col>
              <ion-col size="3">
                <ion-toggle [(ngModel)]="heliportsVisible" (ionChange)="toggleHeliports()"></ion-toggle>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-row>

    <ion-row>
      <div class="title">
        {{ 'PROFIL.UPDATE_MAPS.TITLE' | translate | uppercase }}
      </div>

      <div class="content auto-update">
        <ion-grid>
          <ion-row>
            <ion-col class="image-container" size="2">
              <ion-icon id="refIcon" name="refresh-circle">
              </ion-icon>
            </ion-col>
            <ion-col size="8">
              <span>
                {{ 'PROFIL.UPDATE_MAPS.SUB' | translate }}
              </span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="2"></ion-col>
            <ion-col size="8">
              <span class="sub">
                {{ 'PROFIL.UPDATE_MAPS.TEXT_FAV' | translate }}
              </span>
            </ion-col>
            <ion-col class="toggle-container" size="2">
              <ion-toggle [(ngModel)]="autoUpdateFav" (ionChange)="toggleAutoUpdate()" [disabled]="autoUpdateAll">
              </ion-toggle>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="2"></ion-col>
            <ion-col size="8">
              <span class="sub">
                {{ 'PROFIL.UPDATE_MAPS.TEXT_DLL' | translate }}
              </span>
            </ion-col>
            <ion-col class="toggle-container" size="2">
              <ion-toggle [(ngModel)]="autoUpdateAll" (ionChange)="toggleAutoUpdate()"></ion-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-row>
  </ion-grid>
</div>