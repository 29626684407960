import { Injectable } from '@angular/core';
import { IPoint, IPointObs } from 'src/models/interfaces/points';
import { LeafletUtilsService } from '../../leaflet-utils/leaflet-utils.service';
import { POINTS_TYPE } from 'src/models/enums';
import { MarkerLeafOption } from 'src/models/types';
import { DatabaseService } from '../../database/database.service';
import { MapService } from '../map.service';

@Injectable( {
  providedIn: 'root'
} )
export class PointsService {

  constructor(
    private leafUtils: LeafletUtilsService,
    private db: DatabaseService
  ) {
    this.db.getPoints().then( ( points: any[] ) => {
      const vfrs = [];
      const vors = [];
      const vor_dmes = [];
      points.forEach( ( p: any ) => {
        switch ( p.type ) {
          case POINTS_TYPE.VFR:
            vfrs.push( p );
            break;
          case POINTS_TYPE.VOR:
            vors.push( p );
            break;
          case POINTS_TYPE.VOR_DME:
            vor_dmes.push( p );
            break;
        }
      } );

      const cb = ( param: { id: string, point: IPoint } ) => { };
      this.populatePoints( vfrs, this.VFRs, POINTS_TYPE.VFR, cb );
      this.populatePoints( vors, this.VORs, POINTS_TYPE.VOR, cb );
      this.populatePoints( vor_dmes, this.VOR_DMEs, POINTS_TYPE.VOR_DME, cb );
    } );

    this.db.getPointsObs().then( ( points: IPointObs[] ) => {
      const obstaclesPoints = points;
      const cb = ( param: { id: string, point: IPointObs } ) => { };
      this.populatePointsObs( obstaclesPoints, this.obstacles, POINTS_TYPE.OBS, cb );
    } );
  }

  VFRs: Map<string, L.Marker> = new Map<string, L.Marker>();
  VORs: Map<string, L.Marker> = new Map<string, L.Marker>();
  VOR_DMEs: Map<string, L.Marker> = new Map<string, L.Marker>();
  obstacles: Map<string, L.Marker> = new Map<string, L.Marker>();
  mapService: MapService;

  mappingObstacleIcon: any = {
    Antenne: 'antenne.png',
    Bâtiment: 'batiment.png',
    Câble: 'cable.png',
    'Centrale thermique': 'centrale-thermique.png',
    'Château d\'eau': 'chateau-eau.png',
    Cheminée: 'cheminee.png',
    Derrick: 'derrick.png',
    Eglise: 'eglise.png',
    Eolienne: 'eolienne.png',
    Grue: 'grue.png',
    Mât: 'mat.png',
    'Phare marin': 'phare-marin.png',
    'Pile de pont': 'pile-pont.png',
    Portique: 'portique.png',
    Pylône: 'pylone.png',
    Silo: 'silo.png',
    Terril: 'terril.png',
    Torchère: 'torchere.png',
    Tour: 'tour.png',
    'Treillis métallique': 'treillis-metallique.png'
    // icônes en trop, pas dans la BDD :
    // '': 'centrale-nucleaire.png',
    // '': 'cheminee-usines.png',
    // '': 'clocher.png',
    // '': 'group-eolienne.png',
    // '': 'phare.png',
    // pont-suspendu.png
    // reservoir.png
    // tour-metal-derrick.png
  };

  /**
   * Create the markers and register it for later use
   *
   * @param {IPoint[]} pointsIn
   * @param {Map<string, L.Marker>} pointsOut
   * @param {POINTS_TYPE} pointType
   * @param {Function} callback
   * @memberof PointsService
   */
  populatePoints( pointsIn: IPoint[], pointsOut: Map<string, L.Marker>, pointType: POINTS_TYPE, callback: Function ): void {
    const options: MarkerLeafOption = {
      markerOptions: { icon: null, opacity: 1 },
      popinOptions: {
        content: '',
        closeButton: false,
        closeOnClick: false,
        autoClose: false,
        className: '',
        autoPan: false,
      }
    };

    switch ( pointType ) {
      case POINTS_TYPE.VFR:
        options.markerOptions.icon = this.leafUtils.vfrIcon;
        options.popinOptions.className = 'vfr-icon';
        break;
      case POINTS_TYPE.VOR:
        options.markerOptions.icon = this.leafUtils.vorIcon;
        options.popinOptions.className = 'vor-icon';
        break;
      case POINTS_TYPE.VOR_DME:
        options.markerOptions.icon = this.leafUtils.vorDmeIcon;
        options.popinOptions.className = 'vor-dme-icon';
        break;
    }

    pointsIn.forEach( ( p: IPoint ) => {
      const id = pointType + '-' + p.id;
      let content = '<span class="title">' + p.id + '</span>';
      if ( p.name ) { content += '<br><span class="infos">' + p.name + '</span>'; }
      else if ( p.valFreq ) { content += '<br><span class="infos">' + p.valFreq + ( p.uomFreq ? p.uomFreq : '' ) + '</span>'; }
      options.popinOptions.content = content;
      const newMarker = this.leafUtils.createMarker( {
        id,
        coords: p.coords,
        options,
        callback
      } );
      pointsOut.set( id, newMarker );
    } );
  }

  // nécessaire, on ne peut pas l'avoir dans le constructor
  setMapService( mapService ) {
    this.mapService = mapService;
  }
  // obstacle.png est l'icône du menu

  convertTypeToIcon( obstacleType: string, isLight: boolean ) {
    const trimmedString = obstacleType.replace( /\(s\)$/, '' ).replace( /s$/, '' ).replace( /É$/, 'E' );
    // console.log( 'Icone obstacle : ' + trimmedString );
    let url = this.mappingObstacleIcon[ trimmedString ];
    if ( !url ) {
      url = trimmedString;
    }

    if ( isLight ) {
      url = url.replace( '.png', '-lum.png' );
    }

    const path = './assets/icon/layers/obstacles/';
    return this.leafUtils.createIcon( path + url, [ 30, 30 ], [ 15, 15 ] );
  }

  async populatePointsObs( pointsIn: IPointObs[], pointsOut: Map<string, L.Marker>, pointType: POINTS_TYPE, callback: Function ): Promise<void> {
    const options: MarkerLeafOption = {
      markerOptions: { icon: null, opacity: 1 },
      popinOptions: null
    };

    const createCallbackModal = ( point: IPointObs ) => {
      const callbackModal: Function = ( param: { id: string } ) => {
        const { id } = param;
        this.mapService.onClickMarkerObs(
          {
            id,
            marker: this.obstacles.get( id ),
          },
          null,
          null,
          point
        );
      };
      return callbackModal;
    };

    pointsIn.forEach( ( p: IPointObs ) => {
      const id = pointType + '-' + p.id;
      // console.log( p.name + ' ' + p.typeLight + ' ' + p.codeLight + ' ' + p.isGroup );
      options.markerOptions.icon = this.convertTypeToIcon( p.type, p.codeLight );
      const newMarker = this.leafUtils.createMarker( {
        id,
        coords: p.coords,
        options,
        callback: createCallbackModal( p )
      } );
      pointsOut.set( id, newMarker );
    } );
  }
}
