<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary">
      <div class="obs-type">
        {{ 'GENERAL.OBSTACLE' | translate }}
      </div>
      <div class="obs-name">{{ point.type }}</div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="infos-container">
    <ion-list>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.TYPE' | translate
          }}</ion-label>
        <ion-label class="value">{{ point.type }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.REGROUPEMENT' | translate
          }}</ion-label>
        <ion-label class="value">{{
          point.isGroup
          ? ('OBS_DETAILS.REGROUPEMENT_OUI' | translate)
          : ('OBS_DETAILS.REGROUPEMENT_NON' | translate)
          }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.ALTITUDE' | translate
          }}</ion-label>
        <ion-label class="value">{{
          point.altitude + ' ' + (point.uomDistVer + '').toLowerCase()
          }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.HEIGHT' | translate
          }}</ion-label>
        <ion-label class="value">{{
          point.height + ' ' + (point.uomDistVer + '').toLowerCase()
          }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.NAME' | translate
          }}</ion-label>
        <ion-label class="value">{{ point.name }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.CODE_LIGHT' | translate
          }}</ion-label>
        <ion-label class="value">{{
          (point.codeLight
          ? 'OBS_DETAILS.CODE_LIGHT_YES'
          : 'OBS_DETAILS.CODE_LIGHT_NO'
          ) | translate
          }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.TYPE_LIGHT' | translate
          }}</ion-label>
        <ion-label class="value">{{ point.typeLight }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{ 'OBS_DETAILS.LAT' | translate }}</ion-label>
        <ion-label class="value">{{
          formatCoord(point.coords.latArc, "Lat")
          }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.LONG' | translate
          }}</ion-label>
        <ion-label class="value">{{
          formatCoord(point.coords.lonArc, "Lon")
          }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="title">{{
          'OBS_DETAILS.INFOS_COMPLEMENTAIRES' | translate
          }}</ion-label>
        <ion-label text-wrap class="value">{{ point.txtRmq }}</ion-label>
      </ion-item>
    </ion-list>
  </div>
</ion-content>