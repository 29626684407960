<div class="layers-menu">
  <div class="header">
    <ion-icon name="close" color="light" (click)="closeFilters()"></ion-icon>
    <div class="title">{{ 'LAYERS_MENU.TITLE' | translate }}</div>
  </div>

  <div class="container">
    <ion-button color="dark" class="hide-all" (click)="hideAll()">
      {{ 'LAYERS_MENU.HIDE_ALL' | translate }}
    </ion-button>

    <div *ngIf="hasAzba" class="item-filter">
      <ion-button class="azba-button selected" (click)="setupAZBA()">
        <div class="inside-container">
          <img src="./assets/icon/layers/AZBA.svg" />
          <ion-icon class="settings" name="settings-outline"></ion-icon>
          <div>AZBA</div>
        </div>
      </ion-button>
    </div>

    <div class="item-filter" *ngFor="let layer of layers; let i = index">
      <ion-button
        [ngClass]="{ selected: layer.status }"
        (click)="toggleLayer(i)"
      >
        <div class="inside-container">
          <img [src]="layer.imgUrl"
               [ngClass]="{ 'layer-icon-dim': layer.fixedDim }"/>
          <img *ngIf="layer.imgUrl2" [src]="layer.imgUrl2" />
          <div>{{ layer.label }}</div>
        </div>
      </ion-button>
    </div>
  </div>
</div>
<ion-spinner *ngIf="showSpinner" class="layer-spinner"></ion-spinner>
