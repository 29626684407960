<div class="oaci" (click)="openMap()">
  {{vac.config.oaci}}
</div>

<div class="name" (click)="openMap()">
  {{(vac.config.type === VACS_TYPES.VAC ? 'GENERAL.AIRPORT' : 'GENERAL.HELIPORT') | translate}} - {{vac.config.city}}
  <ion-spinner *ngIf="isUpdating" color="primary" name="bubbles"></ion-spinner>
</div>

<div class="separator">
  <div></div>
</div>

<div class="status">
  <ng-container *ngIf="isUpdating || ( vac?.config?.map?.downloadedMap?.version === fromDllAll); else notUpdating">
    <ion-button color="primary" [disabled]="true">{{'MY_MAPS.UPDATING' | translate}}</ion-button>
  </ng-container>
  <ng-template #notUpdating>
    <ng-container *ngIf="vac.isMapUpdated; else notUpdated">
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="cloud-done" color="primary">
        </ion-icon>
      </ion-button>
    </ng-container>
    <ng-template #notUpdated>
      <ion-button fill="clear" (click)="updateMap()">
        <ion-icon slot="icon-only" name="refresh" color="danger">
        </ion-icon>
      </ion-button>
    </ng-template>
  </ng-template>
</div>

<div class="separator">
  <div></div>
</div>

<div class="favorite">
  <ion-button fill="clear" (click)="toggleFav()">
    <ion-icon slot="icon-only" name="{{isFavorite ? 'star' : 'star-outline'}}"
      color="{{isFavorite ? 'warning' : 'primary'}}">
    </ion-icon>
  </ion-button>
</div>

<div class="separator">
  <div></div>
</div>

<div class="delete">
  <ion-button fill="clear" (click)="deleteMap()">
    <ion-icon slot="icon-only" name="trash" color="primary"></ion-icon>
  </ion-button>
</div>