import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Platform } from '@ionic/angular';
import { WebServices } from '../webservices/web-services.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IEditablePage } from 'src/models/interfaces/web-services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { VACS_TYPES } from 'src/models/enums';
import { IVac, key } from 'src/models/interfaces/vac';
import { map, catchError } from 'rxjs/operators';
import { CacheService } from '../cache/cache.service';
import { DatabaseService } from '../database/database.service';
import { IFavorite } from 'src/models/interfaces/favorite';
import { ITilesZone } from 'src/models/interfaces/tiles-zone';

@Injectable( {
  providedIn: 'root',
} )
export class StorageService {
  get about(): IEditablePage {
    return this._about.value;
  }
  get listenAbout(): Observable<IEditablePage> {
    return this._about.asObservable();
  }
  get legalNotices(): IEditablePage {
    return this.legal.value;
  }
  get listenLegalNotices(): Observable<IEditablePage> {
    return this.legal.asObservable();
  }
  get legalAcceptance(): boolean {
    return this.alreadySeenLegal.value;
  }
  get listenLegalAcceptance(): Observable<boolean> {
    // console.log('accepted as observable');
    return this.alreadySeenLegal.asObservable();
  }

  public get latestServerDates() {
    return this._latestServerDates;
  }
  get savedLanguage(): string {
    return this.savedLanguageP;
  }
  get savedFilters(): { authorizedTypes?: VACS_TYPES[] } {
    return this.savedFiltersP;
  }
  get savedImportDate(): number {
    return this.savedImportDateP.value;
  }
  get savedNextUpdate(): string {
    return this.savedNextUpdateP.value;
  }
  get savedLastUpdate(): string {
    return this.savedLastUpdateP.value;
  }
  get listDllMaps(): Observable<Map<key, IVac>> {
    return this.listDllMapBSP.asObservable();
  }
  get autoUpdate(): { fav: boolean; all: boolean } {
    return this.autoUpdateP;
  }
  set autoUpdate( b: { fav: boolean; all: boolean } ) {
    this.autoUpdateP = b;
    this.saveAutoUpdate();
  }

  constructor(
    private platform: Platform,
    private nativeStorage: NativeStorage,
    private webServices: WebServices,
    private translate: TranslateService,
    private db: DatabaseService,
    private cache: CacheService
  ) {
    this.platform.ready().then( () => {
      if ( this.platform.is( 'cordova' ) ) {
        this.recoverAbout();
        this.recoverLegalNotices();
        this.recoverLegalAcceptance();
        this.recoverLanguage();
        this.recoverImportDate();
        this.recoverNextUpdate();
        this.recoverLastUpdate();
        this.recoverAutoUpdate();
      }
    } );
  }
  static ABOUT = 'about';
  static LEGAL_NOTICES = 'legal';
  static ALREADY_SEEN_LEGAL = 'alreadySeenLegal';
  static SAVED_LANGUAGE = 'savedLanguage';
  static SAVED_FILTERS = 'savedFilters';
  static SAVED_IMPORT_DATE = 'savedImportDate';
  static SAVED_NEXT_UPDATE = 'savedNextUpdate';
  static SAVED_LAST_UPDATE = 'savedLastUpdate';
  static LIST_OF_DLL_MAPS = 'listDLLMaps';
  static AUTO_UPDATE = 'autoUpdate';
  static MAP_DL_VERSION = 'mapDlVersion';
  static ASK_FOR_DL_TILES = 'askForDlTiles';
  static TILES_ZONE = 'tilesZone';

  private _about = new BehaviorSubject<IEditablePage>( null );

  private legal = new BehaviorSubject<IEditablePage>( null );

  private alreadySeenLegal = new BehaviorSubject<boolean>( null );

  private configRecoveredP: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>( false );

  private _latestServerDates: {
    lastUpdate: Date;
    nextUpdate: Date;
    importDate: number;
  } = { lastUpdate: null, nextUpdate: null, importDate: null };

  private savedLanguageP: string;

  private savedFiltersP: { authorizedTypes?: VACS_TYPES[] };

  private savedImportDateP: BehaviorSubject<number> =
    new BehaviorSubject<number>( -1 );

  private savedNextUpdateP: BehaviorSubject<string> =
    new BehaviorSubject<string>( '' );

  private savedLastUpdateP: BehaviorSubject<string> =
    new BehaviorSubject<string>( '' );

  private listDllMapsP: Map<key, IVac>;
  private listDllMapBSP: BehaviorSubject<Map<key, IVac>> = new BehaviorSubject<
    Map<key, IVac>
  >( new Map<key, IVac>() );

  private autoUpdateP: { fav: boolean; all: boolean };
  configRecovered(): Observable<boolean> {
    return this.configRecoveredP.asObservable();
  }
  listenSavedImportDate(): Observable<number> {
    return this.savedImportDateP.asObservable();
  }
  listenSavedNextUpdate(): Observable<string> {
    return this.savedNextUpdateP.asObservable();
  }
  listenSavedLastUpdate(): Observable<string> {
    return this.savedLastUpdateP.asObservable();
  }
  private setListDllMap( f: Map<key, IVac> ): void {
    this.listDllMapsP = f;
    this.listDllMapBSP.next( this.listDllMapsP );
  }

  recoverConfigs(): Observable<void> {
    // console.log('getDistantConfig');
    return this.webServices.getConfigs().pipe(
      map(
        ( dates: { lastUpdate: Date; nextUpdate: Date; importDate: number } ) => {
          // console.log(JSON.stringify(dates));
          this._latestServerDates = dates;
          this.configRecoveredP.next( true );
        },
        () => {
          this._latestServerDates = {
            lastUpdate: null,
            nextUpdate: null,
            importDate: null,
          };
        }
      ),
      catchError( ( error: any, caught: Observable<void> ) => {
        this._latestServerDates = {
          lastUpdate: null,
          nextUpdate: null,
          importDate: null,
        };
        return throwError( null );
      } )
    );
  }

  // ***********//
  // Functions //
  // ***********//
  public async getTilesZone(): Promise<ITilesZone[]> {
    try {
      const zones: ITilesZone[] = await this.nativeStorage.getItem(
        StorageService.TILES_ZONE
      );
      return zones;
    } catch ( _ ) {
      return environment.tilesZones;
    }
  }

  public saveTilesZone( zones: ITilesZone[] ) {
    if ( this.platform.is( 'cordova' ) ) {
      this.nativeStorage.setItem( StorageService.TILES_ZONE, zones );
    }
  }

  public async alreadyAskForDlTiles(): Promise<boolean> {
    try {
      await this.nativeStorage.getItem( StorageService.ASK_FOR_DL_TILES );
      return true;
    } catch ( _ ) {
      return false;
    }
  }

  public setAskForDlTiles() {
    if ( this.platform.is( 'cordova' ) ) {
      this.nativeStorage.setItem( StorageService.ASK_FOR_DL_TILES, true );
    }
  }

  // Legal Notices
  private recoverLegalNotices(): void {
    // console.warn( 'recoverLegalNotices' );
    if ( this.platform.is( 'cordova' ) ) {
      this.nativeStorage.getItem( StorageService.LEGAL_NOTICES ).then(
        ( x: any ) => {
          this.legal.next( x );
        },
        ( e ) => this.legal.next( null )
      );
    }
  }
  public saveLegalNotices( legal: IEditablePage ) {
    this.legal.next( legal );
    if ( this.platform.is( 'cordova' ) ) {
      // console.warn( 'saveLegalNotices' );
      this.nativeStorage.setItem( StorageService.LEGAL_NOTICES, legal );
    }
  }

  // About Notices
  private recoverAbout(): void {
    // console.warn( 'recoverAbout' );
    this.nativeStorage.getItem( StorageService.ABOUT ).then(
      ( x: any ) => {
        this._about.next( x );
      },
      ( e ) => this._about.next( null )
    );
  }

  public saveAbout( about: IEditablePage ) {
    this._about.next( about );
    if ( this.platform.is( 'cordova' ) ) {
      // console.warn( 'saveAbout' );
      this.nativeStorage.setItem( StorageService.ABOUT, about );
    }
  }

  // Legal Acceptance
  private recoverLegalAcceptance(): void {
    // console.warn( 'recoverLegalAcceptance' );
    if ( this.platform.is( 'cordova' ) ) {
      this.nativeStorage.getItem( StorageService.ALREADY_SEEN_LEGAL ).then(
        ( x: any ) => {
          this.alreadySeenLegal.next( x );
        },
        ( e ) => this.alreadySeenLegal.next( false )
      );
    }
  }
  public saveLegalAcceptance( accepted: boolean ): Promise<any> {
    this.alreadySeenLegal.next( accepted );
    // console.warn( 'saveLegalAcceptance' );
    return this.nativeStorage.setItem(
      StorageService.ALREADY_SEEN_LEGAL,
      accepted
    );
  }

  // Language used
  private recoverLanguage(): void {
    // console.warn( 'recoverLanguage' );
    this.nativeStorage.getItem( StorageService.SAVED_LANGUAGE ).then(
      ( x: any ) => {
        this.savedLanguageP = x;
        this.translate.use( x );
      },
      ( e ) => ( this.savedLanguageP = environment.defaultLanguage )
    );
  }
  public saveLanguage( l: string ): Promise<any> {
    this.savedLanguageP = l;
    // console.warn( 'saveLanguage' );
    return this.nativeStorage.setItem( StorageService.SAVED_LANGUAGE, l );
  }

  // Filters
  public async recoverFilters(): Promise<any> {
    // console.warn( 'recoverFilters' );
    if ( this.platform.is( 'cordova' ) ) {
      let val = null;
      try {
        val = await this.nativeStorage.getItem( StorageService.SAVED_FILTERS );
      } catch ( _ ) { }
      // console.log( 'recoverFilter:', JSON.stringify( val ) );
      if ( val ) { this.savedFiltersP = val; }
      else { this.savedFiltersP = {}; }
    } else {
      // TODO Ajouter ici le filtre AZBA par défaut !
      this.savedFiltersP = {};
    }
    // console.log( 'this.savedFiltersP:', this.savedFiltersP );
    return this.savedFiltersP;
  }
  public saveFilters( f: any ): Promise<any> {
    this.savedFiltersP = f;
    // console.warn( 'saveFilters' );
    return this.nativeStorage.setItem( StorageService.SAVED_FILTERS, f );
  }

  // Import update
  private recoverImportDate(): void {
    // console.warn( 'recoverImportDate' );
    this.nativeStorage.getItem( StorageService.SAVED_IMPORT_DATE ).then(
      ( u: any ) => {
        this.savedImportDateP.next( u );
      },
      ( e ) => this.savedImportDateP.next( -1 )
    );
  }

  public saveImportDate( u: number ): Promise<any> {
    this.savedImportDateP.next( u );
    // console.warn( 'saveImportDate' );
    return this.nativeStorage.setItem( StorageService.SAVED_IMPORT_DATE, u );
  }

  // Next update
  private recoverNextUpdate(): void {
    // console.warn( 'recoverNextUpdate' );
    this.nativeStorage.getItem( StorageService.SAVED_NEXT_UPDATE ).then(
      ( u: any ) => {
        this.savedNextUpdateP.next( u );
      },
      ( e ) => this.savedNextUpdateP.next( '' )
    );
  }
  public saveNextUpdate( u: string ): Promise<any> {
    this.savedNextUpdateP.next( u );
    // console.warn( 'saveNextUpdate' );
    return this.nativeStorage.setItem( StorageService.SAVED_NEXT_UPDATE, u );
  }

  // Last update
  private recoverLastUpdate(): void {
    // console.warn( 'recoverLastUpdate' );
    this.nativeStorage.getItem( StorageService.SAVED_LAST_UPDATE ).then(
      ( u: any ) => {
        this.savedLastUpdateP.next( u );
      },
      ( e ) => this.savedLastUpdateP.next( '' )
    );
  }
  public saveLastUpdate( u: any ): Promise<any> {
    this.savedLastUpdateP.next( u );
    // console.warn( 'saveLastUpdate' );
    return this.nativeStorage.setItem( StorageService.SAVED_LAST_UPDATE, u );
  }

  // DLL Maps
  private async recoverFavorites(): Promise<IFavorite[]> {
    return this.db.getFavorites();
  }

  // DLL Maps
  public async recoverDowloadedMaps(): Promise<void> {
    await this.recoverFavorites();

    return this.db.getDownloadedMaps().then( ( downloadedMaps ) => {
      // console.warn( 'downloadedMaps from indexedDB', downloadedMaps );

      return this.nativeStorage.getItem( StorageService.LIST_OF_DLL_MAPS ).then(
        ( f: string ) => {
          if ( !f ) {
            this.setListDllMap( new Map<key, IVac>() );
          } else {
            this.setListDllMap( new Map( JSON.parse( f ) ) );
            console.warn( 'downloadedMaps from nativeStorage', JSON.parse( f ) );
          }
        },
        ( e ) => this.setListDllMap( new Map<key, IVac>() )
      );
    } );
  }

  public changeDllMap( m: Map<key, IVac> ): Promise<any> {
    this.listDllMapsP = m;
    return this.saveDllMaps();
  }
  private saveDllMaps(): Promise<any> {
    // console.warn( 'saveDllMaps' );
    return this.nativeStorage.setItem(
      StorageService.LIST_OF_DLL_MAPS,
      JSON.stringify( Array.from( this.listDllMapsP.entries() ) )
    );
  }

  // Auto update
  recoverAutoUpdate(): Promise<any> {
    // console.warn( 'recoverAutoUpdate' );
    return this.nativeStorage.getItem( StorageService.AUTO_UPDATE ).then(
      ( b: { fav: boolean; all: boolean } ) => {
        this.autoUpdateP = b;
      },
      ( e ) => ( this.autoUpdateP = { fav: true, all: true } )
    );
  }

  private saveAutoUpdate(): Promise<any> {
    // console.warn( 'saveAutoUpdate' );
    return this.nativeStorage.setItem(
      StorageService.AUTO_UPDATE,
      this.autoUpdateP
    );
  }
}
