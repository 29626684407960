<ion-header>
  <ion-toolbar color="dark">
    <ion-title color="primary">
      <div class="flex">
        <div class="title">
          {{ label | async | translate }}
        </div>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="download-container">
    <div>
      {{ message | async | translate }}
    </div>
    <ion-progress-bar color="primary" [value]="progress | async">
    </ion-progress-bar>
  </div>
</ion-content>

<ion-footer *ngIf="showButton | async">
  <div class="more-infos">
    <ion-button (click)="dismiss()">
      {{ 'ALERTS.BASIC_CHOICE.OK' | translate }}
    </ion-button>
  </div>
</ion-footer>
