<div class="azba-menu">
  <div class="header">
    <ion-icon name="close" color="light" (click)="closeFilters()"></ion-icon>
    <div class="title">{{ "AZBA_MENU.TITLE" | translate }}</div>
  </div>

  <div class="container">
    <ion-button
      color="dark"
      id="returnAzba"
      class="return_btn"
      (click)="return()"
    >
      {{ "AZBA_MENU.RETURN" | translate }}
    </ion-button>

    <ion-item>
      <div class="text">{{ "AZBA_MENU.HINT" | translate }}</div>
    </ion-item>

    <ng-template [ngIf]="env.isAZBA">
      <div class="desktop-azba-date-container" (click)="openDatesPopup()">
        <div class="desktop-azba-label">
          {{ "AZBA_MENU.START_LABEL" | translate }}
        </div>
        <div class="desktop-azba-date">
          {{ current.start | date : "dd/MM/yyyy HH:mm" : "+0000" }} (UTC)
        </div>
      </div>
      <div class="desktop-azba-date-container" (click)="openDatesPopup()">
        <div class="desktop-azba-label">
          {{ "AZBA_MENU.END_LABEL" | translate }}
        </div>
        <div class="desktop-azba-date">
          {{ current.end | date : "dd/MM/yyyy HH:mm" : "+0000" }} (UTC)
        </div>
      </div>
    </ng-template>

    <!--<ng-template [ngIf]="!env.isWeb">
      <ion-item>
        <ion-label>
          <div class="text">{{ 'AZBA_MENU.START_LABEL' | translate }}</div>
          <ion-datetime
            [min]="availableRange.start"
            [max]="current.end"
            [value]="current.start"
            mode="md"
            displayFormat="DD/MM/YYYY HH:mm (UTC)"
            [monthNames]="monthNames"
            pickerFormat="D MMMM YYYY HH:mm"
            (ionChange)="onSelect($event, 'start')"
            display-timezone="utc"
            cancelText="{{ 'AZBA.CANCEL' | translate }}"
            doneText="{{ 'AZBA.DONE' | translate }}"
            [pickerOptions]="pickerOptions"
          >
          </ion-datetime>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <div class="text">{{ 'AZBA_MENU.END_LABEL' | translate }}</div>
          <ion-datetime
            [min]="current.start"
            [max]="availableRange.end"
            [value]="current.end"
            mode="md"
            displayFormat="DD/MM/YYYY HH:mm (UTC)"
            [monthNames]="monthNames"
            pickerFormat="D MMMM YYYY HH:mm"
            (ionChange)="onSelect($event, 'end')"
            display-timezone="utc"
            cancelText="{{ 'AZBA.CANCEL' | translate }}"
            doneText="{{ 'AZBA.DONE' | translate }}"
            [pickerOptions]="pickerOptions"
          >
          </ion-datetime>
        </ion-label>
      </ion-item>
    </ng-template>-->
  </div>
</div>
