import { Injectable } from '@angular/core';
import { AlertManagerService } from '../alert-manager/alert-manager.service';
import { File } from '@ionic-native/file/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { StorageService } from 'src/app/services/storage/storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkService } from '../network/network.service';
import { ITilesZone } from 'src/models/interfaces/tiles-zone';
import { TranslateService } from '@ngx-translate/core';
import { ModalManagerService } from '../modal-manager/modal-manager.service';

declare let device: any;
@Injectable( {
  providedIn: 'root',
} )
export class TilesService {
  private progressValueObs: BehaviorSubject<number> =
    new BehaviorSubject<number>( 0 );
  get progressValue(): Observable<number> {
    return this.progressValueObs.asObservable();
  }

  private zonesAvailableBS: BehaviorSubject<ITilesZone[]> = new BehaviorSubject<
    ITilesZone[]
  >( [] );
  get zonesAvailable$(): Observable<ITilesZone[]> {
    return this.zonesAvailableBS.asObservable();
  }

  get zonesAvailable(): ITilesZone[] {
    return this.zonesAvailableBS.value;
  }

  modalProgressPopup: HTMLIonModalElement;

  databaseDirectory: string;

  constructor(
    private alerts: AlertManagerService,
    private file: File,
    private transfer: FileTransfer,
    private storage: StorageService,
    public network: NetworkService,
    private translate: TranslateService,
    private modalManagerService: ModalManagerService
  ) {
    this.init();
  }

  async init() {
    const zones = await this.storage.getTilesZone();

    this.zonesAvailableBS.next( zones );
  }

  public async askForDownload( zone: ITilesZone = null ): Promise<boolean> {
    let choice = false;
    if ( zone === null ) {
      choice = await this.alerts.basicChoice( {
        header: 'TILES.INFO.TITLE',
        message: this.translate.instant( 'TILES.INFO.MAP_DL_ZONE' ),
      } );

      if ( choice ) {
        const result = await this.modalManagerService.presentChooseTilesZone( {
          zones: this.zonesAvailable,
        } );
        // console.log( 'result:', result );
        zone = result.data.zone ?? null;
        // console.log( 'zone:', zone );
      }
    } else {
      const label = this.translate.instant( zone.label );
      choice = await this.alerts.basicChoice( {
        header: 'TILES.INFO.TITLE',
        message: this.translate.instant( 'TILES.INFO.MAP_DL_QUESTION' ),
      } );
    }

    if ( choice && zone !== null ) {
      return this.downloadTiles( zone );
    } else {
      return false;
    }
  }

  /**
    Handle the tiles downloading
  */
  public async downloadTiles( zone: ITilesZone ): Promise<boolean> {
    const hasSpace = environment.isWeb || ( await this.hasEnoughMemorySpace( zone ) );

    // check if we have enough space in the memory
    if ( hasSpace ) {
      // we first create the tiles directory in dataDirectory
      const dirEntry = environment.isWeb || ( await this.createTilesDirectory() );

      if ( dirEntry ) {
        // download the file
        let result: boolean;

        if ( environment.isWeb ) {
          result = ( await new Promise( ( resolve ) =>
            setTimeout( function() {
              resolve( true );
            }, 3000 )
          ) ) as any;
        } else {
          result = await this.download( zone );
        }

        if ( result ) {
          await this.alerts.basicAlert( {
            header: 'TILES.FINISHED.TITLE',
            message: this.translate.instant( 'TILES.FINISHED.FULL_TEXT', {
              zone: this.translate.instant( zone.label ),
            } ),
          } );
        } else {
          await this.alerts.basicAlert( {
            header: 'TILES.FAILURE.TITLE',
            message: this.translate.instant( 'TILES.FAILURE.FULL_TEXT', {
              zone: this.translate.instant( zone.label ),
            } ),
          } );
        }
        return result;
      } else {
        return false;
      }
    } else {
      // Display a message error if no enough disk space
      await this.alerts.basicError(
        'TILES.ERROR.SUB_TITLE',
        'TILES.ERROR.DISK_SPACE'
      );
      return false;
    }
  }

  /**
    Check if we have enough space on the local disk. Tiles need around 200 Mo (200000000 bytes)
  */
  private hasEnoughMemorySpace( zone: ITilesZone ): Promise<boolean> {
    // 200000000 bytes is corresponding to 200 Mo: the minimum space for download tiles
    const minimumSpace =
      zone.size && zone.size > 0 ? zone.size * 1000000 : 200000000;
    return new Promise( ( resolve ) => {
      this.file
        .getFreeDiskSpace()
        .then( ( res ) => {
          if ( device.platform === 'Android' ) {
            res = res * 1000;
          }
          // console.log( 'currentSpace:', res, ' bytes' );
          if ( res >= minimumSpace ) {
            resolve( true );
          } else {
            resolve( false );
          }
        } )
        .catch( ( err ) => {
          resolve( false );
          console.log( 'error while getFreeDiskSpace' );
        } );
    } );
  }

  getDatabaseDirectory(): string {
    const dir = device.platform === 'Android' ? 'databases' : 'LocalDatabase';
    return `${ this.file.dataDirectory }../${ dir }`;
  }

  getDatabaseFullPath( data: string ): string {
    return `${ this.getDatabaseDirectory() }/${ data }`;
  }

  /**
    Create tiles directory
  */
  private async createTilesDirectory(): Promise<boolean> {
    const dir = device.platform === 'Android' ? 'databases' : 'LocalDatabase';
    return new Promise( ( resolve ) => {
      this.file
        .createDir( this.file.dataDirectory + '../', dir, true )
        .then( ( entry ) => {
          resolve( true );
        } )
        .catch( ( err ) => {
          resolve( false );
        } );
    } );
  }

  setZoneIsDownloading( zone: ITilesZone, isDownloading: boolean ) {
    const zonesAvailable = this.zonesAvailableBS.value;
    const found: ITilesZone = zonesAvailable.find(
      ( elem ) => elem.data === zone.data
    );
    if ( found ) {
      found.isDownloading = isDownloading;
      this.zonesAvailableBS.next( zonesAvailable );
    }
  }

  setZoneDownloaded( zone: ITilesZone ) {
    const zonesAvailable = this.zonesAvailableBS.value;
    const found: ITilesZone = zonesAvailable.find(
      ( elem ) => elem.data === zone.data
    );
    if ( found ) {
      found.downloaded = true;
      found.selected = true;
      this.zonesAvailableBS.next( zonesAvailable );
      this.storage.saveTilesZone( zonesAvailable );
    }
  }

  /**
    Download tiles on the server and save them in dataDirectory/tiles
  */
  private async download( zone: ITilesZone ): Promise<boolean> {
    const fileTransfer: FileTransferObject = this.transfer.create();
    this.progressValueObs.next( 0 );
    this.setZoneIsDownloading( zone, true );

    return new Promise( ( resolve ) => {
      const url = environment.urlTiles;
      // console.log( 'Start downloading' );

      // launch the download
      fileTransfer
        .download( `${ url }${ zone.data }`, this.getDatabaseFullPath( zone.data ) )
        .then(
          ( entry ) => {
            this.setZoneIsDownloading( zone, false );
            this.setZoneDownloaded( zone );
            resolve( true );
          },
          ( error ) => {
            this.progressValueObs.next( 0 );
            resolve( false );
          }
        )
        .finally( () => {
          this.setZoneIsDownloading( zone, false );
        } );

      // handle the downloading progress
      fileTransfer.onProgress( ( progressEvent ) => {
        if ( progressEvent.lengthComputable ) {
          this.progressValueObs.next(
            progressEvent.loaded / progressEvent.total
          );
        } else {
          this.progressValueObs.next(
            progressEvent.loaded / progressEvent.total
          );
        }
      } );
    } );
  }
}
