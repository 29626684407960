import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IEditablePage } from 'src/models/interfaces/web-services';
import { WebServices } from 'src/app/services/webservices/web-services.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from 'src/environments/environment';
import { PdfManagerService } from 'src/app/services/pdf-manager/pdf-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { FileEntry } from '@ionic-native/file/ngx';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { MenuController } from '@ionic/angular';

@Component( {
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: [ './settings.page.scss' ],
} )
export class SettingsPage {

  choices = [
    'SETTINGS.PROFIL',
    'SETTINGS.TUTO',
    'SETTINGS.SUPPORT',
    'SETTINGS.SIA',
    'SETTINGS.LEGALS'
  ];
  activeChoice = 'SETTINGS.PROFIL';

  pages: { key?: string, page?: IEditablePage } = {};
  version: string = environment.version;
  env: string = environment.env ? '[' + environment.env + ']' : '';
  device: string = environment.platform ? '#' + environment.platform : '';
  environment = environment;

  guideEn: string;
  guideFr: string;

  constructor( private modalCtrl: ModalController, private webServices: WebServices, private storage: StorageService,
               private iab: InAppBrowser, private pdfManager: PdfManagerService, private translate: TranslateService,
               private alerts: AlertManagerService, private menu: MenuController ) {

    this.webServices.recoverGettingStarted().subscribe(
      ( pages: FileEntry[] ) => {
        this.guideEn = pages[ 0 ].nativeURL || this.webServices.getUserGuideCacheUrl( 'en' );
        this.guideFr = pages[ 1 ].nativeURL || this.webServices.getUserGuideCacheUrl( 'fr' );
      },
      ( error: any ) => {
        this.guideEn = this.webServices.getUserGuideCacheUrl( 'en' );
        this.guideFr = this.webServices.getUserGuideCacheUrl( 'fr' );
      } );
    this.pages[ 'SETTINGS.LEGALS' ] = this.storage.legalNotices;
    this.pages[ 'SETTINGS.SIA' ] = this.storage.about;
  }

  toggleMenu() {
    // this.menu.enable( true, 'menu-settings' );
    this.menu.toggle( 'menu-settings' );
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  onFakeData(): void {
    this.storage.saveImportDate( 1 );
    alert( 'fake date patched, please restart :)' );
  }

  toggleButton( title: string ): void {
    if ( title === 'SETTINGS.SUPPORT' ) {
      const browser = this.iab.create( 'https://www.sia.aviation-civile.gouv.fr/contact#' );
      browser.show();
    } else if ( title === 'SETTINGS.TUTO' ) {
      if ( ( this.translate.currentLang === 'fr' && !this.guideFr ) || this.translate.currentLang === 'en' && !this.guideEn ) {
        this.alerts.genericErrorAlert();
      } else {
        const filePath = this.translate.currentLang === 'fr' ? this.guideFr : this.guideEn;
        this.pdfManager.openPdf( { filePath, nameToDisplay: 'Guide' } );
      }
    } else {
      this.choices.forEach( ( c ) => {
        if ( c === title ) {
          this.activeChoice = title;
        }
      } );
    }
    this.menu.close();
  }
}
