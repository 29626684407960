<ion-grid>
  <ion-row>
    <ion-col class="col-logo">
      <img class="logo" src="./assets/imgs/logo-sia-blue.png">
    </ion-col>
    <ion-col class="col-search">
      <search-vac [authorizedTypes]="authorizedTypes" [events]="eventsSubject.asObservable()"></search-vac>
    </ion-col>
    <ion-col class="col-icon">
      <div class="iconbar">
        <div class="no-margin-left" (click)="toggleGrounds()" class="row-picto">
          <ion-row class="row-picto">
            <ion-icon name="options"></ion-icon>
          </ion-row>
        </div>
        <div (click)="toggleMyMaps()">
          <ion-row class="notifications row-picto">
            <ion-icon name="map-sharp"></ion-icon>
            <ion-badge *ngIf="nbFavToUpdate > 0" color="warning">{{nbFavToUpdate | noMoreNine}}</ion-badge>
          </ion-row>
        </div>
        <div (click)="toggleSettings()" class="row-picto">
          <ion-row class="row-picto">
            <ion-icon name="settings-sharp"></ion-icon>
          </ion-row>
        </div>
        <div (click)="toggleAlerts()">
          <ion-row class="notifications row-picto">
            <ion-icon name="notifications">
            </ion-icon>
            <ion-badge *ngIf="nbAlerts > 0" color="danger">{{nbAlerts | noMoreNine}}</ion-badge>
          </ion-row>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>