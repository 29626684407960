import { environment } from 'src/environments/environment';

export const ENDPOINTS = {
  CONFIG: `${ environment.sofiaApiVersion }configs`,
  EDITABLE_PAGES: ( name: string ) =>
    `${ environment.sofiaApiVersion }editable_pages?title=${ name }`,
  GET_FILE: ( oaci: string, type: string ) =>
    `${ environment.sofiaApiVersion }custom/file-path/${ oaci }/${ type }`,
  OACIS: `${ environment.sofiaApiVersion }oacis`,
  NOTIFICATIONS: ( startDate: string, endDate: string ) =>
    `${ environment.sofiaApiVersion }notifications?startDate[before]=${ startDate }&endDate[after]=${ endDate }`,
  ZONES: ( zoneStart: string, zoneBack: string ) =>
    `${ environment.sofiaApiVersion }zones?${ zoneStart }=${ zoneBack }`,
  POINTS: ( pointStart: string, pointBack: string ) =>
    `${ environment.sofiaApiVersion }points?${ pointStart }=${ pointBack }`,
  OBS: ( pointStart: string, pointBack: string ) =>
    `${ environment.sofiaApiVersion }obstacles?${ pointStart }=${ pointBack }`,
  AZBA_RANGE: `${ environment.azbaApiVersion }custom/currentDate`,
  RTBA_NETWORK: ( version: string ) =>
    `${ environment.azbaApiVersion }r_t_b_as?date=${ version }`,
  AZBA_ACTIVE: ( version: string, startDate: string, endDate: string ) =>
    `${ environment.azbaApiVersion }r_t_b_as?itemsPerPage=200&date=${ version }&timeSlots.startTime[before]=${ endDate }&timeSlots.endTime[after]=${ startDate }`,
};

// --- Editable page ----------------------------------------------------------------

export enum EditablePageName {
  ABOUT = 'about',
  LEGAL = 'legal',
}

// --- Config -----------------------------------------------------------------------

export enum ConfigValues {
  NEXT_HE = 'he-next-date',
  ACTUAL_HE = 'he-current-date',
  NEXT_AD = 'ad-next-date',
  ACTUAL_AD = 'ad-current-date',
  IMPORT_DATE = 'import-date',
}

// --- Pagination -------------------------------------------------------------------

export interface IPageParams {
  page: number;
  itemsPerPage: number;
}

export class PageParams implements IPageParams {
  page: number;
  itemsPerPage: number;

  constructor( page: number, itemsPerPage: number ) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
  }
}
