import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { TokenInterceptorService } from './services/webservices/token-interceptor/token-interceptor.service';
import { UserAgent } from '@ionic-native/user-agent/ngx';
import { SettingsPage } from './pages/settings/settings.page';
import { SettingsPageModule } from './pages/settings/settings.module';
import { Network } from '@ionic-native/network/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InformationsPage } from './pages/informations/informations.page';
import { InformationsPageModule } from './pages/informations/informations.module';
import { DataSetCheckerService } from './services/dataset-checker/dataset-checker.service';
import { AzbaManagerService } from './services/azba/azba-manager.service';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';

import { SQLite } from '@ionic-native/sqlite/ngx';

export function HttpLoaderFactory( http: HttpClient ) {
  return new TranslateHttpLoader( http, './assets/i18n/', '.json' );
}

import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { SqliteService } from './services/sqlite/sqlite.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

// the second parameter 'fr' is optional
registerLocaleData( localeFr, 'fr' );

@NgModule( {
  declarations: [ AppComponent ],
  entryComponents: [ SettingsPage, InformationsPage ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,

        deps: [ HttpClient ],
      },
    } ),
    PipesModule,
    ComponentsModule,
    SettingsPageModule,
    InformationsPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DataSetCheckerService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    NativeStorage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    UserAgent,
    Network,
    HTTP,
    File,
    FileTransfer,
    FileTransferObject,
    DocumentViewer,
    Globalization,
    InAppBrowser,
    AzbaManagerService,
    SQLite,
    SqliteService,
    DatePipe,
    BackgroundMode,
  ],
  bootstrap: [ AppComponent ],
} )
export class AppModule { }
