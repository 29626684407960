import { map } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, AfterViewInit, AfterViewChecked, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { GROUND_TYPES } from 'src/models/enums';
import { IFilters } from 'src/models/interfaces/vac';
import { AppearMenuComponent } from '../appear-menu.component';
import { TranslateService } from '@ngx-translate/core';

interface SimpleSliderModel {
  value: number;
  options: Options;
  manualRefresh: EventEmitter<void>;
}

@Component( {
  selector: 'grounds-menu',
  templateUrl: './grounds-menu.component.html',
  styleUrls: [ './grounds-menu.component.scss' ],
} )
export class GroundsMenuComponent extends AppearMenuComponent {
  // portrait = window.matchMedia( '(orientation: portrait)' );

  isHardActive: boolean;
  isGrassActive: boolean;
  FLAG = true;
  groundTypes = GROUND_TYPES;
  lengthSlider: SimpleSliderModel = {
    value: 600,
    options: {
      floor: 600,
      ceil: 2000,
      step: 100,
      vertical: false, // !this.portrait.matches,
      showTicks: false,
      showTicksValues: false,
      getLegend: ( value: number ): string => {
        if ( value === this.lengthSlider.options.floor ) { return this.translate.instant( 'GROUNDS_MENU.ALL' ); }
        else { return value + ' m'; }
      },
      ticksArray: [ 600, 1300, 2000 ],
      showSelectionBar: true,
      translate: ( value: number ): string => {
        return '';
      }
    },
    manualRefresh: new EventEmitter<void>(),
  };

  refreshSlider(): void {
    this.lengthSlider.manualRefresh.emit();
  }

  constructor( private translate: TranslateService, private changeDetectorRef: ChangeDetectorRef ) {
    super();
    this.resetFilters();
    this.filtersUpdated = new EventEmitter<IFilters>();
    this.FLAG = true;

    /*if ( this.portrait?.addEventListener ) {
      this.portrait.addEventListener( 'change', ev => { this.viewUpdate(); } );
    } else {
      this.portrait.addListener( ev => { this.viewUpdate(); } );
    }*/
  }

  /*viewUpdate() {
    const newOptions: Options = Object.assign( {}, this.lengthSlider.options );
    newOptions.vertical = !this.portrait.matches;

    this.lengthSlider.options = newOptions;
    this.FLAG = false;
    this.changeDetectorRef.detectChanges();
    this.FLAG = true;
  }*/
  /**
   * Reset filters and close menu
   *
   * @memberof GroundsMenuComponent
   */
  closeFilters(): void {
    this.toggleGround();
    super.closeFilters();
  }

  /**
   * Reset all the filters
   *
   * @memberof GroundsMenuComponent
   */
  resetFilters(): void {
    this.isHardActive = false;
    this.isGrassActive = false;
    this.lengthSlider.value = 0;
  }

  /**
   * Send new filters to the parent component
   *
   * @param {GROUND_TYPES} type
   * @memberof GroundsMenuComponent
   */
  toggleGround( type?: GROUND_TYPES ): void {
    if ( type === GROUND_TYPES.HARD ) {
      this.isHardActive = !this.isHardActive;
    } else if ( type === GROUND_TYPES.GRASS ) {
      this.isGrassActive = !this.isGrassActive;
    }

    const grounds = [];
    if ( this.isHardActive ) { grounds.push( GROUND_TYPES.HARD ); }
    if ( this.isGrassActive ) { grounds.push( GROUND_TYPES.GRASS ); }

    this.filtersUpdated.emit(
      {
        groundType: grounds,
        airstripLength: this.lengthSlider.value !== this.lengthSlider.options.floor ? this.lengthSlider.value : 0
      }
    );
  }

}
