import { Injectable } from '@angular/core';
import { AlertOptions } from '@ionic/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ALERTS_TYPE } from 'src/models/enums';

@Injectable( {
  providedIn: 'root'
} )
export class AlertManagerService {


  private activeAlerts: Map<ALERTS_TYPE, boolean> = new Map<ALERTS_TYPE, boolean>();

  constructor(
    private alertController: AlertController,
    private translate: TranslateService
  ) { }

  async presentAlert( params: {
    backdropDismiss?: boolean,
    cssClass: string,
    header: string,
    subHeader: string,
    message: string,
    buttons: any[]
  },
                      type: ALERTS_TYPE ) {
    // Just one alert of each type at the time
    if ( this.activeAlerts.get( type ) ) { return; }

    const alertOptions: AlertOptions = {
      backdropDismiss: params.backdropDismiss === false ? false : true,
      cssClass: params.cssClass,
      header: params.header,
      subHeader: params.subHeader,
      message: params.message,
      buttons: params.buttons
    };

    const alert = await this.alertController.create( alertOptions );

    this.activeAlerts.set( type, true );
    alert.onWillDismiss().then( () => {
      this.activeAlerts.set( type, false );
    } );
    await alert.present();
  }

  /**
   * Generic error message displayed by default
   *
   * @memberof AlertManagerService
   */
  async genericAlert( type: string, alert: ALERTS_TYPE ) {
    await this.presentAlert( {
      cssClass: 'alert-generic-' + type.toLowerCase(),
      header: this.translate.instant( 'ALERTS.GENERIC.TITLE' ),
      subHeader: this.translate.instant( 'ALERTS.GENERIC.' + type.toUpperCase() + '.SUB_TITLE' ),
      message: this.translate.instant( 'ALERTS.GENERIC.' + type.toUpperCase() + '.MESSAGE' ),
      buttons: [ this.translate.instant( 'ALERTS.GENERIC.BUTTON' ) ]
    },
      alert );
  }

  async genericErrorAlert() {
    await this.genericAlert( 'error', ALERTS_TYPE.GENERIC_ERROR );
  }

  async genericWebAlert() {
    await this.genericAlert( 'web', ALERTS_TYPE.GENERIC_WEB );
  }

  async genericNoInternet() {
    await this.genericAlert( 'internet', ALERTS_TYPE.GENERIC_INTERNET );
  }

  async basicError( subHeader: string, message: string ) {
    await this.presentAlert( {
      cssClass: 'alert-basic-error',
      header: this.translate.instant( 'ALERTS.GENERIC.TITLE' ),
      subHeader: this.translate.instant( subHeader ),
      message: this.translate.instant( message ),
      buttons: [ this.translate.instant( 'ALERTS.GENERIC.BUTTON' ) ]
    },
      ALERTS_TYPE.BASIC_ERROR );
  }

  /**
   * Present a simple two-buttons-alert returning true or false when buttons are clicked
   *
   * @returns {Promise<boolean>}
   * @memberof AlertManagerService
   */
  async basicChoice( params: {
    header: string,
    message: string,
    cancel?: {
      text: string,
      class: string
    },
    confirm?: {
      text: string,
      class: string
    }
  } ): Promise<boolean> {
    const result = new Promise<boolean>( async ( resolve, reject ) => {

      const textCancel = this.translate.instant( params.cancel && params.cancel.text ? params.cancel.text : 'ALERTS.BASIC_CHOICE.CANCEL' );
      const textConfirm = this.translate.instant( params.confirm && params.confirm.text ? params.confirm.text : 'ALERTS.BASIC_CHOICE.CONFIRM' );
      let cssCancel = 'alert-basic-choice-cancel';
      if ( params.cancel && params.cancel.class ) {
        cssCancel += ' ' + params.cancel.class;
      }
      let cssConfirm = 'alert-basic-choice-confirm';
      if ( params.confirm && params.confirm.class ) {
        cssConfirm += ' ' + params.confirm.class;
      }

      await this.presentAlert( {
        cssClass: 'alert-basic-choice',
        header: this.translate.instant( params.header ),
        subHeader: null,
        message: this.translate.instant( params.message ),
        buttons: [ {
          text: textCancel,
          role: 'cancel',
          cssClass: cssCancel,
          handler: () => {
            resolve( false );
          }
        },
        {
          text: textConfirm,
          role: 'confirm',
          cssClass: cssConfirm,
          handler: () => {
            resolve( true );
          }
        } ]
      },
        ALERTS_TYPE.BASIC_CHOICE );
    } );

    return result;
  }

  /**
 * Present a simple two-buttons-alert returning true or false when buttons are clicked
 *
 * @returns {Promise<boolean>}
 * @memberof AlertManagerService
 */
  async basicAlert( params: {
    header: string,
    message: string,
    backdropDismiss?: boolean,
    confirm?: {
      text: string,
      class: string
    }
  } ): Promise<boolean> {
    const result = new Promise<boolean>( async ( resolve, reject ) => {
      const textConfirm = this.translate.instant( params.confirm && params.confirm.text ? params.confirm.text : 'ALERTS.GENERIC.BUTTON' );
      let cssConfirm = 'alert-basic-choice-confirm';
      if ( params.confirm && params.confirm.class ) {
        cssConfirm += ' ' + params.confirm.class;
      }

      await this.presentAlert( {
        cssClass: 'alert-basic-choice',
        backdropDismiss: params.backdropDismiss,
        header: this.translate.instant( params.header ),
        subHeader: null,
        message: this.translate.instant( params.message ),
        buttons: [
          {
            text: textConfirm,
            role: 'confirm',
            cssClass: cssConfirm,
            handler: () => {
              resolve( true );
            }
          } ]
      },
        ALERTS_TYPE.BASIC_CHOICE );
    } );

    return result;
  }

}
