<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button fill="clear" (click)="close()">
        <ion-icon large slot="start" name="arrow-back"></ion-icon>
        <ion-label>{{'GENERAL.BACK' | translate}}</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary">
      <div class="flex">
        <div class="vac-type">
          {{(vac.config.type === VACS_TYPES.VAC ? 'GENERAL.AIRPORT' : 'GENERAL.HELIPORT') | translate}}
        </div>
        <div class="vac-name">{{vac.config.city}}</div>
        <div class="vac-oaci">{{vac.config.oaci}}</div>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="download-container"
    [ngClass]="{'connected': isConnected, 'not-connected': !isConnected, 'toUpdate': (vac?.config?.map?.downloadedMap && vac.config.map.downloadedMap.version !== vac.config.map.version)}">
    <ion-grid>
      <ion-row *ngIf="!env?.isWeb; else web">
        <ion-col size="6" class="icon-container">
          <ion-col size="4">
            <ion-button fill="clear" (click)="trashOrDll()"
              [disabled]="(!isConnected && !(vac?.config.map.downloadedMap)) || isBeingDll">
              <ng-container *ngIf="!isBeingDll; else beingDll">
                <ion-icon slot="icon-only" name="{{vac?.config.map?.downloadedMap ? 'trash' : 'cloud-download' }}"
                  color="secondary">
                </ion-icon>
              </ng-container>
              <ng-template #beingDll>
                <ion-spinner></ion-spinner>
              </ng-template>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button fill="clear" (click)="toggleFav()"
              [disabled]="(!isConnected && !(vac?.config.map?.downloadedMap)) || isBeingDll">
              <ion-icon slot="icon-only" name="{{isFavorite ? 'star' : 'star-outline'}}"
                color="{{isFavorite ? 'warning' : 'secondary'}}"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <div *ngIf="vac?.config?.map?.downloadedMap" class="downloaded">
              <ng-container *ngIf="vac.config.map.downloadedMap.version === vac.config.map.version; else notUpToDate">
                <ion-icon class="check-icon" name="checkmark" color="secondary">
                </ion-icon>
              </ng-container>
              <ng-template #notUpToDate>
                <ion-button fill="clear" (click)="downloadPDF(false,false)" [disabled]="!isConnected || isBeingDll">
                  <ion-icon slot="icon-only" name="refresh" color="secondary">
                  </ion-icon>
                </ion-button>
              </ng-template>
            </div>
          </ion-col>
        </ion-col>
        <ion-col size="6" class="icon-container">
          <div class="button-container">
            <ng-container *ngIf="isConnected || vac?.config.map?.downloadedMap; else noDownloadPdf">
              <ion-button fill="outline" (click)="openPDF()" [disabled]="isBeingDll">
                {{(env?.isWeb ? 'VAC_DETAILS.DOWNLOAD' : 'VAC_DETAILS.OPEN') | translate}}
              </ion-button>
            </ng-container>
            <ng-template #noDownloadPdf>
              <div>
                {{'VAC_DETAILS.NOT_CONNECTED' | translate}}
              </div>
            </ng-template>
          </div>
        </ion-col>
      </ion-row>
      <ng-template #web>
        <ion-row>
          <ion-col size="6" class="icon-container">
            <ion-col size="4" *ngIf="isBeingDll">
              <ion-button fill="clear" disabled=true>
                <ng-container>
                  <ion-spinner></ion-spinner>
                </ng-container>
              </ion-button>
            </ion-col>
          </ion-col>
          <ion-col size="6">
            <div class="button-container">
              <ion-button fill="outline" (click)="downloadPDF()" [disabled]="isBeingDll">
                {{(env?.isWeb ? 'VAC_DETAILS.DOWNLOAD' : 'VAC_DETAILS.OPEN') | translate}}
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ng-template>
    </ion-grid>
  </div>
  <!--
  <div class="download-container" [ngClass]="{'not-connected': !isConnected}">
    <div *ngIf="!env?.isWeb" class="icon-container favoris">
      <ion-button fill="clear" (click)="toggleFav()" [disabled]="isBeingDll">
        <ion-icon slot="icon-only" name="{{isFavorite ? 'star' : 'star-outline'}}"
          color="{{isFavorite ? 'warning' : 'primary'}}"></ion-icon>-->
  <!--<span>{{(isFavorite ? 'VAC_DETAILS.REMOVE_FROM_FAV' : 'VAC_DETAILS.ADD_TO_FAV') | translate
          }}</span>-->
  <!--
      </ion-button>
    </div>
    <div class="icon-container icon-is-connected">
      <img src="{{isConnected ? './assets/icon/map-connected.png' : './assets/icon/map-not-connected.png'}}">
    </div>
    <div class="button-container">
      <ion-button fill="outline" (click)="downloadPDF()" *ngIf="isConnected;else notConnectedText"
        [disabled]="isBeingDll">
        {{(env?.isWeb ? 'VAC_DETAILS.DOWNLOAD' : 'VAC_DETAILS.OPEN') | translate}}
      </ion-button>
      <ng-template #notConnectedText>
        {{'VAC_DETAILS.NOT_CONNECTED' | translate}}
      </ng-template>
    </div>
  </div>-->

  <ion-grid class="details">
    <ion-row>
      <ion-col size="5">
        <ion-list>
          <ion-item *ngIf="vac.infosByLng?.manager">
            <ion-label class="title">{{'VAC_DETAILS.MANAGER' | translate}}</ion-label>
            <ion-label class="value">{{vac.infosByLng?.manager}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.config.ground">
            <ion-label class="title">{{'VAC_DETAILS.LATITUDE' | translate}}</ion-label>
            <ion-label class="value">{{formatCoord(vac.config.ground.coordsToDisplay.lat, "Lat")}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.config.ground">
            <ion-label class="title">{{'VAC_DETAILS.LONGITUDE' | translate}}</ion-label>
            <ion-label class="value">{{formatCoord(vac.config.ground.coordsToDisplay.lon, "Lon")}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.config.ground">
            <ion-label class="title">{{'VAC_DETAILS.ALTITUDE' | translate}}</ion-label>
            <ion-label class="value">{{vac.config.ground.elevation}} ft</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.infosByLng?.night">
            <ion-label class="title">{{'VAC_DETAILS.NIGHT' | translate}}</ion-label>
            <ion-label class="value">
              {{vac.infosByLng?.night ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate)}}
            </ion-label>
          </ion-item>
        </ion-list>
        <!--
        <ion-list *ngIf="vac.config.frequencies.length > 0">
          <ion-list-header>
            <ion-label class="title">{{'VAC_DETAILS.FREQUENCIES' | translate}}</ion-label>
          </ion-list-header>
          <ion-item *ngIf="vac.config.frequencies[0].APP">
            <ion-label class="title">APP</ion-label>
            <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].APP}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.config.frequencies[0].TWR">
            <ion-label class="title">TWR</ion-label>
            <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].TWR}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.config.frequencies[0].ATIS">
            <ion-label class="title">ATIS</ion-label>
            <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].ATIS}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.config.frequencies[0].FIS">
            <ion-label class="title">FIS</ion-label>
            <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].FIS}}</ion-label>
          </ion-item>
        </ion-list>-->

        <ion-list *ngIf="vac.longestAirstrip">
          <ion-list-header>
            <ion-label class="title">{{'VAC_DETAILS.AIRSTRIPS' | translate}}</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label class="title">{{'VAC_DETAILS.AIRSTRIP' | translate}}</ion-label>
            <ion-label class="value">{{vac.longestAirstrip.degrees}}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="title">{{'VAC_DETAILS.DIMENSIONS' | translate}}</ion-label>
            <ion-label class="value">{{vac.longestAirstrip.length}} x {{vac.longestAirstrip.width}}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="title">{{'VAC_DETAILS.SURFACE_TYPE' | translate}}</ion-label>
            <ion-label class="value">
              {{(vac.longestAirstrip.type === 'HARD'? 'GENERAL.HARD':'GENERAL.GRASS') | translate}}
            </ion-label>
          </ion-item>
        </ion-list>

        <div *ngFor="let runway of vac.config.runways">
          <ion-list *ngIf="runway !== vac.longestAirstrip">
            <ion-item>
              <ion-label class="title">{{'VAC_DETAILS.AIRSTRIP' | translate}}</ion-label>
              <ion-label class="value">{{runway.degrees}}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="title">{{'VAC_DETAILS.DIMENSIONS' | translate}}</ion-label>
              <ion-label class="value">{{runway.length}} x {{runway.width}}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="title">{{'VAC_DETAILS.SURFACE_TYPE' | translate}}</ion-label>
              <ion-label class="value">
                {{(runway.type === 'HARD'? 'GENERAL.HARD':'GENERAL.GRASS') | translate}}
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>

      <ion-col size="7" class="secondary-informations">
        <ion-list>
          <ion-item
            *ngIf="vac.infosByLng && (vac.infosByLng?.address || vac.infosByLng?.phoneNumber || vac.infosByLng?.faxNumber)">
            <ion-label class="title">
              <img src="./assets/imgs/contact.png">
            </ion-label>
            <ion-label class="picto"></ion-label>
            <ion-label class="value">
              {{vac.infosByLng?.address}}<br>
              {{vac.infosByLng?.phoneNumber}}<br>
              {{vac.infosByLng?.faxNumber}}
            </ion-label>
          </ion-item>
          <ion-item *ngIf="vac.infosByLng?.fuel">
            <ion-label class="title">
              <img src="./assets/imgs/fuel.png">
            </ion-label>
            <ion-label class="picto"></ion-label>
            <ion-label class="value">{{vac.infosByLng?.fuel}}</ion-label>
          </ion-item>
        </ion-list>

        <ion-list class="services">
          <ion-item *ngIf="vac.infosByLng?.repair">
            <ion-label class="title">{{'VAC_DETAILS.SERVICES' | translate}}</ion-label>
            <ion-label class="picto"></ion-label>
            <ion-label class="value">{{vac.infosByLng?.repair}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.infosByLng?.hotel">
            <ion-label class="title">{{!vac.infosByLng?.repair ? ('VAC_DETAILS.SERVICES' | translate) : ''}}</ion-label>
            <ion-label class="picto"></ion-label>
            <ion-label class="value">{{vac.infosByLng?.hotel}}</ion-label>
          </ion-item>
          <ion-item *ngIf="vac.infosByLng?.restaurant">
            <ion-label class="title">
              {{!(vac.infosByLng?.repair || vac.infosByLng?.hotel) ? ('VAC_DETAILS.SERVICES' | translate) : ''}}
              <img src="./assets/imgs/restaurant.png">
            </ion-label>
            <ion-label class="picto"></ion-label>
            <ion-label class="value">
              {{vac.infosByLng?.restaurant}}
            </ion-label>
          </ion-item>
          <ion-item *ngIf="vac.infosByLng?.bank">
            <ion-label class="title">
              {{!(vac.infosByLng?.repair || vac.infosByLng?.hotel || vac.infosByLng?.restaurant) ?
              ('VAC_DETAILS.SERVICES' | translate) : ''}}
            </ion-label>
            <ion-label class="picto"></ion-label>
            <ion-label class="value">{{vac.infosByLng?.bank}}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>