import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppearMenuComponent } from '../appear-menu.component';
import { ZONES_TYPE, POINTS_TYPE } from 'src/models/enums';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'layers-menu',
  templateUrl: './layers-menu.component.html',
  styleUrls: [ './layers-menu.component.scss' ],
} )
export class LayersMenuComponent extends AppearMenuComponent {

  constructor() {
    super();
  }
  public showSpinner = false;

  @Output() openAzba = new EventEmitter<void>();

  hasAzba = environment.isAZBA;

  layers = [
    {
      status: false,
      imgUrl: './assets/icon/layers/FIR.png',
      label: 'FIR',
      id: ZONES_TYPE.FIR,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/SIV.png',
      label: 'SIV',
      id: ZONES_TYPE.SIV,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/TMA.png',
      label: 'TMA',
      id: ZONES_TYPE.TMA,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/CTR.png',
      label: 'CTR',
      id: ZONES_TYPE.CTR,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/ZONES-RD.png',
      label: 'Zones R',
      id: ZONES_TYPE.ZONES_R,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/ZONES-RD.png',
      label: 'Zones D',
      id: ZONES_TYPE.ZONES_D,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/ZONES-P.png',
      label: 'Zones P',
      id: ZONES_TYPE.ZONES_P,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/VFR.png',
      label: 'Point VFR',
      id: POINTS_TYPE.VFR,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/VOR.png',
      imgUrl2: './assets/icon/layers/VOR-DME.png',
      label: 'VOR',
      id: POINTS_TYPE.VOR,
    },
    {
      status: false,
      imgUrl: './assets/icon/layers/obstacles/obstacle.png',
      label: 'Obstacles',
      fixedDim: true,
      id: POINTS_TYPE.OBS,
    },
  ];

  activeLayers = [];

  callbackAfterViewChecked: any = null;

  hideAll(): void {
    this.layers.forEach( ( a: any ) => ( a.status = false ) );
    this.toggleLayer();
  }

  setupAZBA(): void {
    // console.log('display AZBA filter');
    this.openAzba.emit();
  }

  ngAfterViewChecked() {
    if ( this.callbackAfterViewChecked ) {
      this.callbackAfterViewChecked();
      this.callbackAfterViewChecked = null;
    }
  }

  toggleLayer( index?: number, reRun?: any ): void {
    if ( index >= 0 ) {
      if ( !reRun && index >= 0 && index < this.layers.length ) {
        this.layers[ index ].status = !this.layers[ index ].status;
      }
      if ( !reRun && this.layers[ index ].id === POINTS_TYPE.OBS ) {
        this.callbackAfterViewChecked = () => {
          setTimeout( () => {
            this.toggleLayer( index, true );
          }, 50 );
        };
        this.showSpinner = true;
        return;
      }
    }
    this.activeLayers = this.layers.filter( ( a: any ) => {
      return a.status;
    } );

    const zones = [];
    const points = [];

    this.activeLayers.forEach( ( l ) => {
      switch ( l.id ) {
        case ZONES_TYPE.FIR:
        case ZONES_TYPE.SIV:
        case ZONES_TYPE.TMA:
        case ZONES_TYPE.CTR:
        case ZONES_TYPE.ZONES_R:
        case ZONES_TYPE.ZONES_D:
        case ZONES_TYPE.ZONES_P:
          // console.log('toggleLayer push ' + l.id);
          zones.push( l.id );
          break;
        case POINTS_TYPE.VFR:
        case POINTS_TYPE.VOR:
        case POINTS_TYPE.VOR_DME:
        case POINTS_TYPE.OBS:
          points.push( l.id );
          break;
      }
    } );

    // AZBA is automaticaly selected
    if ( environment.isAZBA ) {
      zones.push( ZONES_TYPE.RTBA );
    }

    if ( points.indexOf( POINTS_TYPE.VOR ) !== -1 ) {
      points.push( POINTS_TYPE.VOR_DME );
    }

    this.filtersUpdated.emit( {
      zones,
      points,
      callback: () => {
        this.showSpinner = false;
      },
    } );
  }
}
