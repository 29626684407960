<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button fill="clear" (click)="close()">
        <ion-icon large slot="start" name="arrow-back"></ion-icon>
        <ion-label>{{'GENERAL.BACK' | translate}}</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary">{{'INFORMATIONS.TITLE' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-slides pager="true" [options]="{
    initialSlide: 0,
    speed: 400
  }" (ionSlideDidChange)="slideChanged()" #ionSlider (ionSlidesDidLoad)="slideChanged()">

    <ion-card *ngIf="!alerts || alerts.length === 0">
      <ion-card-header>
        <ion-card-title>{{'INFORMATIONS.NO_ALERTS' | translate}}</ion-card-title>
      </ion-card-header>
    </ion-card>

    <ion-slide *ngFor="let selectedAlert of alerts">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{selectedAlert.startDate | date:'dd/MM/y'}}</ion-card-subtitle>
          <ion-card-title>{{selectedAlert.title }}</ion-card-title>
        </ion-card-header>

        <ion-card-content [innerHtml]="selectedAlert.message">
        </ion-card-content>
      </ion-card>
    </ion-slide>

  </ion-slides>
</ion-content>