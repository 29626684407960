interface IGN_DATA {
    key: string,
    userAgent: string,
    mapType: string
}

export const IGN_WEB_DEV: IGN_DATA = {
    key: 'decouverte',
    userAgent: null,
    mapType: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2 '
}

export const IGN_WEB_PROD: IGN_DATA = {
    key: 'decouverte',
    userAgent: null,
    mapType: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2 '
}

export const IGN_IPAD_DEV: IGN_DATA = {
    key: 'decouverte',
    userAgent: 'SIADGAC2020',//'SOFIAVAC'
    mapType: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2 '
}

export const IGN_IPAD_PROD: IGN_DATA = {
    key: 'decouverte',
    userAgent: 'SIADGAC2020',
    mapType: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2 '
}

//https://geoservices.ign.fr/documentation/donnees-ressources-wmts.html
