import { Subscription } from 'rxjs';
import { OnDestroy, OnInit, Directive } from '@angular/core';

/**
 * Abstract class used to manage auto desubscription on component's end of life
 *
 * @export
 * @abstract
 * @class Desubscribe
 * @implements {OnDestroy}
 */
@Directive()
export abstract class Desubscribe implements OnInit, OnDestroy {

    private subscriptions: Subscription[];

    constructor() {
        this.subscriptions = [];
    }

    addSubscription( subscription: Subscription ) {
        this.subscriptions.push( subscription );
    }

    ngOnInit( reinit: boolean = true ): void {
        if ( reinit ) { this.subscriptions = []; }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach( ( s: Subscription ) => {
            s.unsubscribe();
        } );
    }
}
