import { Injectable } from '@angular/core';
import {
  HttpParams,
  HttpHeaders,
  HttpResponse,
  HttpClient,
} from '@angular/common/http';
import { of, Observable, Subject, throwError, from, forkJoin } from 'rxjs';
import { catchError, flatMap, map, takeLast } from 'rxjs/operators';
import { HTTP } from '@ionic-native/http/ngx';

import { environment } from 'src/environments/environment';
import {
  ConfigValues,
  EditablePageName,
  ENDPOINTS,
  PageParams,
} from './endpoints';
import {
  IEditablePage,
  IGround_WS,
  IMap_WS,
  IVacDetails_WS,
  IVac_WS,
  IZone_WS,
  toCodeClass,
  toZoneType,
  COORDINATES_TYPE_WS,
  IAzba_Range_WS,
  ZONES_TYPE_WS,
  Activated_AZBA,
  AZBA_TimeSlot,
  IAzba_Timeslot_WS,
} from 'src/models/interfaces/web-services';
import { cleanWhiteSpace, getKey, IVac } from 'src/models/interfaces/vac';
import { IRunway } from 'src/models/interfaces/runway';

import {
  AbstractWebServices,
  Content_Types,
  Http_Methods,
} from './abstract/abstract-web-services.service';
import { DmsToDdPipe } from 'src/app/pipes/dms-to-dd/dms-to-dd.pipe';
import { IMap } from 'src/models/interfaces/map';
import { IGround, ICoords } from 'src/models/interfaces/ground';
import { IFrequencie } from 'src/models/interfaces/frequencie';
import { IInformations } from 'src/models/interfaces/informations';
import {
  VACS_TYPES,
  ZONES_TYPE,
  POINTS_TYPE,
  TMA_TYPE,
  toVacType,
} from 'src/models/enums';
import { IZone, IPoint, IPointObs } from 'src/models/interfaces/points';
import { UtilsService } from '../utils/utils.service';
import * as turf from '@turf/turf';
import { AzbaRange } from 'src/models/interfaces/range';
import { formatDate } from '@angular/common';
import { FileService } from '../file/file.service';

const DEBUG = false; // TEMPORARY TO DEBUG
// const DEBUG = true; // TEMPORARY TO DEBUG
const numObstaclesDebug = 30;

@Injectable( {
  providedIn: 'root',
} )
export class WebServices extends AbstractWebServices {
  private _dmsToDd = new DmsToDdPipe().transform;

  private FORMAT_DATE = 'dd/MM/yyyy';
  private FORMAT_HOUR = 'HH:mm';

  constructor(
    protected http: HttpClient,
    protected utils: UtilsService,
    private nativeHttp: HTTP,
    private readonly fileService: FileService
  ) {
    super( http, utils );

    this.host = environment.baseUrl;
    this.mockHost = environment.mockUrl;
    this.mockExtension = environment.mockExtension;
    this.isMock = environment.isMock;
  }

  /**
   * Send the request to the API endpoint
   * @param {string} service API endpoint
   * @param {string} method POST, GET...
   * @param {(Object | JSON)} [data=null] the data to pass in parameters
   * @param {HttpParams} [params=null] existing params if needed
   * @param {boolean} [needAuthentification=false] is authentification needed
   * @param {HttpHeaders} [headers=null] existing headers if needed
   * @param {string} [contentType=null] value of contentType
   * @param {('arraybuffer' | 'blob' | 'json' | 'text')} [responseType='json'] what type of response do we expect
   * @returns {Observable<Response>}
   * @memberof WebservicesProvider
   */
  protected executeRequest(
    service: string,
    method: string,
    data: object | JSON = null,
    params: HttpParams = null,
    isAbsolute: boolean = false,
    needAuthentification: boolean = false,
    headers: HttpHeaders = null,
    contentType: string = null,
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json',
    isMock: boolean = false
  ): Observable<any> {
    const returnObservable = this.launchRequest(
      service,
      method,
      data,
      params,
      isAbsolute,
      needAuthentification,
      headers,
      contentType,
      responseType,
      isMock
    );

    return returnObservable.pipe(
      takeLast( 1 ),
      map( ( d: Response ) => {
        return d.body ? d.body : d;
      } ),
      catchError( ( e ) => {
        return this.handleError( e );
      } )
    );
  }

  /**
   * Called on every error returned by executeRequest
   *
   * @private
   * @param {HttpResponse<any>} error
   * @returns
   * @memberof WebServicesProvider
   */
  private handleError( error: HttpResponse<any> ) {
    return throwError( error );
  }

  /**
   * Recover editable page representing the legal informations
   *
   * @returns {Observable<IEditablePage>}
   * @memberof WebServices
   */
  public recoverLegalNotices(): Observable<IEditablePage> {
    return this.recoverEditablePage( EditablePageName.LEGAL );
  }

  /**
   * Recover the pdfs for user_guide
   *
   * @returns {Observable<IEditablePage>}
   * @memberof WebServices
   */
  public recoverGettingStarted(): Observable<any> {
    const newHost = this.host.replace( '/api', '' );
    const aboutSiaEn = newHost + 'user_guide_en.pdf';
    const aboutSiaFr = newHost + 'user_guide_fr.pdf';
    const myObservableEn = from(
      this.nativeHttp.downloadFile(
        aboutSiaEn,
        null,
        {},
        this.getUserGuideCacheUrl( 'en' )
      )
    );
    const myObservableFr = from(
      this.nativeHttp.downloadFile(
        aboutSiaFr,
        null,
        {},
        this.getUserGuideCacheUrl( 'fr' )
      )
    );
    return forkJoin( myObservableEn, myObservableFr );
  }

  public getUserGuideCacheUrl( lang: string ): string {
    return this.fileService.dataDirectory + `user_guide_${ lang }.pdf`;
  }

  /**
   * Recover editable page representing the about SIA
   *
   * @returns {Observable<IEditablePage>}
   * @memberof WebServices
   */
  public recoverAboutSia(): Observable<IEditablePage> {
    return this.recoverEditablePage( EditablePageName.ABOUT );
  }

  /**
   * Recover an Editable Page
   *
   * @private
   * @param {string} name
   * @returns {Observable<IEditablePage>}
   * @memberof WebServices
   */
  private recoverEditablePage( name: string ): Observable<IEditablePage> {
    return this.executeRequest(
      ENDPOINTS.EDITABLE_PAGES( name ),
      Http_Methods.GET
    ).pipe(
      map( ( data: any ) => {
        return this.formatEditablePage( data );
      } )
    );
  }

  /**
   * Format back return for an Editable page
   *
   * @private
   * @param {*} data
   * @returns {IEditablePage}
   * @memberof WebServices
   */
  private formatEditablePage( data: any ): IEditablePage {
    const page = data[ 'hydra:member' ][ 0 ];
    return {
      '@id': page[ '@id' ],
      '@type': page[ '@type' ],
      id: page.id,
      key: page.key,
      title: page.title,
      content: {
        fr: page.contentFr,
        en: page.contentEn,
      },
    };
  }

  /**
   * Called to receive the configs informations (last and next update mostly)
   *
   * @returns {Observable<{ lastUpdate: Date, importDate: number }>}
   * @memberof WebservicesProvider
   */
  public getConfigs(): Observable<{
    lastUpdate: Date;
    nextUpdate: Date;
    importDate: number;
  }> {
    const TWENTY_EIGHT_DAY = 2419200000;

    return this.executeRequest( ENDPOINTS.CONFIG, Http_Methods.GET ).pipe(
      map( ( data ) => {
        const result = { lastUpdate: null, nextUpdate: null, importDate: null };
        if ( data && data[ 'hydra:member' ] && data[ 'hydra:member' ].length ) {
          let actualHE, actualAD, importDate;
          data[ 'hydra:member' ].forEach( ( value: any ) => {
            if ( value && value.name ) {
              switch ( value.name ) {
                case ConfigValues.ACTUAL_HE:
                  actualHE = value.value;
                  break;
                case ConfigValues.ACTUAL_AD:
                  actualAD = value.value;
                  break;
                case ConfigValues.IMPORT_DATE:
                  importDate = value.value;
              }
            }
          } );

          result.lastUpdate =
            actualHE < actualAD ? new Date( actualAD ) : new Date( actualHE );
          result.nextUpdate = new Date(
            result.lastUpdate.getTime() + TWENTY_EIGHT_DAY
          );
          result.importDate = importDate;
        }

        return result;
      } )
    );
  }

  /**
   * Chained all the observables to receive all the data without pagination
   *
   * @param {Subject<{ step: number, finalStep: number }>} stepsObservable
   * @param {{ index: number, maxIndex: number, result: any[] }} progression
   * @param {string} urlPath
   * @param {Function} formatFunction
   * @returns {Observable<any[]>}
   * @memberof WebservicesProvider
   */
  public getByPagination(
    stepsObservable: Subject<{
      step: number;
      finalStep: number;
      nbElement?: number;
      nbElementTotal?: number;
    }>,
    progression: { index: number; maxIndex: number; result: any[] },
    urlPath: string,
    formatFunction: Function,
    itemsPerPage: number = environment.itemsPerPage
  ): Observable<any[]> {
    if ( !progression ) {
      progression = {
        index: 1, // Begin at 1, why not...
        maxIndex: 1,
        result: [],
      };
    }
    if ( progression.index > progression.maxIndex ) {
      return of( progression.result );
    }

    const params: HttpParams = this.generateParameters(
      new PageParams( progression.index, itemsPerPage )
    );

    return this.executeRequest( urlPath, Http_Methods.GET, null, params ).pipe(
      flatMap( ( data ) => {
        // console.log(JSON.stringify(data));
        if ( data[ 'hydra:member' ] ) {
          data[ 'hydra:member' ].forEach( ( element ) => {
            const newElement = formatFunction( element );
            if ( newElement ) {
              if ( Array.isArray( newElement ) ) {
                Array.prototype.push.apply( progression.result, newElement );
              } else {
                progression.result.push( newElement );
              }
            }
          } );
        }
        progression.index++;
        progression.maxIndex = Math.ceil(
          data[ 'hydra:totalItems' ] / itemsPerPage
        );
        if ( DEBUG ) {
          progression.maxIndex = Math.ceil(
            numObstaclesDebug / itemsPerPage
          );
        }
        // progression.index = progression.maxIndex + 1;
        if ( stepsObservable ) {
          stepsObservable.next( {
            step: progression.index,
            finalStep: progression.maxIndex,
            nbElement: progression.result.length,
            nbElementTotal: data[ 'hydra:totalItems' ],
          } );
        }
        return this.getByPagination(
          stepsObservable,
          progression,
          urlPath,
          formatFunction
        );
      } ),
      catchError( ( e ) => {
        console.log( e );
        return of( e );
      } )
    );
  }

  /**
   *
   *
   * @returns {Observable<IGround>}
   * @memberof WebServices
   */
  public getVacs(
    stepsObservable?: Subject<{
      step: number;
      finalStep: number;
      nbElement: number;
      nbElementTotal: number;
    }>,
    progression?: { index: number; maxIndex: number; result: IVac[] }
  ): Observable<IVac[]> {
    return this.getByPagination(
      stepsObservable,
      progression,
      ENDPOINTS.OACIS,
      this.formatVac.bind( this )
    );
  }

  private addOffset( coord: string, offset: number, length: number ): string {
    const position: string = coord.slice( 0, coord.length - 1 );
    const direction: string = coord.slice( coord.length - 1 );
    const value: number = parseFloat( position ) + offset;
    return `${ value.toFixed( 2 ).padStart( length - 1, '0' ) }${ direction }`;
  }

  private formatGround( g: IGround_WS, patchHP: boolean = false ): IGround {
    const type = patchHP ? VACS_TYPES.HEL : toVacType( g.type );
    const lat = patchHP ? this.addOffset( g.latitude, -10, 10 ) : g.latitude;
    const lon = patchHP ? this.addOffset( g.longitude, 20, 11 ) : g.longitude;

    return {
      type,
      coords: {
        lat: this._dmsToDd( lat ),
        lon: this._dmsToDd( lon ),
      },
      coordsToDisplay: {
        lat,
        lon,
      },
      elevation: parseInt( g.elevation ),
    };
  }

  /**
   * Format backend data to front model
   *
   * @param vac
   * @returns {IVac}
   * @memberof WebServices
   */
  private formatVac( vac_ws: IVac_WS ): IVac[] {
    if (
      environment.check_dataset &&
      vac_ws &&
      ( !vac_ws.grounds || !vac_ws.grounds.length )
    ) {
      console.error( `[FIXME] [${ vac_ws.code }] n'a pas de ground !!!` );
    }

    if ( !vac_ws || !vac_ws.grounds || !vac_ws.grounds.length ) {
      // console.log('Plein d\'erreur : ' + JSON.stringify(vac_ws));
      return null;
    }

    // AD
    let ground_AD: IGround;
    let map_AD: IMap;
    const runways: IRunway[] = [];

    // HP
    let ground_HP: IGround;
    let map_HP: IMap;

    // COMMON
    const informations: IInformations[] = [];
    const frequencies: IFrequencie[] = [];

    // format and assign grounds
    vac_ws.grounds.map( ( g: IGround_WS ) => {
      const ground: IGround = this.formatGround( g );

      if ( ground.type === VACS_TYPES.VAC ) {
        ground_AD = ground;
      } else {
        ground_HP = ground;
      }
    } );

    // format and assign maps
    vac_ws.maps.map( ( m: IMap_WS ) => {
      const map: IMap = {
        fileName: m.fileName,
        type: toVacType( m.type ),
        version: m.version,
        fileSize: m.fileSize,
      };

      if ( map.type === VACS_TYPES.VAC ) {
        map_AD = map;
      } else {
        map_HP = map;
      }
    } );

    vac_ws.runways.map( ( r ) => {
      runways.push( {
        length: parseInt( r.length ),
        width: parseInt( r.width ),
        type: r.type,
        degrees: r.degrees,
      } );
    } );

    vac_ws.frequencies.map( ( f ) => {
      frequencies.push( {
        APP: f.freqAPP,
        TWR: f.freqTWR,
        VDF: f.freqVDF,
        ATIS: f.freqATIS,
        FIS: f.freqFIS,
      } );
    } );

    vac_ws.information.map( ( i ) => {
      informations.push( {
        address: i.address,
        phoneNumber: i.phoneNumber,
        faxNumber: i.faxNumber,
        hotel: i.hotel,
        restaurant: i.restaurant,
        fuel: i.fuel,
        repair: i.repair,
        night: i.night,
        codeActivity: i.codeActivity,
        descriptionActivity: i.descriptionActivity,
        language: i.language,
        manager: i.manager,
        bank: i.bank,
      } );
    } );

    // if ( vac_ws.maps.length > 1 ) console.info( `[PATCHED] ${ vac_ws.maps.length } cartes pour [${ vac_ws.code }]`, vac_ws );
    // if ( vac_ws.grounds.length > 1 ) console.info( `[PATCHED] ${ vac_ws.grounds.length } grounds pour [${ vac_ws.code }]`, vac_ws );

    if ( vac_ws.maps.length > 1 && vac_ws.grounds.length < 2 ) {
      if ( environment.check_dataset ) {
        console.warn( '[PATCHED] Plusieurs maps pour un seul ground !', vac_ws );
      }
      ground_HP = this.formatGround( vac_ws.grounds[ 0 ], true );
    }

    const vacs: IVac[] = [];
    if ( ground_AD ) {
      vacs.push(
        this.generateVac(
          vac_ws,
          ground_AD,
          map_AD,
          frequencies,
          informations,
          runways
        )
      );
    }
    if ( ground_HP ) {
      vacs.push(
        this.generateVac( vac_ws, ground_HP, map_HP, frequencies, informations )
      );
    }
    // console.log('allVacs : ' + JSON.stringify(vacs));
    return vacs;
  }

  private generateVac(
    vac: IVacDetails_WS,
    ground: IGround,
    map: IMap,
    frequencies: IFrequencie[],
    informations: IInformations[],
    runways: IRunway[] = null
  ): IVac {
    const type: VACS_TYPES = map ? map.type : ground.type;

    return {
      key: getKey( vac.code, type ),
      oaci: vac.code,
      city: cleanWhiteSpace( vac.city ),
      type,
      ground,
      runways,
      map,
      frequencies,
      informations
    };
  }

  /**
   * Renvoi les dates liées à Azba :
   * start              date de début de recherche pour la zone d'activation
   * end                date de fin de recherche pour la zone d'activation
   * latest_azba_date   dernière date dans l'historique des zones azba
   *
   * @returns
   */
  public getAzbaRange(): Observable<AzbaRange> {
    return this.executeRequest( ENDPOINTS.AZBA_RANGE, Http_Methods.GET ).pipe(
      map( ( data: IAzba_Range_WS ) => {
        console.warn( data );
        return {
          latest_azba_date: data.rtba,
          range: {
            start: data.startDate,
            end: data.endDate,
          },
        };
      } )
    );
  }

  public getPdfUrl( oaci: string, type: string ) {
    return this.host + ENDPOINTS.GET_FILE( oaci, type );
  }
  /**
   * Called to received the pdf file and download it
   *
   * @param {number} pdfId
   * @returns {Observable<any>}
   * @memberof WebServices
   */
  public getPdf(
    oaci: string,
    type: string,
    temp: boolean = true
  ): Observable<any> {
    const key: string = oaci + '_' + type;
    const urlPdf = this.getPdfUrl( oaci, type );
    const myObservable = from(
      this.nativeHttp.downloadFile(
        urlPdf,
        null,
        {
          Authorization: 'Basic ' + btoa( 'api:L4b6P!d9+YuiG8-M' ),
          Auth: this.generateAuth( urlPdf ),
          // Save on folder to prevent memory overcharge
        },
        ( temp
          ? this.fileService.cacheDirectory
          : this.fileService.dataDirectory ) /*+ oaci + '/'*/ +
        key +
        '.pdf'
      )
    );
    return myObservable;
  }

  public openFromBrowser( oaci: string, type: string ): Observable<Blob> {
    const urlPdf = this.getPdfUrl( oaci, type );

    return this.http
      .get( urlPdf, {
        headers: new HttpHeaders( {
          Authorization: 'Basic ' + btoa( 'api:L4b6P!d9+YuiG8-M' ),
          Auth: this.generateAuth( urlPdf ),
          'Content-Type': Content_Types.APPLICATION_PDF,
        } ),
        responseType: 'blob',
        observe: 'response',
      } )
      .pipe(
        map( ( res: HttpResponse<Blob> ) => {
          return res.body;
        } )
      );
  }

  /**
   * Called to received the notifications
   *
   * @returns {Observable<any>}
   * @memberof WebServices
   */
  public getNotifications(): Observable<any> {
    const today: string = formatDate( new Date(), 'yyyy-MM-dd', 'fr' );

    return this.executeRequest( ENDPOINTS.NOTIFICATIONS( today, today ), Http_Methods.GET,
    ).pipe(
      map( ( data ) => {
        return data[ 'hydra:member' ];
      } )
    );
  }

  public getZones(
    zone: ZONES_TYPE,
    stepsObservable?: Subject<{ step: number; finalStep: number }>,
    progression?: { index: number; maxIndex: number; result: IVac[] }
  ): Observable<IZone[]> {
    let zoneBack = '';
    const zoneStart = 'initialCodeType';
    switch ( zone ) {
      case ZONES_TYPE.FIR:
        zoneBack = 'FIR';
        break;
      case ZONES_TYPE.SIV:
        zoneBack = 'SIV';
        break;
      case ZONES_TYPE.TMA:
        zoneBack = 'TMA';
        break;
      case ZONES_TYPE.CTR:
        zoneBack = 'CTR';
        break;
      case ZONES_TYPE.ZONES_R:
        zoneBack = 'R';
        break;
      case ZONES_TYPE.ZONES_D:
        zoneBack = 'D';
        break;
      case ZONES_TYPE.ZONES_P:
        zoneBack = 'P';
        break;
    }
    return this.getByPagination(
      stepsObservable,
      progression,
      ENDPOINTS.ZONES( zoneStart, zoneBack ),
      ( z ) => this.formatZone( z )
    );
  }

  public getZoneRTBA(
    version,
    stepsObservable?: Subject<{ step: number; finalStep: number }>,
    progression?: { index: number; maxIndex: number; result: IVac[] }
  ): Observable<IZone[]> {
    const formatZoneRTBA = (
      zone: IZone_WS,
      isFromSub: boolean = false
    ): IZone => {
      zone.initialCodeType = ZONES_TYPE_WS.RTBA;
      zone.codeId = '_RTBA_' + zone.codeId;
      return this.formatZone( zone, isFromSub );
    };

    return this.getByPagination(
      stepsObservable,
      progression,
      ENDPOINTS.RTBA_NETWORK( version ),
      ( z ) => formatZoneRTBA( z )
    );
  }

  public getActiveAZBA(
    version,
    startDate,
    endDate
  ): Observable<Activated_AZBA[]> {
    return this.executeRequest(
      ENDPOINTS.AZBA_ACTIVE( version, startDate, endDate ),
      Http_Methods.GET
    ).pipe(
      map( ( data ) => {
        let response: Activated_AZBA[] = [];
        let activated: Activated_AZBA;
        let data_WS: any[];
        if ( data && data[ 'hydra:member' ] ) {
          data_WS = data[ 'hydra:member' ];

          try {
            data_WS.forEach( ( azba_active_ws ) => {
              activated = {
                codeId: azba_active_ws.codeId,
                timeSlots: [],
              };

              let timeslot: AZBA_TimeSlot;
              let timeslot_ws: IAzba_Timeslot_WS;

              if ( false ) {
                // test pour des zones actives sur plusieurs jours
              }

              for ( const timeslotId in azba_active_ws.timeSlots ) {
                timeslot_ws = azba_active_ws.timeSlots[ timeslotId ];

                try {
                  timeslot = {
                    from: new Date( timeslot_ws.startTime ).getTime(),
                    to: new Date( timeslot_ws.endTime ).getTime(),
                    startDate: formatDate(
                      timeslot_ws.startTime,
                      this.FORMAT_DATE,
                      'fr',
                      '+0000'
                    ),
                    endDate: formatDate(
                      timeslot_ws.endTime,
                      this.FORMAT_DATE,
                      'fr',
                      '+0000'
                    ),
                    startHour: formatDate(
                      timeslot_ws.startTime,
                      this.FORMAT_HOUR,
                      'fr',
                      '+0000'
                    ),
                    endHour: formatDate(
                      timeslot_ws.endTime,
                      this.FORMAT_HOUR,
                      'fr',
                      '+0000'
                    ),
                  };
                } catch ( e ) {
                  console.error( e );
                }

                activated.timeSlots.push( timeslot );
              }

              activated.timeSlots.sort( ( a, b ) =>
                a.startDate === b.endDate ? a.to - b.to : a.from - b.from
              );
              response.push( activated );
            } );
          } catch ( e ) {
            response = data_WS;
          }
        }
        return response;
      } )
    );
  }

  private formatZone( zone: IZone_WS, isFromSub: boolean = false ): IZone {
    if ( !zone || !zone.coordinates || !zone.initialCodeType ) {
      return;
    }

    const type: ZONES_TYPE = toZoneType( zone.initialCodeType );
    const codeClass: TMA_TYPE | string = toCodeClass( zone.codeClass );

    const coords = [];
    const coordinatesLength = zone.coordinates.length;
    zone.coordinates.forEach( ( c, index: number ) => {
      const coord: ICoords = {
        lat: this._dmsToDd( c.latitude ),
        lon: this._dmsToDd( c.longitude ),
      };
      coords.push( coord );

      if ( c.valRadiusArc && c.codeType ) {
        if ( c.codeType === COORDINATES_TYPE_WS.CIRCLE ) {
          // meters to kilometers
          c.valRadiusArc /= 1000; // 1852; //2539.957;
          if ( !c.geoLongArc && !c.geoLatArc ) {
            c.geoLongArc = c.longitude;
            c.geoLatArc = c.latitude;
            coords.length = 0;
          }
        }

        const indexToNext = index === coordinatesLength - 1 ? 0 : index + 1;
        const cNext: ICoords = {
          lat: this._dmsToDd( zone.coordinates[ indexToNext ].latitude ),
          lon: this._dmsToDd( zone.coordinates[ indexToNext ].longitude ),
        };

        const center = turf.point( [
          this._dmsToDd( c.geoLongArc ),
          this._dmsToDd( c.geoLatArc ),
        ] );
        let radius = turf.distance(
          center.geometry.coordinates,
          this.utils.coordsToTurf( coord )
        );
        if ( radius === 0 ) {
          radius = c.valRadiusArc;
        }

        const bearing1 = turf.bearing(
          center.geometry.coordinates,
          this.utils.coordsToTurf( coord )
        );
        const bearing2 = turf.bearing(
          center.geometry.coordinates,
          this.utils.coordsToTurf( cNext )
        );
        // const arc = turf.lineArc( center, radius, bearing1, bearing2 );
        const arc = turf.lineArc(
          center.geometry.coordinates,
          radius,
          c.codeType === COORDINATES_TYPE_WS.CCA ? bearing2 : bearing1,
          c.codeType === COORDINATES_TYPE_WS.CCA ? bearing1 : bearing2
        );
        if ( c.codeType === COORDINATES_TYPE_WS.CCA ) {
          // Don't get the first and last points, as they are the "same" as the previous and next coord
          for ( let i = arc.geometry.coordinates.length - 2; i > 0; --i ) {
            coords.push( this.utils.turfToCoord( arc.geometry.coordinates[ i ] ) );
          }
        } else {
          for ( let i = 1; i < arc.geometry.coordinates.length - 1; ++i ) {
            coords.push( this.utils.turfToCoord( arc.geometry.coordinates[ i ] ) );
          }
        }
      }
    } );

    // We don't want zone without coords
    if ( coords.length === 1 || ( coords.length === 0 && !isFromSub ) ) {
      // console.log('drop');
      return;
    }

    const subZones = [];
    if ( zone.zones && zone.zones.length ) {
      zone.zones.forEach( ( z ) => {
        const newZ = this.formatZone( z, true );
        newZ.coords = coords;
        subZones.push( newZ );
      } );
    }

    let frequencies = null;
    if ( zone.frequency ) {
      frequencies = {
        ATIS: zone.frequency.freqATIS,
        APP: zone.frequency.freqApp,
        FIS: zone.frequency.freqFIS,
        TWR: zone.frequency.freqTWR,
        VDF: zone.frequency.freqVDF,
      };
    }


    const toReturn: IZone = {
      initialCodeType: zone.initialCodeType,
      codeClass,
      id: zone.codeId,
      type,
      coords,
      name: zone.name,
      uomDistVerLower: zone.uomDistVerLower,
      uomDistVerUpper: zone.uomDistVerUpper,
      valDistVerLower: zone.valDistVerLower,
      valDistVerUpper: zone.valDistVerUpper,
      codeDistVerUpper: zone.codeDistVerUpper,
      codeDistVerLower: zone.codeDistVerLower,
      subZones,
      frequency: frequencies,
      isRTBA: zone.isRtba,
    };

    return toReturn;
  }

  public getPoints(
    point: POINTS_TYPE,
    stepsObservable?: Subject<{ step: number; finalStep: number }>,
    progression?: { index: number; maxIndex: number; result: IVac[] }
  ): Observable<any> {
    let pointBack = '';
    let pointStart = 'initialCodeType';

    switch ( point ) {
      case POINTS_TYPE.VFR:
        pointBack = 'VFR';
        break;
      case POINTS_TYPE.VOR:
        pointBack = 'VOR';
        break;
      case POINTS_TYPE.VOR_DME:
        pointBack = 'VOR-DME';
        pointStart = 'codeType';
        break;
      case POINTS_TYPE.OBS:
        pointBack = 'obstacles';
        break;
    }
    return this.getByPagination(
      stepsObservable,
      progression,
      ENDPOINTS.POINTS( pointStart, pointBack ),
      this.formatPoint
    );
  }

  public getObstacles(
    point: POINTS_TYPE,
    stepsObservable?: Subject<{ step: number; finalStep: number }>,
    progression?: { index: number; maxIndex: number; result: IVac[] }
  ): Observable<any> {
    let pointBack = '';
    const pointStart = 'initialCodeType';
    const itemsPerPage = DEBUG ? numObstaclesDebug : 200 * 60;

    switch ( point ) {
      case POINTS_TYPE.OBS:
        pointBack = 'obstacles';
        break;
    }
    return this.getByPagination(
      stepsObservable,
      progression,
      ENDPOINTS.OBS( pointStart, pointBack ),
      this.formatPointObs,
      itemsPerPage
    );
  }

  /*
    raw data of a point

    @id: "/api/obstacles/52624"
    @type: "Obstacle"
    altitude: "318"
    codeLight: false
    geoLat: "482936.00N"
    geoLong: "0015526.00W"
    height: "167"
    isGroup: false
    name: "22033"
    reference: "U"
    txtRmq: null
    type: "Pylône"
    typeLight: null
    uomDistVer: "FT"

-----------------------------

Au tap sur un obstacle on affichera son type, sa description, son altitude et sa hauteur en précisant son type de balisage, ainsi que les informations complémentaires <txtDescrLgt> et enfin regroupement si <codegroup> est Yes sinon pas de mention

typeLight = type de balisage
*/
  private formatPointObs( point: any ): IPointObs {
    if ( !point ) {
      return;
    }
    const pipeDmsToDdPipe = new DmsToDdPipe();

    const type: POINTS_TYPE = POINTS_TYPE.OBS;
    const result: any = JSON.parse( JSON.stringify( point ) ) as any;
    // console.log( JSON.stringify( point ) );
    result.id = result[ '@id' ];
    delete result[ '@id' ];
    result.typeObs = result[ '@type' ];
    delete result[ '@type' ];
    result.coords = {
      lat: pipeDmsToDdPipe.transform( point.geoLat ),
      lon: pipeDmsToDdPipe.transform( point.geoLong ),
      // on réutilise ces fields dans ICoords pour éviter de créer une version dans la base de données
      latArc: point.geoLat,
      lonArc: point.geoLong
    };
    delete result.geoLat;
    delete result.geoLong;
    return result as IPointObs;
  }

  private formatPoint( point: any ): IPoint {
    if ( !point || !point.coordinates || !point.initialCodeType ) {
      return;
    }
    const pipeDmsToDdPipe = new DmsToDdPipe();

    let type: POINTS_TYPE;
    switch ( point.initialCodeType ) {
      case 'DME':
        type = POINTS_TYPE.VOR_DME;
        break;
      case 'VOR':
        type = POINTS_TYPE.VOR;
        break;
      case 'VFR':
        type = POINTS_TYPE.VFR;
        break;
    }

    return {
      id: type === POINTS_TYPE.VFR ? point.txtName : point.codeId,
      type,
      coords: {
        lat: pipeDmsToDdPipe.transform( point.coordinates.latitude ),
        lon: pipeDmsToDdPipe.transform( point.coordinates.longitude ),
      },
      name: type === POINTS_TYPE.VFR ? point.codeId : point.txtName,
      rmk: point.txtRmk,
      uomFreq: point.uomFreq,
      valFreq: point.valFreq,
    };
  }
}
