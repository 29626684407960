import {
  HttpClient,
  HttpRequest,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as sha512 from 'js-sha512';
import { UtilsService } from '../../utils/utils.service';
import { environment } from '../../../../environments/environment';

// The request's methods
export enum Http_Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

// The content types for the headers
export enum Content_Types {
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_JSON = 'application/json',
  MULTIPART_FORM_DATA = 'multipart/form-data',
  TEXT_PLAIN = 'text/plain',
}

@Injectable()
export abstract class AbstractWebServices {
  // static S = 'Th1sIsMyS&cr3t+fdg3213fg213gfh32f1g3gf21h3gf21hds56f4d5s64f';

  // The api address of the application
  private hostP = '';
  private mockHostP = '';
  private mockExtensionP = '';
  private isMockP = false;

  constructor(protected http: HttpClient, protected utils: UtilsService) {}

  get host(): string {
    return this.hostP;
  }
  set host(host: string) {
    this.hostP = host;
  }

  get mockHost(): string {
    return this.mockHostP;
  }
  set mockHost(host: string) {
    this.mockHostP = host;
  }

  get mockExtension(): string {
    return this.mockExtensionP;
  }
  set mockExtension(ext: string) {
    this.mockExtensionP = ext;
  }

  get isMock(): boolean {
    return this.isMockP;
  }
  set isMock(b: boolean) {
    this.isMockP = b;
  }

  /**
   * Generate the HttpParams
   *
   * @protected
   * @param {(Object | JSON)} data  the data to pass in parameters
   * @param {HttpParams} [params] existing HttpParams if needed
   * @returns {HttpParams}
   * @memberof WebServicesAbstract
   */
  protected generateParameters(
    data: object | JSON,
    params?: HttpParams
  ): HttpParams {
    let result = params || new HttpParams();
    if (!data) {
      return result;
    }

    const toIterate =
      data instanceof Object
        ? Object.keys(data)
        : Object.keys(this.utils.deepCopy(data));

    toIterate.forEach((key) => {
      if (data[key]) {
        if (data[key].forEach) {
          data[key].forEach((element) => {
            if (!key.includes('[]')) {
              result = result.append(key + '[]', element);
            } else {
              result = result.append(key, element);
            }
          });
        } else {
          result = result.append(key, data[key]);
        }
      }
    });

    return result;
  }

  /**
   * Generate the HttpHeaders
   *
   * @private
   * @param {string} contentType from the object CONTENT_TYPES
   * @param {HttpHeaders} [headers] existing HttpHeaders if needed
   * @returns {HttpHeaders}
   * @memberof WebServicesAbstract
   */
  protected generateHeaders(
    contentType: string,
    headers?: HttpHeaders
  ): HttpHeaders {
    let result = headers || new HttpHeaders();

    if (contentType) {
      result = result.append('Content-Type', contentType);
    }

    return result;
  }

  /**
   * Génère en interne la requete a envoyer au serveur
   *
   * @protected
   * @param {string} service API endpoint
   * @param {string} method POST, GET...
   * @param {(Object | JSON)} data the data to pass in parameters
   * @param {HttpParams} params existing params if needed
   * @param {boolean} needAuthentification is authentification needed
   * @param {HttpHeaders} headers existing headers if needed
   * @param {('arraybuffer' | 'blob' | 'json' | 'text')} responseType what type of response do we expect
   * @returns {HttpRequest<any>}
   * @memberof WebServicesAbstract
   */
  protected generateRequest(
    service: string,
    method: string,
    data: object | JSON,
    params: HttpParams,
    isAbsolute: boolean,
    needAuthentification: boolean,
    headers: HttpHeaders,
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text',
    isMock: boolean = false
  ): HttpRequest<any> {
    if (!isMock) {
      isMock = this.isMockP;
    }
    let finalService = this.hostP + service;
    if (isMock) {
      if (params && params.get('page')) {
        finalService =
          this.mockHostP +
          service +
          'page=' +
          params.get('page') +
          this.mockExtensionP;
      } else {
        finalService = this.mockHostP + service + this.mockExtensionP;
      }
      finalService = finalService.replace('?', '');

      params = new HttpParams();

      method = Http_Methods.GET;
    }
    if (isAbsolute) {
      finalService = service;
    }

    const request: HttpRequest<any> = new HttpRequest<any>(
      method, // La méthode utilisée pour l'envoi des données
      finalService, // L'url du service
      data, // The body / Les paramètres à envoyer
      {
        headers,
        reportProgress: false,
        params, // Envoi toutes les données sous la forme de paramètres
        responseType, // Le type de réponse souhaité : json
        withCredentials: needAuthentification,
      }
    );

    return request;
  }

  /**
   * Send the request to the API endpoint
   * @param {string} service API endpoint
   * @param {string} method POST, GET...
   * @param {(Object | JSON)} [data=null] the data to pass in parameters
   * @param {HttpParams} [params=null] existing params if needed
   * @param {boolean} [needAuthentification=false] is authentification needed
   * @param {HttpHeaders} [headers=null] existing headers if needed
   * @param {string} [contentType=null] value of contentType
   * @param {('arraybuffer' | 'blob' | 'json' | 'text')} [responseType='json'] what type of response do we expect
   * @returns {Promise<any>}
   * @memberof WebServicesAbstract
   */
  public launchRequest(
    service: string,
    method: string,
    data: object | JSON = null,
    params: HttpParams = null,
    isAbsolute: boolean,
    needAuthentification: boolean = false,
    headers: HttpHeaders = null,
    contentType: string = null,
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json',
    isMock: boolean = false
  ): Observable<Response> {
    // Parameters construction
    if (data !== null) {
      params = params || new HttpParams(); // this.generateParameters( data, params );
    }

    // Header construction
    // headers = this.generateHeaders( contentType || Content_Types.APPLICATION_JSON, headers );
    headers = this.generateHeaders(contentType, headers);

    let httpPromise: Observable<any>;

    const request = this.generateRequest(
      service,
      method,
      data,
      params,
      isAbsolute,
      needAuthentification,
      headers,
      responseType,
      isMock
    );

    httpPromise = this.http.request<Response>(this.generateRequestS(request));

    return httpPromise;
  }

  /**
   * Generate a request with a s token to encrypt front-back transmissions
   *
   * @private
   * @param {HttpRequest<any>} requestOri
   * @returns {HttpRequest<any>}
   * @memberof AbstractWebServices
   */
  private generateRequestS(requestOri: HttpRequest<any>): HttpRequest<any> {
    const startUrl = requestOri.urlWithParams;
    const finalAuth = this.generateAuth(
      requestOri.urlWithParams,
      requestOri.body
    );

    const finalRequest = this.generateRequest(
      startUrl.split('/api/')[1],
      Http_Methods.GET,
      null,
      null,
      false,
      false,
      new HttpHeaders({
        AUTH: finalAuth,
      }),
      'json',
      false
    );

    return finalRequest;
  }

  /**
   * Generate the string for the Auth parameter
   *
   * @private
   * @param {string} fullUrl
   * @param {*} [body]
   * @returns {string}
   * @memberof AbstractWebServices
   */
  protected generateAuth(fullUrl: string, body?: any): string {
    const startUrl = fullUrl;
    const finalUrl =
      environment.share_secret + '/api/' + startUrl.split('/api/')[1];
    const finalBody: any = {
      tokenUri: sha512.sha512(finalUrl),
    };

    if (body) {
      finalBody.tokenParams = sha512.sha512(body);
    }

    return btoa(JSON.stringify(finalBody));
  }

  /**
   * Permet de télécharger des fichiers
   * @param url            url de téléchargement
   * @param contentType    content type du fichier à télécharger
   * @param responseType   type de transfert, normalement blob
   */
  public getFileFromURL(
    url: string,
    contentType: string,
    responseType: any = 'blob'
  ): Observable<any> {
    const headers = new HttpHeaders().append('Accept', contentType);
    return this.launchRequest(
      url,
      Http_Methods.GET,
      null,
      null,
      null,
      null,
      headers,
      contentType,
      responseType
    );
  }
}
