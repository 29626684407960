<div *ngIf="isLoadingLegal;else gridLegal" class="loading-legal">
  <ion-spinner color="primary"></ion-spinner>
  <span>{{'LEGAL_NOTICES.LOADING'| translate}}</span>
</div>

<ng-template #gridLegal>
  <ion-grid>
    <ion-row size="12" class="container">
      <ion-col class="main-content">

        <div class="title">
          {{'LEGAL_NOTICES.TITLE' | translate}}
        </div>

        <div class="content" [innerHTML]="content"></div>

        <div class="buttons">
          <ion-item>
            <ion-checkbox [(ngModel)]="termsAccepted" color="primary" slot="start"></ion-checkbox>
            <ion-label>{{'LEGAL_NOTICES.TERMS' | translate}}</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox [(ngModel)]="confidentialityAccepted" color="primary" slot="start"></ion-checkbox>
            <ion-label>{{'LEGAL_NOTICES.CONFIDENTIALITY' | translate}}</ion-label>
          </ion-item>

          <ion-button [disabled]="!termsAccepted || !confidentialityAccepted" color="primary" (click)="accepted()">
            {{'LEGAL_NOTICES.ACCEPT' | translate}}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>