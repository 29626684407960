import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';
import { WebServices } from '../webservices/web-services.service';
import { IAlert } from 'src/models/interfaces/alert';
import { DatabaseService } from '../database/database.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable( {
  providedIn: 'root'
} )
export class NotificationsService {

  private intervalCounter: NodeJS.Timer;

  private alertsBS: BehaviorSubject<IAlert[]> = new BehaviorSubject<IAlert[]>( [] );
  get alerts(): Observable<IAlert[]> {
    return this.alertsBS.asObservable();
  }
  private unreadAlertsBS: BehaviorSubject<number> = new BehaviorSubject<number>( 0 );
  get unreadAlerts(): Observable<number> {
    return this.unreadAlertsBS.asObservable();
  }

  constructor( private webServices: WebServices, private network: NetworkService, private database: DatabaseService ) {
    this.database.getAlerts().then( ( alerts: IAlert[] ) => {
      this.dispatchAlerts( alerts );
      this.deleteOldNotifications();
      this.startPeriodicityCounter();
    },
      () => {
        this.deleteOldNotifications();
        this.startPeriodicityCounter();
      }
    );
  }

  /**
  * Récupèration du nombre de notification de façon périodique
  * (permet de récupérer le nombre de notification même si les push notifications sont désactivées)
  *
  * @private
  * @memberof NotificationsService
  */
  private startPeriodicityCounter(): void {
    const onGetNotificationCount = () => {
      this.onGetNotificationCount();
    };
    this.intervalCounter = setInterval( onGetNotificationCount, environment.notifications_interval * 1000 );
    this.onGetNotificationCount();
  }

  /**
   * Called when an update of the notificaiton number is asked
   *
   * @memberof NotificationsService
   */
  public onGetNotificationCount(): void {
    if ( !this.network.isConnected ) { return; }
    this.webServices.getNotifications()
      .subscribe( data => {
        this.updateAlerts( data );
        this.recoverAlerts();
      } );
  }

  private dispatchAlerts( alerts: IAlert[] ) {
    try {
      alerts.sort( ( a, b ) => {
        return new Date( a.startDate ).getTime() - new Date( b.startDate ).getTime() == 0
          ? new Date( b.endDate ).getTime() - new Date( a.endDate ).getTime() :
          new Date( b.startDate ).getTime() - new Date( a.startDate ).getTime()
      } );
    } catch ( e ) { }
    this.alertsBS.next( alerts );
  }

  /**
   * Recover the alerts from the database
   *
   * @private
   * @memberof NotificationsService
   */
  private recoverAlerts(): void {
    this.database.getAlerts().then(
      ( alerts: IAlert[] ) => {
        let unread = 0;
        alerts.forEach( ( a: IAlert ) => {
          if ( !a.opened ) { unread++; }
        } );
        this.unreadAlertsBS.next( unread );
        this.dispatchAlerts( alerts );
      },
      () => {
        this.unreadAlertsBS.next( 0 );
        this.dispatchAlerts( [] );
      }
    );
  }

  /**
  * Delete all the old notifications at the app start
  *
  * @private
  * @memberof NotificationsProvider
  */
  private deleteOldNotifications(): void {
    const now = new Date().getTime();
    // Verify if not equal to 1st january 2013 (meaning no end date on the back (?))
    const defaultDate = new Date( '2014-01-01T00:00:00+00:00' ).getTime();
    this.database.getAlerts().then( alerts => {
      alerts.forEach( alert => {
        if ( new Date( alert.endDate ).getTime() < now && new Date( alert.endDate ).getTime() > defaultDate ) {
          this.database.removeAlert( Number( alert.id ) );
        }
      } );
    } );
  }

  /**
   * Compare the DB with the new alerts
   * @private
   * @param {IAlert[]} alerts
   * @param {IAlert[]} toReturn
   * @returns {Promise<any>}
   * @memberof NotificationsProvider
   */
  private updateAlerts( alerts: IAlert[] ): void {
    if ( !alerts ) { return; }

    const oldAlertsId = [];

    const oldAlerts = this.alertsBS.getValue();
    oldAlerts.forEach( ( a: IAlert ) => {
      oldAlertsId.push( a.id );
    } );

    alerts.forEach( ( newAlert: IAlert ) => {
      const index = oldAlertsId.indexOf( newAlert.id );
      if ( index !== -1 ) {
        oldAlertsId.splice( index, 1 );
        oldAlerts.forEach( ( oA: IAlert ) => {
          if ( oA.id === newAlert.id ) {
            newAlert.opened = oA.opened;
          }
        } );
      } else {
        newAlert.opened = false;
      }
      this.database.putAlert( newAlert );
    } );

    oldAlertsId.forEach( ( id: string ) => {
      this.database.removeAlert( parseInt( id ) );
    } );
  }

  public markReadAlert( alert: IAlert, read: boolean = true ): void {
    if ( alert.opened === read ) { return; }
    alert.opened = read;

    this.database.putAlert( alert ).then( () => this.recoverAlerts() );
  }
}
