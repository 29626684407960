import { Component, ViewChild, Input, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { MapService } from 'src/app/services/map/map.service';
import { IonInput, IonItem } from '@ionic/angular';
import { IVac } from 'src/models/interfaces/vac';
import { GeolocationService } from 'src/app/services/geolocation/geolocation.service';
import { Desubscribe } from 'src/models/classes/desubscribe';
import { VACS_TYPES } from 'src/models/enums';
import { FilterByPipe } from 'src/app/pipes/filter-by/filter-by.pipe';
import { CacheService } from 'src/app/services/cache/cache.service';
import { Observable, Subscription } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Keyboard } from '@ionic-native/keyboard';

@Component( {
  selector: 'search-vac',
  templateUrl: './search-vac.component.html',
  styleUrls: [ './search-vac.component.scss' ],
} )
export class SearchVacComponent extends Desubscribe {
  @ViewChild( 'searchVacContainer' ) searchContainer: ElementRef;
  @ViewChild( 'resultContainer' ) resultContainer: ElementRef;
  @ViewChild( 'theInput' ) theInput;
  @ViewChild( 'theResultList' ) theResultList: CdkVirtualScrollViewport;

  listResultHeight = '0px';
  inputIsFocused = false;
  isExpanded = false;
  ionItemInput: any;
  @ViewChild( IonItem ) set setIonItemInput( i: any ) {
    this.ionItemInput = i;
  }
  searchTerm = '';
  authorizedTypesP = [];
  @Input() set authorizedTypes( a: VACS_TYPES[] ) {
    this.authorizedTypesP = a;
    this.changeText();
  }
  @Input() events: Observable<void>;

  vacsAll: IVac[];
  vacsToSearch: IVac[];
  private eventsSubscription: Subscription;
  geolocActive: boolean;

  constructor( private mapService: MapService, private cache: CacheService, private geolocation: GeolocationService,
    private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef, private backgroundMode: BackgroundMode,
  ) {
    super();
  }

  ngOnInit(): void {
    this.vacsAll = this.cache.getVacs();
    this.vacsToSearch = this.vacsAll;
    this.changeText();
    this.eventsSubscription = this.events.subscribe( () => this.hideSearch() );

    this.addSubscription( this.geolocation.listenToActivation().subscribe( ( active: boolean ) => {
      this.geolocActive = active;
    } ) );

    this.backgroundMode.enable();
    this.backgroundMode.on( 'deactivate' ).subscribe( () => { this.hideSearch(); } );
  }

  hideSearch(): void {
    if ( this.isExpanded ) {
      // console.log('hideSearch');
      this.listResultHeight = '0px';
      this.searchTerm = '';
      this.theResultList.scrollToIndex( 0 );
      this.renderer.removeClass( this.searchContainer.nativeElement, 'search-vac-container-expanded' );

      this.inputIsFocused = false;

      Keyboard.hide();

      this.isExpanded = false;
    }
  }

  onBlurInput(): void {
    if ( this.isExpanded ) {
      // console.log('onBlur');
      this.listResultHeight = '0px';
      this.renderer.removeClass( this.searchContainer.nativeElement, 'search-vac-container-expanded' );
      this.inputIsFocused = false;
      this.isExpanded = false;
    }
  }

  focusUserLocation(): void {
    this.mapService.focusUserLocation();
  }

  goToVac( vac: IVac ): void {
    // console.log('gotoVac' + vac.oaci);
    this.onClickIcon();
    this.searchTerm = '';
    this.theResultList.scrollToIndex( 0 );
    this.mapService.focusVac( vac );
  }

  trackByID( index: number, vac: IVac ): string {
    // console.log('track : ' + vac.oaci);
    // return vac.oaci;
    return vac.oaci;
  }

  onClickIcon(): void {
    // console.log('onclickIcon');
    if ( !this.isExpanded ) {
      this.renderer.addClass( this.searchContainer.nativeElement, 'search-vac-container-expanded' );
      this.isExpanded = true;
      this.listResultHeight = this.calculateContainerHeight();

      this.theInput.setFocus();
      this.inputIsFocused = true;
    } else {
      this.hideSearch();
    }
  }

  // HACK: virtual scroll not working with pipes transforming array, virtual scroll bad, bad virtual scroll. So directly on the controller
  changeText(): void {
    // console.log('changeText');
    if ( !this.vacsAll ) { return; }
    this.vacsToSearch = new FilterByPipe().transform(
      new FilterByPipe().transform( this.vacsAll, this.searchTerm, [ 'city', 'oaci' ] ),
      this.authorizedTypesP, [ 'type' ] );
    this.listResultHeight = this.calculateContainerHeight();
    this.changeDetectorRef.detectChanges();
  }

  calculateContainerHeight(): string {
    if ( !this.isExpanded ) {
      return '0px';
    }
    const numberOfItems = this.vacsToSearch.length;
    // This should be the height of your item in pixels
    const itemHeight = 35;
    // The final number of items you want to keep visible
    const visibleItems = 5;

    // It calculates the container height for the first items in the list
    // It means the container will expand until it reaches `200px` (20 * 10)
    // and will keep this size.
    if ( numberOfItems <= visibleItems ) {
      return `${ itemHeight * numberOfItems }px`;
    }

    // This function is called from the template so it ensures the container will have
    // the final height if number of items are greater than the value in "visibleItems".
    return `${ itemHeight * visibleItems }px`;
  }
}
