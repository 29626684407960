import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-progress-popup',
  templateUrl: './progress-popup.component.html',
  styleUrls: ['./progress-popup.component.scss'],
})
export class ProgressPopupComponent {
  label: Observable<string>;
  message: Observable<string>;
  progress: Observable<number>;
  showButton: Observable<boolean>;

  constructor(private modalCtrl: ModalController) {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
