import { AfterViewInit, Output, EventEmitter, Directive } from '@angular/core';
@Directive()
export class AppearMenuComponent implements AfterViewInit {

  @Output() closeMenu = new EventEmitter<void>();
  @Output() filtersUpdated = new EventEmitter<any>();

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit(): void {
    // HACK: CSS slider issue with size
    this.manualRefresh.emit();
  }

  closeFilters(): void {
    this.closeMenu.emit();
  }

}
