import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { IAlert } from 'src/models/interfaces/alert';

@Component( {
  selector: 'app-informations',
  templateUrl: './informations.page.html',
  styleUrls: [ './informations.page.scss' ],
} )
export class InformationsPage implements AfterViewInit {
  @ViewChild( 'ionSlider' ) slides: IonSlides;

  alerts: IAlert[] = [];
  selectedAlert: IAlert;

  idToFocus: string;

  constructor( private modalCtrl: ModalController, private notifications: NotificationsService ) {
    this.notifications.alerts.subscribe( ( alerts: IAlert[] ) => this.alerts = alerts );
  }

  ngAfterViewInit(): void {
    if ( this.idToFocus ) {
      this.alerts.forEach( ( a: IAlert ) => {
        if ( this.idToFocus === a.id ) {
          this.openAlert( a );
        }
      } );
    }
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  slideChanged() {
    const indx = this.slides.getActiveIndex();
    indx.then( value => {
      this.openAlert( this.alerts[ value ] );
    }
    );
  }

  openAlert( alert: IAlert ): void {
    this.selectedAlert = alert;
    this.notifications.markReadAlert( alert, true );
    alert.opened = true;
  }
}
