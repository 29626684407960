<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon slot="start" name="arrow-back"></ion-icon>
        <ion-label>{{'GENERAL.BACK' | translate}}</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary">
      <ion-icon name="map-outline"></ion-icon>
      {{'MY_MAPS.TITLE' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="get-maps">
      <div class="my-maps-search-{{noUpdate ? 50 : 35}}" *ngIf="!noMaps">
        <ion-item class="filter-input">
          <ion-icon name="search-outline" class="icon-search-outline"></ion-icon>
          <ion-input placeholder="{{'INPUTS.SEARCH.PLACEHOLDER_MY_MAPS' | translate}}" clearInput [ngModel]="searchTerm"
            (ngModelChange)="searchTerm = $event.toLocaleUpperCase()">
          </ion-input>
        </ion-item>
      </div>
      <div class="my-maps-dl-all" *ngIf="!noMaps">
        <ion-button fill="clear" class="dll-container" (click)="downloadAll()" [disabled]="isUpdatingAll">
          <div>
            <span class="dll-all">{{'MY_MAPS.DLL_ALL' | translate:{mo: nbMo} }}</span>
            <br>
            <span class="dll-all-sub">{{'MY_MAPS.DLL_ALL_SUB' | translate }}</span>
          </div>
        </ion-button>
      </div>
      <div class="my-maps-update" *ngIf="!noMaps && !noUpdate">
        <ion-button fill="outline" color="dark" (click)="updateAllF($event)" [disabled]="isUpdatingAll">
          {{'MY_MAPS.UPDATE_ALL' | translate }}
        </ion-button>
      </div>
      <div class="my-maps-delete" *ngIf="!noMaps">
        <ion-button fill="outline" color="danger" (click)="deleteAll($event, true)">
          {{'MY_MAPS.DELETE_ALL' | translate }}
        </ion-button>
      </div>
    </ion-row>

    <br>

    <ion-row class="no-maps default-border" *ngIf="noMaps">
      <div class="no-maps-unit-dl">
        <span fill="clear" class="text">{{'MY_MAPS.NO_MAPS' | translate}}</span>
        <span fill="clear" class="text blue" (click)="close()">
          {{'MY_MAPS.NO_MAPS_2' | translate | lowercase}}
          <ion-icon color="primary" name="information-circle-outline"></ion-icon>
        </span>
        <!--<div class="text">{{'MY_MAPS.NO_MAPS' | translate}}</div>
        <ion-button fill="clear" class="text blue" (click)="close()">{{'MY_MAPS.NO_MAPS_2' | translate | lowercase}}
          <ion-icon color="primary" name="information-circle-outline"></ion-icon>
        </ion-button>-->
      </div>

      <div class="separator"></div>

      <div class="no-maps-full-dl">
        <span fill="clear" class="text blue" (click)="downloadAll()">
          {{'GENERAL.OR'| translate}} {{'MY_MAPS.DLL_ALL' | translate:{mo: nbMo} | lowercase}}
        </span>
        <!--<ion-button fill="clear" class="text blue" (click)="downloadAll()">
          {{'GENERAL.OR'| translate}}
          {{'MY_MAPS.DLL_ALL' | translate:{mo: nbMo} | lowercase}}
        </ion-button>-->
      </div>
    </ion-row>

    <div *ngIf="noMaps" class="no-maps-background">
      {{'MY_MAPS.NO_MAPS_BACKGROUND' | translate}}
    </div>

    <ion-row *ngIf="!noFav" class="default-border">
      <ion-col size="12">
        <div class="toggle-expand" (click)="toggleExpand('fav')">
          <ng-container *ngIf="expandFav; then expandedFav; else collapsedFav"></ng-container>
          <ng-template #expandedFav>
            <ion-icon color="primary" name="caret-up-outline"></ion-icon>
            <span>{{'MY_MAPS.COLLAPSE.FAV' | translate}}</span>
          </ng-template>
          <ng-template #collapsedFav>
            <ion-icon color="primary" name="caret-down-outline"></ion-icon>
            <span>{{'MY_MAPS.EXPAND.FAV' | translate}}</span>
          </ng-template>
          <span class="update-all" fill="clear" (click)="updateAllF($event, true)"
            *ngIf="!(isUpdatingAll || noFavToUpdate)">
            {{'MY_MAPS.UPDATE_ALL' | translate}}
          </span>
        </div>
        <div class="expand-wrapper" [class.collapsed]="!expandFav">
          <cdk-virtual-scroll-viewport [itemSize]="45" [maxBufferPx]="1000" [minBufferPx]="400"
            class="virtual-container" [ngStyle]="{'height.px': heightFav}" [style.max-width.%]="100">
            <ion-item
              *cdkVirtualFor="let vac of favMapsDisplay | attributeContains:['city','oaci']:searchTerm; trackBy: indexTrackFn">
              <map-item [vacItem]="vac"></map-item>
            </ion-item>
          </cdk-virtual-scroll-viewport>
        </div>
      </ion-col>
    </ion-row>

    <br *ngIf="!noFav">

    <ion-row *ngIf="!noDll" class="default-border">
      <ion-col size="12">
        <div class="toggle-expand" (click)="toggleExpand('dll')">
          <ng-container *ngIf="expandDll; then expandedDll; else collapsedDll"></ng-container>
          <ng-template #expandedDll>
            <ion-icon color="primary" name="caret-up-outline"></ion-icon>
            <span>{{'MY_MAPS.COLLAPSE.DLL' | translate}}</span>
          </ng-template>
          <ng-template #collapsedDll>
            <ion-icon color="primary" name="caret-down-outline"></ion-icon>
            <span>{{'MY_MAPS.EXPAND.DLL' | translate}}</span>
          </ng-template>
          <span class="delete-all" (click)="deleteAll($event)">
            {{'MY_MAPS.DELETE_ALL' | translate}}
          </span>
        </div>
        <div class="expand-wrapper" [class.collapsed]="!expandDll">
          <cdk-virtual-scroll-viewport [itemSize]="45" [maxBufferPx]="8000" [minBufferPx]="400"
            class="virtual-container" [ngStyle]="{'height.px': heightDll}" [style.max-width.%]="100">
            <ion-item
              *cdkVirtualFor="let vac of dllMapsDisplay | attributeContains:['city','oaci']:searchTerm;let i = index">
              <map-item [vacItem]="vac"></map-item>
            </ion-item>
          </cdk-virtual-scroll-viewport>
        </div>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>