<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary">
      <div class="vac-type">
        {{(vac.config.type === VACS_TYPES.VAC ? 'GENERAL.AIRPORT' : 'GENERAL.HELIPORT') | translate}}
      </div>
      <div class="vac-name">{{vac.config.city}}</div>
      <div class="vac-oaci">{{vac.config.oaci}}</div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="download-container"
    [ngClass]="{'connected': isConnected, 'not-connected': !isConnected, 'toUpdate': (vac?.config?.map?.downloadedMap && vac.config.map.downloadedMap.version !== vac.config.map.version)}">
    <ion-grid>
      <ion-row *ngIf="!env?.isWeb; else web">
        <ion-col size="6" class="icon-container">
          <ion-col size="4">
            <ion-button fill="clear" (click)="trashOrDll()"
              [disabled]="(!isConnected && !(vac?.config.map.downloadedMap)) || isBeingDll">
              <ng-container *ngIf="!isBeingDll; else beingDll">
                <ion-icon slot="icon-only" name="{{vac?.config.map?.downloadedMap ? 'trash' : 'cloud-download' }}"
                  color="light">
                </ion-icon>
              </ng-container>
              <ng-template #beingDll>
                <ion-spinner></ion-spinner>
              </ng-template>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button fill="clear" (click)="toggleFav()"
              [disabled]="(!isConnected && !(vac?.config.map?.downloadedMap)) || isBeingDll">
              <ion-icon slot="icon-only" name="{{isFavorite ? 'star' : 'star-outline'}}"
                color="{{isFavorite ? 'warning' : 'light'}}"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <div *ngIf="vac?.config?.map?.downloadedMap" style="padding-top: 3px;">
              <ng-container *ngIf="vac.config.map.downloadedMap.version === vac.config.map.version; else notUpToDate">
                <ion-icon class="check-icon" name="checkmark" color="light">
                </ion-icon>
              </ng-container>
              <ng-template #notUpToDate>
                <ion-button fill="clear" (click)="downloadPDF(false,false)" [disabled]="!isConnected || isBeingDll">
                  <ion-icon slot="icon-only" name="refresh" color="light">
                  </ion-icon>
                </ion-button>
              </ng-template>
            </div>
          </ion-col>
        </ion-col>

        <ion-col size="6">
          <div class="button-container">
            <ng-container *ngIf="isConnected || vac?.config.map?.downloadedMap; else noDownloadPdf">
              <ion-button fill="outline" (click)="openPDF()" [disabled]="isBeingDll">
                {{(env?.isWeb ? 'VAC_DETAILS.DOWNLOAD' : 'VAC_DETAILS.OPEN') | translate}}
              </ion-button>
            </ng-container>
            <ng-template #noDownloadPdf>
              <div>
                {{'VAC_DETAILS.NOT_CONNECTED' | translate}}
              </div>
            </ng-template>
          </div>
        </ion-col>
      </ion-row>
      <ng-template #web>
        <ion-row>
          <ion-col size="6" class="icon-container">
            <ion-col size="4" *ngIf="isBeingDll">
              <ion-button fill="clear" disabled=true>
                <ng-container>
                  <ion-spinner></ion-spinner>
                </ng-container>
              </ion-button>
            </ion-col>
          </ion-col>
          <ion-col size="6">
            <div class="button-container">
              <ion-button fill="outline" (click)="downloadPDF()" [disabled]="isBeingDll">
                {{(env?.isWeb ? 'VAC_DETAILS.DOWNLOAD' : 'VAC_DETAILS.OPEN') | translate}}
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ng-template>
    </ion-grid>

  </div>
  <div class="infos-container">
    <ion-list>
      <ion-item *ngIf="vac.infosByLng?.manager">
        <ion-label class="title">{{'VAC_DETAILS.MANAGER' | translate}}</ion-label>
        <ion-label class="value">
          {{vac.infosByLng?.manager}}
        </ion-label>
      </ion-item>

      <ion-list-header *ngIf="vac.config.ground">
        <ion-label class="title">{{'VAC_DETAILS.LOCALISATION' | translate}}</ion-label>
      </ion-list-header>
      <ion-item *ngIf="vac.config.ground">
        <ion-label class="title">{{'VAC_DETAILS.LATITUDE' | translate}}</ion-label>
        <ion-label class="value">{{formatCoord(vac.config.ground.coordsToDisplay.lat, "Lat")}}</ion-label>
      </ion-item>
      <ion-item *ngIf="vac.config.ground">
        <ion-label class="title">{{'VAC_DETAILS.LONGITUDE' | translate}}</ion-label>
        <ion-label class="value">{{formatCoord(vac.config.ground.coordsToDisplay.lon, "Lon")}}</ion-label>
      </ion-item>
      <ion-item *ngIf="vac.config.ground">
        <ion-label class="title">{{'VAC_DETAILS.ALTITUDE' | translate}}</ion-label>
        <ion-label class="value">{{vac.config.ground.elevation}} ft</ion-label>
      </ion-item>
      <ion-list-header *ngIf="vac.longestAirstrip">
        <ion-label class="title">{{'VAC_DETAILS.LONGEST_AIRSTRIP' | translate}}</ion-label>
      </ion-list-header>
      <ion-item *ngIf="vac.longestAirstrip">
        <ion-label class="title">{{'VAC_DETAILS.AIRSTRIP' | translate}}</ion-label>
        <ion-label class="value">{{vac.longestAirstrip.degrees}}</ion-label>
      </ion-item>
      <ion-item *ngIf="vac.longestAirstrip">
        <ion-label class="title">{{'VAC_DETAILS.DIMENSIONS' | translate}}</ion-label>
        <ion-label class="value">{{vac.longestAirstrip.length}} x {{vac.longestAirstrip.width}}</ion-label>
      </ion-item>
      <ion-item *ngIf="vac.longestAirstrip">
        <ion-label class="title">{{'VAC_DETAILS.SURFACE_TYPE' | translate}}</ion-label>
        <ion-label class="value">{{(vac.longestAirstrip.type === 'HARD'? 'GENERAL.HARD':'GENERAL.GRASS') | translate}}
        </ion-label>
      </ion-item>

      <!--
      <ion-list *ngIf="vac.config.frequencies.length > 0">
        <ion-list-header>
          <ion-label class="title">{{'VAC_DETAILS.FREQUENCIES' | translate}}</ion-label>
        </ion-list-header>
        <ion-item *ngIf="vac.config.frequencies[0].APP">
          <ion-label class="title">APP</ion-label>
          <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].APP}}</ion-label>
        </ion-item>
        <ion-item *ngIf="vac.config.frequencies[0].TWR">
          <ion-label class="title">TWR</ion-label>
          <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].TWR}}</ion-label>
        </ion-item>
        <ion-item *ngIf="vac.config.frequencies[0].ATIS">
          <ion-label class="title">ATIS</ion-label>
          <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].ATIS}}</ion-label>
        </ion-item>
        <ion-item *ngIf="vac.config.frequencies[0].FIS">
          <ion-label class="title">FIS</ion-label>
          <ion-label class="value">{{vac.config.oaci}} {{vac.config.frequencies[0].FIS}}</ion-label>
        </ion-item>
      </ion-list>-->



      <ion-list-header>
        <ion-label class="title">{{'VAC_DETAILS.SERVICES' | translate}}</ion-label>
      </ion-list-header>
      <ion-item *ngIf="vac.infosByLng?.fuel">
        <ion-label class="title">
          <img src="./assets/imgs/fuel.png">
        </ion-label>
        <ion-label class="value">{{'VAC_DETAILS.FUEL' | translate}}</ion-label>
      </ion-item>
      <div *ngIf="env?.isDebug">
        <ion-list-header>
          <ion-label class="title">MAPS</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label class="title">Locale :</ion-label>
          <ion-label class="value">{{vac.config.map?.downloadedMap?.version}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="title">Server :</ion-label>
          <ion-label class="value">{{vac.config.map?.version}}</ion-label>
        </ion-item>
        <ion-item *ngIf="vac.config.map?.downloadedMap">
          <ion-button (click)="fakeVersion()">Mark as N-1900-01</ion-button>
        </ion-item>
      </div>
    </ion-list>
  </div>
</ion-content>

<ion-footer>
  <div class="more-infos" (click)="moreInfos()">
    <ion-button fill="clear">{{'VAC_DETAILS.MORE_INFOS' | translate}}</ion-button>
    <ion-icon name="arrow-forward"></ion-icon>
  </div>
</ion-footer>