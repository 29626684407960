<div
  class="search-vac-container search-vac-container-notexpanded"
  #searchVacContainer
>
  <ion-item [ngClass]="{ 'is-focused': inputIsFocused }" style="height: 40px">
    <ion-icon
      name="search-outline"
      class="icon-search-outline"
      (click)="onClickIcon()"
    ></ion-icon>
    <ion-input
      (ionChange)="changeText()"
      (ionBlur)="onBlurInput()"
      [autofocus]="true"
      clearInput
      [ngModel]="searchTerm"
      (ngModelChange)="searchTerm = $event.toLocaleUpperCase()"
      class="searchInput"
      type="email"
      #theInput
    >
    </ion-input>
  </ion-item>

  <div
    class="results"
    [ngClass]="{ 'drop-down': inputIsFocused }"
    [ngStyle]="{ width: ionItemInput?.el.clientWidth + 'px' }"
    [style.height]="this.listResultHeight"
    [style.maxHeight]="this.listResultHeight"
    style="overflow: hidden"
    #resultContainer
  >
    <cdk-virtual-scroll-viewport
      itemSize="32"
      maxBufferPx="420"
      minBufferPx="252"
      style="height: 100%; min-height: 100%; background-color: white"
      #theResultList
    >
      <ion-button
        *cdkVirtualFor="
          let item of vacsToSearch | orderByParam: 'city';
          trackBy: trackByID
        "
        (click)="goToVac(item)"
        style="
          height: 32px;
          max-height: 32px;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        "
      >
        <result-vac
          [vac]="item"
          [searchTerm]="searchTerm"
          style="
            height: 32px;
            max-height: 32px;
            width: 100%;
            z-index: 500001;
            transform: translate3d(0, 0, 0);
          "
        >
        </result-vac>
      </ion-button>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
