import { Injectable } from '@angular/core';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { File, FileError, RemoveResult } from '@ionic-native/file/ngx';
import { AlertManagerService } from '../alert-manager/alert-manager.service';
import { FileService } from '../file/file.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';

declare let cordova: any;

@Injectable( {
  providedIn: 'root',
} )
export class PdfManagerService {
  constructor(
    private documentViewer: DocumentViewer,
    private file: File,
    private fileService: FileService,
    private alerts: AlertManagerService,
    private statusBar: StatusBar,
  ) { }

  /**
   * Open a pdf using the full path (for temp folder) or the document path (changed every app update)
   *
   * @param {{
   *     filePath?: string,
   *     fileName?: string,
   *     nameToDisplay: string
   *   }} params
   * @memberof PdfManagerService
   */
  openPdf( params: {
    filePath?: string;
    fileName?: string;
    nameToDisplay: string;
  } ): void {
    const url = params.filePath
      ? params.filePath
      : this.fileService.dataDirectory + params.fileName;
    // console.log( 'openPdf ??' );
    if ( window.hasOwnProperty( 'cordova' ) ) {
      // console.log('cordova open pdf : ' + url);
      cordova.plugins.fileOpener2.open( url, 'application/pdf', {
        error: () => {
          // console.log('error : ');
          this.alerts.basicError(
            'ALERTS.PDF.READER.SUB_TITLE',
            'ALERTS.PDF.READER.MESSAGE'
          );
        },
        success: () => {
          // console.log('ok');
          this.statusBar.backgroundColorByHexString( '#004e94' );
          this.statusBar.hide();
          this.statusBar.show();
        },
      } );
    } else {
      // console.log( 'desktop' );
      this.documentViewer.viewDocument(
        url,
        'application/pdf',
        {
          title: params.nameToDisplay,
          print: {
            enabled: true
          }, email: {
            enabled: true
          }
        },
        () => { },
        () => { },
        () => { },
        () => {
          this.alerts.basicError(
            'ALERTS.PDF.OPEN.SUB_TITLE',
            'ALERTS.PDF.OPEN.MESSAGE'
          );
        }
      );
    }
  }

  removePdf( folderName: string, fileName: string ): Promise<any> {
    const result = new Promise<any>( ( resolve, reject ) => {
      this.file.removeFile( folderName, fileName ).then(
        ( s: RemoveResult ) => {
          resolve( s );
        },
        ( e: FileError ) => {
          // 1: NOT_FOUND_ERROR: on considère que c'est un success
          // 11: TYPE_MISMATCH_ERR
          if ( e.code === 1 || e.code === 11 ) {
            resolve( e );
          } else {
            reject( e );
          }
        }
      );
    } );
    return result;
  }
}
