import { Component } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { WebServices } from 'src/app/services/webservices/web-services.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { VacDetailsComponent } from '../vac-details.component';
import { TranslateService } from '@ngx-translate/core';
import { PdfManagerService } from 'src/app/services/pdf-manager/pdf-manager.service';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { CacheService } from 'src/app/services/cache/cache.service';
import { UtilsService } from '../../../services/utils/utils.service';

@Component( {
  selector: 'app-vac-details-popin',
  templateUrl: './vac-details-popin.component.html',
  styleUrls: [ './vac-details-popin.component.scss' ],
} )
export class VacDetailsPopinComponent extends VacDetailsComponent {

  constructor(
    protected pdfManager: PdfManagerService,
    protected loader: LoadingController,
    protected modalCtrl: ModalController,
    protected network: NetworkService,
    protected translate: TranslateService,
    protected webServices: WebServices,
    protected myMaps: MyMapsService,
    protected alerts: AlertManagerService,
    protected cache: CacheService,
    protected utils: UtilsService
  ) {
    super( pdfManager, loader, modalCtrl, network, translate, webServices, myMaps, alerts, cache, utils );
  }

  moreInfos(): void {
    this.modalCtrl.dismiss( this.vac );
  }

  fakeVersion(): void {
    this.vac.config.map.downloadedMap.version = 'N-1900-01';
  }
}
