import * as L from 'leaflet';
import { ICoords } from './ground';

export interface IIconLeafOption {
    icon: L.Icon;
    opacity?: number;
    rotationAngle?: number;
}

export interface IPopupLeafOption {
    content: string;
    closeButton?: boolean;
    closeOnClick?: boolean;
    autoClose?: boolean;
    className?: string;
    autoPan: boolean;
    offset?: L.Point;
}

export interface IZoomInfos {
    imperial: {
        value: string,
        width: number
    };
    metric: {
        value: string,
        width: number
    };
}

export interface IRTBAPolylineOptions {
    fillColor: string;
    color: string;
    fillOpacity: number;
}

export enum Direction {
    right = "right",
    left = "left",
    top = "top",
    bottom = "bottom",
    center = "center",
    auto = "auto"
}

export interface Label {
    text: string;
    direction: Direction;
    position?: ICoords;
    cssClass?: string[];
}