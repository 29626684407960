import { Inject, Injectable } from '@angular/core';
import * as L from 'leaflet';
import { ICoords } from 'src/models/interfaces/ground';
import { IZoomInfos } from 'src/models/interfaces/leaf';
import { GeolocationService } from '../geolocation/geolocation.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { IVac, IFilters, key, keyToString } from 'src/models/interfaces/vac';
import {
  VACS_TYPES,
  POINTS_TYPE,
  ZONES_TYPE,
  GROUND_TYPES,
  MAP_TYPES,
} from 'src/models/enums';
import { LExtended as LExt } from 'geoportal-extensions-leaflet';
import { IRunway } from 'src/models/interfaces/runway';
import { NetworkService } from '../network/network.service';
import { ModalManagerService } from '../modal-manager/modal-manager.service';
import { Vac } from 'src/models/classes/vac';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet-edgebuffer';
import 'leaflet-rotatedmarker';
import 'node_modules/leaflet.nauticscale/dist/leaflet.nauticscale.min.js';
import { TranslateService } from '@ngx-translate/core';
import { ZonesService } from './zones/zones.service';
import { MyMapsService } from '../my-maps/my-maps.service';
import { LeafletUtilsService } from '../leaflet-utils/leaflet-utils.service';
import { MarkerLeafOption } from 'src/models/types';
import { PointsService } from './points/points.service';
import { UtilsService } from '../utils/utils.service';
import { environment } from 'src/environments/environment';
import { Zone } from 'src/models/classes/zone';
import * as log from 'geoportal-extensions-leaflet/node_modules/loglevel';
import { CacheService } from '../cache/cache.service';
import { IMapType } from 'src/models/interfaces/map';
import { StorageService } from '../storage/storage.service';
import { SqliteService } from '../sqlite/sqlite.service';
import { TilesService } from '../tiles/tiles.service';
import { ITilesZone } from 'src/models/interfaces/tiles-zone';
import { IPointObs } from 'src/models/interfaces/points';
import { DOCUMENT } from '@angular/common';

export default class MBTiles extends L.TileLayer {
  // db: SQLitePlugin
  mbTilesDB: SqliteService = null;

  constructor( db: SqliteService, options?: L.TileLayerOptions ) {
    super( '', options );
    this.mbTilesDB = db;
  }

  createTile( coords: L.Coords, done: L.DoneCallback ): HTMLElement {
    const tile = document.createElement( 'img' );

    L.DomEvent.on( tile, 'load', () => done( null, tile ) );
    L.DomEvent.on( tile, 'error', () => done( null, tile ) );

    if ( this.options.crossOrigin || this.options.crossOrigin === '' ) {
      tile.crossOrigin =
        this.options.crossOrigin === true ? '' : this.options.crossOrigin;
    }

    /*
     Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
     http://www.w3.org/TR/WCAG20-TECHS/H67
    */
    tile.alt = '';

    /*
     Set role="presentation" to force screen readers to ignore this
     https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
    */
    tile.setAttribute( 'role', 'presentation' );

    this.getTileUrlIn( coords, tile );

    return tile;
  }

  async getTileUrlIn( tilePoint: any, tile: any ) {
    const z = this._getZoomForUrl();
    const x = tilePoint.x;
    const y = tilePoint.y;
    const base64Prefix = 'data:image/png;base64,';

    if ( this.mbTilesDB ) {
      const data = await this.mbTilesDB.getImageData( x, y, z );
      if ( data ) { tile.src = base64Prefix + data; } else { tile.src = ''; }
    }
  }
}
@Injectable( {
  providedIn: 'root',
} )
export class MapService {
  get maps(): { [ index: string ]: IMapType } {
    return this.mapsP;
  }

  get selectedMap(): MAP_TYPES {
    return this.selectedMapP;
  }

  set selectedMap( mapType: MAP_TYPES ) {
    if ( !this.map ) { return; }
    if ( mapType !== this.selectedMapP ) {
      this.map.removeLayer( this.mapsP[ this.selectedMapP ].layer );
      this.selectedMapP = mapType;
      this.map.addLayer( this.mapsP[ this.selectedMapP ].layer );
    }
  }

  get zoomChanged(): Observable<IZoomInfos> {
    return this.onZoomChanged.asObservable();
  }

  constructor(
    private geolocation: GeolocationService,
    private cache: CacheService,
    private network: NetworkService,
    private modal: ModalManagerService,
    private translate: TranslateService,
    private zonesService: ZonesService,
    private readonly myMaps: MyMapsService,
    private utils: UtilsService,
    private leafUtils: LeafletUtilsService,
    private pointsService: PointsService,
    private storage: StorageService,
    private readonly sqliteService: SqliteService,
    private readonly tilesService: TilesService,
    @Inject( DOCUMENT ) private _document: HTMLDocument
  ) {
    this.pointsService.setMapService( this );
    this.mapsP[ MAP_TYPES.CLASSIC ] = {
      type: MAP_TYPES.CLASSIC,
      icon: '',
      label: 'MAPS_MENU.CLASSIC',
      layer: LExt.tileLayer( 'https://wxs.ign.fr/' + MapService.API_KEY + '/geoportail/wmts?' +
        '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM' +
        '&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&STYLE=normal&FORMAT=image/png' +
        '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}',
        {
          // ignApiKey: MapService.API_KEY,
          // ignLayer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
          // style: 'normal',
          // format: 'image/png',
          // service: 'WMTS',
          attribution: '&copy IGN',
          updateWhenIdle: true,
          updateWhenZooming: false,
          keepBuffer: 10
        } )
    };

    this.mapsP[ MAP_TYPES.SATELLITE ] = {
      type: MAP_TYPES.SATELLITE,
      icon: '',
      label: 'MAPS_MENU.SATELLITE',
      layer: LExt.tileLayer( 'https://wxs.ign.fr/' + MapService.API_KEY + '/geoportail/wmts?' +
        '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM' +
        '&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&STYLE=normal&FORMAT=image/jpeg' +
        '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}',
        {
          // ignApiKey: MapService.API_KEY,
          // ignLayer: 'ORTHOIMAGERY.ORTHOPHOTOS',
          // style: 'normal',
          // format: 'image/jpeg',
          // service: 'WMTS',
          attribution: '&copy IGN',
          updateWhenIdle: true,
          updateWhenZooming: false,
          keepBuffer: 10
        } )
    };

    /* En attendant activation sur validation des fond de carte souhaité*/
    this.mapsP[ MAP_TYPES.OSM ] = {
      type: MAP_TYPES.OSM,
      icon: '',
      label: 'MAPS_MENU.OSM',
      layer: L.tileLayer( 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: MapService.MAX_ZOOM.ONLINE,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        updateWhenIdle: true,
        updateWhenZooming: false,
        keepBuffer: 10
      } ),
    };
    /*
            this.mapsP[ MAP_TYPES.TERRAIN ] = {
              type: MAP_TYPES.TERRAIN,
              icon: '',
              label: 'MAPS_MENU.TERRAIN',
              layer: L.tileLayer( 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg', {
                maxZoom: MapService.MAX_ZOOM.ONLINE,
                subdomains: [ 'a', 'b', 'c', 'd' ],
                attribution: '&copy; Map tiles by Stamen Design, under CC BY 3.0. Data by OpenStreetMap, under ODbL'
              } ),
            };*/


    this.disableLog();
    this.generateCluster();

    this.tilesService.zonesAvailable$.subscribe( ( zones ) =>
      this.openSqliteDatabase( zones )
    );

    this.myMaps.authorizedTypes.subscribe( ( authTypes: VACS_TYPES[] ) => {
      // console.log( 'Observable: Authorized updated' );
      this.airportsVisible = authTypes.includes( VACS_TYPES.VAC );
      this.heliportsVisible = authTypes.includes( VACS_TYPES.HEL );
    } );

    // Refresh the markers with the dll information
    this.myMaps.lastChange.subscribe( ( lastChange: { key: key; vac: IVac } ) => {
      if ( lastChange ) {
        const marker = this.markers.get( lastChange.key );
        if ( marker ) {
          if (
            marker.getPopup() &&
            marker.getPopup().getPane() &&
            marker.getPopup().getPane().classList
          ) {
            if ( lastChange.vac ) {
              marker.getPopup().getPane().classList.add( 'dll-blue' );
            } else {
              marker.getPopup().getPane().classList.remove( 'dll-blue' );
            }
          }
        }
      }
    } );

    this.listenToConnection();
  }
  set airportsVisible( b: boolean ) {
    this.airportsActivated = b;
    this.populateMapWithBoundingBox();
  }
  get airportsVisible(): boolean {
    return this.airportsActivated;
  }
  set heliportsVisible( b: boolean ) {
    this.heliportsActivated = b;
    this.populateMapWithBoundingBox();
  }
  get heliportsVisible(): boolean {
    return this.heliportsActivated;
  }
  set filters( f: IFilters ) {
    this.filtersP = f;
    this.populateMapWithBoundingBox();
  }
  static API_KEY = environment.ign_api_key;
  static API_MAP_TYPE = environment.ign_api_map_type;
  static USER_AGENT = environment.ign_api_user_agent;
  static USER_ID = 'userID' as any as key;
  static DEFAULT_ZOOM = {
    ONLINE: 10,
    OFFLINE: 11,
    WEB: 6,
    IPAD: 8,
  };

  static THREE_NM_ZOOM = 11;
  static FRANCE_ZOOM = 6;
  static MIN_ZOOM = 2;
  static MAX_ZOOM = {
    ONLINE: 18,
    OFFLINE: 12,
  };
  static ZOOM_DELTA = {
    ONLINE: 1,
    OFFLINE: 1,
  };
  static LOW_OPACITY = 0.25;
  static DISABLE_CLUSTERING_UNDER = 9;
  static DISABLE_CLUSTERING_UNDER_BY_ZONE = {
    RTBA_LABEL: 6,
    RTBA: 5,
    FIR: 5,
    SIV: 6,
    TMA: 7,
    CTR: 8,
    ZONES_R: 7,
    ZONES_D: 7,
    ZONES_P: 7,
  };
  static DISABLE_CLUSTERING_UNDER_BY_POINT = {
    VFR: 10,
    VOR: 10,
    VOR_DME: 10,
    OBS: 10, // le zoom level, on requiert 3 à 5 nautic miles dans le pdf de cahier des charges
  };
  static EXTENDS_COORD_LOADING_BY_ZOOM = {
    5: {
      lat: 2,
      lon: 2,
    },
    6: {
      lat: 1.75,
      lon: 1.75,
    },
    7: {
      lat: 1.5,
      lon: 1.5,
    },
    8: {
      lat: 1.25,
      lon: 1.25,
    },
    9: {
      lat: 1,
      lon: 1,
    },
    10: {
      lat: 0.75,
      lon: 0.75,
    },
    11: {
      lat: 0.5,
      lon: 0.5,
    },
    12: {
      lat: 0.25,
      lon: 0.25,
    },
  };
  private mapsP: { [ index: string ]: IMapType } = {};


  private map: L.Map;
  // Aerodrome and helistations
  private markers: Map<key, L.Marker> = new Map<key, L.Marker>();
  // Points (VFR, VOR, VOR-DME...)
  private points: Map<POINTS_TYPE, Map<string, L.Marker>> = new Map<
    POINTS_TYPE,
    Map<string, L.Marker>
  >();
  // Zones (FIR, CTR, SIV, TMA, Zones RDP...)
  private zones: Map<ZONES_TYPE, Map<string, Zone>> = new Map<
    ZONES_TYPE,
    Map<string, Zone>
  >();

  private scale: L.Control.Scale;
  private populateRunning = false;
  private forceRedraw = false;
  // private fullscreen: L.Control.Fullscreen | undefined;
  private selectedMapP: MAP_TYPES = MAP_TYPES.CLASSIC;

  private onZoomChanged = new Subject<IZoomInfos>();
  private lastZoom: number;

  private filtersP: IFilters;

  private layersP: { zones: ZONES_TYPE[]; points: POINTS_TYPE[] } = {
    zones: [],
    points: [],
  };

  private isConnected = false;
  private geolocSubscription: Subscription;

  private mapLayer: L.Layer;
  private mapCluster: MarkerClusterGroup;
  private zonesClusters: Map<ZONES_TYPE, MarkerClusterGroup> = new Map<
    ZONES_TYPE,
    MarkerClusterGroup
  >();
  private pointsClusters: Map<POINTS_TYPE, MarkerClusterGroup> = new Map<
    POINTS_TYPE,
    MarkerClusterGroup
  >();

  private airportsActivated = true;
  private heliportsActivated = true;

  private focusOnFirstGeloc = false;
  public closeAllCallback: Function;

  setLayers( param: { zones: ZONES_TYPE[]; points: POINTS_TYPE[], callback: any } ) {
    // console.log(JSON.stringify(param.zones));
    if ( param.zones ) { this.layersP.zones = param.zones; }
    if ( param.points ) { this.layersP.points = param.points; }
    this.populateMapWithBoundingBox( 'setLayers' );
    if ( param.callback ) {
      param.callback();
    }
  }

  async openSqliteDatabase( zones: ITilesZone[] = null ): Promise<void> {
    zones = zones ?? this.tilesService.zonesAvailable;
    const found = zones.find( ( elem: ITilesZone ) => elem.selected );

    // console.log( 'BD already axiste:', found );
    if ( found ) {
      return this.sqliteService.init( found.data );
    }
    return;
  }

  /**
   * Listen to connection availabality in order to load local map
   *
   * @private
   * @memberof MapService
   */
  private listenToConnection(): void {
    this.network.checkConnection().subscribe( ( connected: boolean ) => {
      if ( this.isConnected !== connected ) {
        this.isConnected = connected;
        this.generateLayer( true );
      }
    } );
  }

  /**
   * Generate the Leaflet cluster with params
   *
   * @private
   * @returns {void}
   * @memberof MapService
   */
  private generateCluster(): void {
    this.mapCluster = new MarkerClusterGroup( {
      disableClusteringAtZoom: MapService.DISABLE_CLUSTERING_UNDER,
      maxClusterRadius: 150,
      iconCreateFunction( cluster ) {
        const digits = ( cluster.getChildCount() + '' ).length;
        return L.divIcon( {
          html: cluster.getChildCount().toString(),
          className: 'dgac-cluster cluster-icons digits-' + digits,
          iconSize: null,
        } );
      },
    } );

    Object.keys( ZONES_TYPE ).forEach( ( v ) => {
      this.zonesClusters.set(
        v as ZONES_TYPE,
        new MarkerClusterGroup( {
          disableClusteringAtZoom:
            MapService.DISABLE_CLUSTERING_UNDER_BY_ZONE[ v ],
          // maxClusterRadius: 500,
          singleMarkerMode: true,
          iconCreateFunction( cluster ) {
            const digits = ( cluster.getChildCount() + '' ).length;
            let title = '';
            switch ( v ) {
              case ZONES_TYPE.ZONES_R:
                title = 'R';
                break;
              case ZONES_TYPE.ZONES_D:
                title = 'D';
                break;
              case ZONES_TYPE.ZONES_P:
                title = 'P';
                break;
              default:
                title = v;
                break;
            }
            return L.divIcon( {
              html: `<div>${ title }</div><span>${ cluster
                .getChildCount()
                .toString() }</<span>`,
              className: 'dgac-cluster cluster-' + v + ' digits-' + digits,
              iconSize: null,
            } );
          },
        } )
      );
    } );

    Object.keys( POINTS_TYPE ).forEach( ( v ) => {
      if ( POINTS_TYPE.VOR_DME === v ) { return; } // We don't want to display them alone
      this.pointsClusters.set(
        v as POINTS_TYPE,
        new MarkerClusterGroup( {
          disableClusteringAtZoom:
            MapService.DISABLE_CLUSTERING_UNDER_BY_POINT[ v ],
          maxClusterRadius: 150,
          iconCreateFunction( cluster ) {
            const digits = ( cluster.getChildCount() + '' ).length;
            let title = '';
            switch ( v ) {
              case POINTS_TYPE.VFR:
                title = 'VFR';
                break;
              case POINTS_TYPE.VOR:
                title = 'VOR';
                break;
              // case POINTS_TYPE.VOR_DME:
              //   title = 'VOR-DME';
              //   break;
              case POINTS_TYPE.OBS:
                title = 'OBS';
                break;
            }
            return L.divIcon( {
              html: `<div class="small-cluter-name">${ title }</div><span>${ cluster
                .getChildCount()
                .toString() }</<span>`,
              className: 'dgac-cluster cluster-' + v + ' digits-' + digits,
              iconSize: null,
            } );
          },
        } )
      );
    } );
  }

  private disableLog(): void {
    // Disable the geoportail logs from here
    setInterval( () => {
      const loggers = [ 'CommonService', 'layer-event', 'wmts', 'layers' ];

      loggers.forEach( ( name ) => {
        const logger = log.getLogger( name );
        logger.disableAll();
        logger.setDefaultLevel( 'SILENT' );
        logger.setLevel( 'SILENT' );
      } );
    }, 1000 );
  }

  convertFileSrc( url ) {
    if ( !url ) {
      return url;
    }
    if ( url.indexOf( '/' ) === 0 ) {
      return ( window as any ).WEBVIEW_SERVER_URL + '/_app_file_' + url;
    }
    if ( url.indexOf( 'file://' ) === 0 ) {
      return (
        ( window as any ).WEBVIEW_SERVER_URL + url.replace( 'file://', '/_app_file_' )
      );
    }
    if ( url.indexOf( 'content://' ) === 0 ) {
      return (
        ( window as any ).WEBVIEW_SERVER_URL +
        url.replace( 'content:/', '/_app_content_' )
      );
    }
    // console.log( 'convertFileSrc : ' + url );
    return url;
  }

  /**
   * Generate map layer, depending on the connection
   *
   * @param {boolean} [fromConnection=false]
   * @private
   * @memberof MapService
   */
  private async generateLayer( fromConnection: boolean = false ): Promise<void> {
    let layer: L.Layer;

    if ( !this.map ) {
      return;
    }
    if ( this.mapLayer ) {
      this.map.removeLayer( this.mapLayer );
    }
    // Ajout de la couche IGN
    if ( this.isConnected ) {
      layer = this.mapsP[ this.selectedMap ].layer;
    } else {
      await this.openSqliteDatabase();
      layer = new MBTiles( this.sqliteService, { tms: true } );
    }

    this.mapLayer = layer;

    if ( !fromConnection ) { this.focusUserLocation(); }
    this.map.options.zoomDelta = this.isConnected
      ? MapService.ZOOM_DELTA.ONLINE
      : MapService.ZOOM_DELTA.OFFLINE;
    this.map.options.maxZoom = this.isConnected
      ? MapService.MAX_ZOOM.ONLINE
      : MapService.MAX_ZOOM.OFFLINE;
    this.map.addLayer( layer );
  }

  /**
   * Given a HTML id, create the map on the document
   *
   * @param {string} htmlId
   * @memberof MapService
   */
  public generateMap( htmlId: string ): Promise<void> {
    return new Promise( ( resolve, reject ) => {
      const onGeoportalInitialized = async ( response?) => {
        // console.log( 'onGeoportalInitialized' );
        this.map = new L.Map( htmlId, {
          minZoom: MapService.MIN_ZOOM,
          maxZoom: this.isConnected
            ? MapService.MAX_ZOOM.ONLINE
            : MapService.MAX_ZOOM.OFFLINE,
          zoomDelta: this.isConnected
            ? MapService.ZOOM_DELTA.ONLINE
            : MapService.ZOOM_DELTA.OFFLINE,
          zoom: environment.isWeb
            ? MapService.DEFAULT_ZOOM.WEB
            : MapService.DEFAULT_ZOOM.IPAD,
          center: [
            GeolocationService.DEFAULT_COORDS.lat,
            GeolocationService.DEFAULT_COORDS.lon,
          ],
          zoomControl: false,
          worldCopyJump: true
          // renderer: L.canvas(),
        } );
        this.map.attributionControl.setPrefix( 'Leaflet' );

        this.generateLayer();
        this.map.addLayer( this.mapCluster );
        this.zonesClusters.forEach( ( c: MarkerClusterGroup ) => {
          this.map.addLayer( c );
        } );
        this.pointsClusters.forEach( ( c: MarkerClusterGroup ) => {
          this.map.addLayer( c );
        } );

        this.scale = L.control.scalenautic( {
          nautic: true,
          metric: false,
          imperial: false,
          position: 'bottomleft'
        } ).addTo( this.map );

        this.map.on( 'click', () => this.intercloseAllCallback() );
        this.map.on( 'move', () => this.modifyScaleLabel() );
        this.map.on( 'moveend', () => { this.redrawLayers(); } );
        this.map.on( 'resize', () => { this.forceRedraw = true; } );

        const savedFilters = await this.storage.recoverFilters();
        if ( savedFilters && savedFilters.authorizedTypes ) {
          this.airportsActivated = savedFilters.authorizedTypes.includes(
            VACS_TYPES.VAC
          );
          this.heliportsActivated = savedFilters.authorizedTypes.includes(
            VACS_TYPES.HEL
          );
        } else {
          this.airportsActivated = this.heliportsActivated = true;
        }

        this.populateMapWithBoundingBox();

        this.modifyScaleLabel();
        this.focusOnFirstGeloc = true;

        this.geolocSubscription = this.geolocation
          .listenToUserLocation()
          .subscribe( ( c: ICoords ) => {
            if ( c ) {
              this.updateUserLocation( c );
              if ( this.focusOnFirstGeloc ) {
                this.focusUserLocation();
                this.focusOnFirstGeloc = false;
              }
            }
          } );

        resolve();
      };

      setTimeout( () => {
        onGeoportalInitialized();
      } );
    } );
  }

  private redrawLayers(): void {
    this.populateRunning = true;
    this.map = this.map.invalidateSize();
    this.populateMapWithBoundingBox();
  }

  private intercloseAllCallback() {
    this.closeAllCallback();
  }
  public setCloseAllCallback( callback: () => void ) {
    this.closeAllCallback = callback;
    // this.closeAllCallback();
  }

  /**
   * Place the map's view on coords with give zoom
   *
   * @param {ICoords} coords
   * @param {number} zoom
   * @memberof MapService
   */
  public setView( coords: ICoords, zoom?: number ): void {
    if ( !coords || !this.map ) {
      return;
    }
    this.map.setView(
      [ coords.lat, coords.lon ],
      zoom ||
      ( environment.isWeb
        ? MapService.DEFAULT_ZOOM.WEB
        : this.isConnected
          ? MapService.DEFAULT_ZOOM.ONLINE
          : MapService.DEFAULT_ZOOM.OFFLINE )
    );
    this.lastZoom = this.map.getZoom();
  }

  /**
   * Force view to refresh with currents coords/zoom
   */
  public refresh(): void {
    console.warn( '*** map::refresh' );
    this.setView( this.geolocation.coords );
  }

  public forceUpdate(): void {
    if ( !this.map ) { return; }
    const zoom = this.map.getZoom();
    const center = this.map.getCenter();
    this.map.setView( center, zoom );
  }

  /**
   * update RTBA network
   */
  public updateRTBA() {
    console.warn( '*** map::updateRTBA' );
    this.zones.get( ZONES_TYPE.RTBA )?.forEach( ( zone: Zone, id: string ) => {
      this.removeZone( ZONES_TYPE.RTBA, id );
    } );

    this.zonesService.refreshRTBA();
    this.forceUpdate();
  }

  /**
   * Remove the map from the view
   *
   * @memberof MapService
   */
  public removeMap(): void {
    if ( this.map ) {
      this.map.remove();
    }
    if ( this.markers ) {
      this.markers.clear();
    }
    if ( this.geolocSubscription ) {
      this.geolocSubscription.unsubscribe();
    }
  }

  /**
   * Add a marker on the map
   *
   * @param {string} id
   * @param {ICoords} coords
   * @memberof MapService
   */
  public addMarker( id: key, coords: ICoords, options?: MarkerLeafOption ): Promise<void> {
    const tempMarker = this.markers.get( id );
    if ( id !== MapService.USER_ID && tempMarker ) {
      tempMarker.setOpacity( options.markerOptions.opacity );
      const popup = tempMarker.getPopup();
      if ( popup ) {
        if ( options.markerOptions.opacity === MapService.LOW_OPACITY ) {
          popup.getElement().classList.add( 'low-opacity' );
        } else {
          popup.getElement().classList.remove( 'low-opacity' );
        }
      }
      return;
    }

    this.removeMarker( id );
    const newMarker = this.leafUtils.createMarker( {
      id: keyToString( id ),
      coords,
      options,
      callback: ( param ) => this.onClickMarker( param ),
    } );
    this.markers.set( id, newMarker );

    // Add new marker on the map
    if ( id === MapService.USER_ID ) {
      newMarker.addTo( this.map );
    } else {
      this.mapCluster.addLayer( newMarker );
    }
  }

  /**
   * Remove a marker based on id
   *
   * @param {string} id
   * @memberof MapService
   */
  public removeMarker( id: key ): void {
    const oldMarker = this.markers.get( id );
    if ( oldMarker ) {
      oldMarker.remove();
      oldMarker.removeFrom( this.map );
      this.markers.delete( id );
      if ( id !== MapService.USER_ID ) {
        this.mapCluster.removeLayer( oldMarker );
      }
    }
  }

  /**
   * Add a point from the map
   *
   * @private
   * @param {POINTS_TYPE} pointType
   * @param {string} id
   * @param {L.Marker} marker
   * @memberof MapService
   */
  private addPoint( pointType: POINTS_TYPE, id: string, marker: L.Marker ): void {
    let points = this.points.get( pointType );
    if ( !points ) {
      this.points.set( pointType, new Map<string, L.Marker>() );
      points = this.points.get( pointType );
    }
    if ( !points.get( id ) ) {
      points.set( id, marker );
      // We regroupe VOR DME and VOR
      this.pointsClusters
        .get( pointType === POINTS_TYPE.VOR_DME ? POINTS_TYPE.VOR : pointType )
        .addLayer( marker );
    }
  }

  /**
   * Remove a point from the map
   *
   * @private
   * @param {POINTS_TYPE} pointType
   * @param {string} id
   * @memberof MapService
   */
  private removePoint( pointType: POINTS_TYPE, id: string ): void {
    const points = this.points.get( pointType );
    if ( points ) {
      const point = points.get( id );
      if ( point ) {
        // We regroupe VOR DME and VOR
        this.pointsClusters
          .get( pointType === POINTS_TYPE.VOR_DME ? POINTS_TYPE.VOR : pointType )
          .removeLayer( point );
        points.delete( id );
      }
    }
  }

  private addZone( zoneType: ZONES_TYPE, id: string, zone: Zone ): void {
    // console.log('addZone : ' + zoneType);
    let zones = this.zones.get( zoneType );
    if ( !zones ) {
      this.zones.set( zoneType, new Map<string, Zone>() );
      zones = this.zones.get( zoneType );
    }
    if ( !zones.get( id ) ) {
      zones.set( id, zone );
    }

    const onlyCenter =
      this.lastZoom < MapService.DISABLE_CLUSTERING_UNDER_BY_ZONE[ zoneType ];
    const displayLabel =
      this.lastZoom > MapService.DISABLE_CLUSTERING_UNDER_BY_ZONE.RTBA_LABEL;
    if ( onlyCenter ) {
      this.zonesClusters.get( zoneType ).addLayer( zone.markerCenter );
      if ( zone.isMultipleElements ) {
        ( zone.leafletElement as Array<any> ).forEach( ( el ) =>
          el.removeFrom( this.map )
        );
      } else {
        ( zone.leafletElement as L.Polyline ).removeFrom( this.map );
      }
    } else {
      this.zonesClusters.get( zoneType ).removeLayer( zone.markerCenter );
      if ( zone.isMultipleElements ) {
        ( zone.leafletElement as Array<any> ).forEach( ( el ) => el.addTo( this.map ) );
      } else {
        ( zone.leafletElement as L.Polyline ).addTo( this.map );
      }
    }

    if ( displayLabel && zone.textMarker ) {
      this.zonesClusters.get( zoneType ).addLayer( zone.textMarker );
    } else {
      if ( zone.textMarker ) {
        this.zonesClusters.get( zoneType ).removeLayer( zone.textMarker );
      }
    }
  }

  private removeZone( zoneType: ZONES_TYPE, id: string ): void {
    const zones = this.zones.get( zoneType );
    if ( zones ) {
      const zone = zones.get( id );
      if ( zone ) {
        this.zonesClusters.get( zoneType ).removeLayer( zone.markerCenter );
        if ( zone.textMarker ) {
          this.zonesClusters.get( zoneType ).removeLayer( zone.textMarker );
        }
        if ( zone.isMultipleElements ) {
          ( zone.leafletElement as Array<any> ).forEach( ( el ) =>
            el.removeFrom( this.map )
          );
        } else {
          ( zone.leafletElement as L.Polyline ).removeFrom( this.map );
        }
        zones.delete( id );
      }
    }
  }

  /**
   * Called every time the location change and update the icon on the map
   *
   * @private
   * @param {ICoords} coords
   * @memberof MapService
   */
  private updateUserLocation( coords: ICoords ): void {
    if ( this.geolocation.geolocActive ) {
      this.addMarker( MapService.USER_ID, coords, {
        markerOptions: {
          icon: this.leafUtils.userIcon,
          rotationAngle: coords.heading,
        },
      } );
    } else {
      this.removeMarker( MapService.USER_ID );
    }
  }

  /**
   * Set view on the user location with a default zoom
   *
   * @memberof MapService
   */
  public focusUserLocation(): void {
    if ( this.geolocation.geolocActive ) {
      this.setView( this.geolocation.coords );
    } else {
      this.geolocation.askPermission().then(
        () => this.setView( this.geolocation.coords ),
        () => this.setView( this.geolocation.coords, MapService.FRANCE_ZOOM )
      );
    }
  }

  public zoomIn(): void {
    this.map.zoomIn();
  }

  public zoomOut(): void {
    this.map.zoomOut();
  }

  /**
   * Set the map's view to a specific Vac and open the popin infos
   *
   * @param {IVac} vac
   * @memberof MapService
   */
  public focusVac( vac: IVac ): void {
    if ( !vac || !vac.ground || !vac.ground.coords ) {
      return;
    }
    // this.setView( vac.ground.coords );
    const coords = new L.LatLng( vac.ground.coords.lat, vac.ground.coords.lon );
    this.onClickMarker(
      { id: vac.key, marker: this.markers.get( vac.key ) },
      coords,
      this.map.getZoom()
      // MapService.THREE_NM_ZOOM Pas de changement de niveau de zoom lors d'un focus sur VAC
    );
  }

  /**
   * Get the informations on the scale
   *
   * @private
   * @memberof MapService
   */
  private modifyScaleLabel(): void {
    if ( !this.map ) { return; }
    this.lastZoom = this.map.getZoom();
    if ( !this.scale ) {
      return;
    }
    const scaleCaption = this._document.querySelector( '.leaflet-control-scale-line' );

    const nm: number = +scaleCaption.innerHTML.substring( 0, scaleCaption.innerHTML.length - 3 ).trim();
    let result: number = nm * 1852;
    let unit = 'm';
    if ( result > 1000 ) {
      result = ( result / 1000 );
      unit = 'km';
    }

    scaleCaption.innerHTML = scaleCaption.innerHTML +
      '<p style="display: block;margin-top:1px;font-size:0.75em">' + ( result.toFixed( 2 ) ) + ' ' + unit + '</p>';
  }

  /**
   * Add an icon on a coords, representing an Heliport or an Airport
   *
   * @param {string} id
   * @param {ICoords} coords
   * @param {VACS_TYPES} [type]
   * @memberof MapService
   */
  public addVac(
    id: key,
    oaci: string,
    coords: ICoords,
    type?: VACS_TYPES,
    opacity: number = 1
  ): void {
    const icon =
      type === VACS_TYPES.HEL
        ? this.leafUtils.helistationIcon
        : this.leafUtils.aerodromeIcon;

    let className = 'aerodrome-icon';
    if ( this.myMaps.getDllMap( id ) ) {
      className += ' dll-blue';
    }
    if ( opacity !== 1 ) {
      className += ' low-opacity';
    }
    this.addMarker( id, coords, {
      markerOptions: { icon, opacity },
      popinOptions: {
        content: oaci,
        closeButton: false,
        closeOnClick: false,
        autoClose: false,
        className,
        autoPan: false,
      },
    } );
  }

  /**
   * Add and remove markers inside the bounding box
   *
   * @private
   * @memberof MapService
   */
  private populateMapWithBoundingBox( context = null ): Promise<any> {
    if ( !this.map ) { return; }
    const bounds = this.map.getBounds();
    const maxLat = bounds.getNorthEast().lat;
    const minLat = bounds.getSouthWest().lat;
    const maxLon = bounds.getNorthEast().lng;
    const minLon = bounds.getSouthWest().lng;
    // console.log('populateMapWithBoundingBox ' + JSON.stringify(context));
    // Markers => Aerodromes & Helistations
    this.cache.getVacs().forEach( ( v: IVac ) => {
      if ( !v.ground ) { return; }
      const coords = v.ground.coords;
      let toKeep = false;
      let fullOpa = true;

      if (
        ( this.airportsVisible && v.type === VACS_TYPES.VAC ) ||
        ( this.heliportsVisible && v.type === VACS_TYPES.HEL )
      ) {
        if ( this.isInsideBox( coords, { maxLat, minLat, maxLon, minLon } ) ) {
          if ( this.filtersP && v.type === VACS_TYPES.VAC ) {
            // Pas de filtres hélistations pour l'instant
            fullOpa = this.filterVisiblesMarkers( v );
          }
          toKeep = true;
        }
      }

      if ( toKeep ) {
        this.addVac(
          v.key,
          v.oaci,
          v.ground.coords,
          v.type,
          fullOpa ? 1 : MapService.LOW_OPACITY
        );
      } else {
        this.removeMarker( v.key );
      }
    } );

    // Points
    const checkPoints = (
      points: Map<string, L.Marker>,
      pointType: POINTS_TYPE
    ) => {
      points.forEach( ( m: L.Marker, id: string ) => {
        if (
          this.isInsideBox( this.utils.latLngToCoords( m.getLatLng() ), {
            maxLat,
            minLat,
            maxLon,
            minLon,
          } )
        ) {
          this.addPoint( pointType, id, m );
        } else {
          this.removePoint( pointType, id );
        }
      } );
    };

    const allPoints = Object.keys( POINTS_TYPE );
    for ( let i = 0; i < allPoints.length; i += 1 ) {
      const p = allPoints[ i ] as POINTS_TYPE;
      if ( context !== 'setLayers' && p === POINTS_TYPE.OBS ) {
        continue;
      }
      // Remove all the points when filter not selected
      if ( this.layersP.points.indexOf( p ) === -1 ) {
        this.points.get( p )?.forEach( ( marker: L.Marker, id: string ) => {
          this.removePoint( p, id );
        } );
      } else {
        // Add point that are on the filter
        switch ( p ) {
          case POINTS_TYPE.VFR:
            checkPoints( this.pointsService.VFRs, p );
            break;
          case POINTS_TYPE.VOR:
            checkPoints( this.pointsService.VORs, p );
            break;
          case POINTS_TYPE.VOR_DME:
            checkPoints( this.pointsService.VOR_DMEs, p );
            break;
          case POINTS_TYPE.OBS:
            // Ne pas faire TROP de mise à jour pour OBS car il y a trop de points, 12000
            if ( context === 'setLayers' ) {
              // we put points on all the France map and not just on the zoom area
              this.pointsService.obstacles.forEach( ( m: L.Marker, id: string ) => {
                this.addPoint( p, id, m );
              } );
            }
            break;
        }
      }
    }
    ///

    // Zones
    const checkZones = ( zones: Map<string, Zone>, zoneType: ZONES_TYPE ) => {
      // console.log('checkzones with Add : ' + zoneType + ' ' + JSON.stringify(zones));
      zones.forEach( ( z: Zone, id: string ) => {
        // console.log('before zoneIsInsideBox');
        if ( this.zoneIsInsideBox( z, { maxLat, minLat, maxLon, minLon } ) ) {
          this.addZone( zoneType, id, z );
        } else {
          // console.log('remove zone ' + id);
          this.removeZone( zoneType, id );
        }
      } );
    };

    Object.keys( ZONES_TYPE ).forEach( ( z: ZONES_TYPE ) => {
      // Remove all the points when filter not selected
      if ( this.layersP.zones.indexOf( z ) === -1 ) {
        this.zones.get( z )?.forEach( ( zone: Zone, id: string ) => {
          this.removeZone( z, id );
        } );
      } else {
        // Add point that are on the filter
        switch ( z ) {
          case ZONES_TYPE.RTBA:
            checkZones( this.zonesService.RTBAs, z );
            break;

          case ZONES_TYPE.FIR:
            checkZones( this.zonesService.FIRs, z );
            break;
          case ZONES_TYPE.SIV:
            checkZones( this.zonesService.SIVs, z );
            break;
          case ZONES_TYPE.TMA:
            checkZones( this.zonesService.TMAs, z );
            break;
          case ZONES_TYPE.CTR:
            checkZones( this.zonesService.CTRs, z );
            break;
          case ZONES_TYPE.ZONES_R:
            checkZones( this.zonesService.ZONES_Rs, z );
            break;
          case ZONES_TYPE.ZONES_D:
            checkZones( this.zonesService.ZONES_Ds, z );
            break;
          case ZONES_TYPE.ZONES_P:
            checkZones( this.zonesService.ZONES_Ps, z );
            break;
          default:
            throw new Error( '[FIXME] unknown type zone' );
        }
      }
    } );
    ///

    if ( this.forceRedraw ) {
      this.forceRedraw = false;
      setTimeout( () => {
        // console.log('force redraw');
        this.redrawLayers();
      }, 300 );

    }
    this.populateRunning = false;
  }

  /**
   * Return true if v has at least one runways of filtersP.groundType at a minimun length of this.filtersP.airstripLength
   *
   * @private
   * @param {IVac} v
   * @returns {boolean}
   * @memberof MapService
   */
  private filterVisiblesMarkers( v: IVac ): boolean {
    if (
      !this.filtersP ||
      ( this.filtersP.groundType.length === 0 &&
        this.filtersP.airstripLength === 0 )
    ) {
      return true;
    }

    const validGrounds: any = {};
    this.filtersP.groundType.forEach( ( g: GROUND_TYPES ) => {
      validGrounds[ g ] = false;
    } );

    let atLeastLenght = false;
    v.runways.forEach( ( r: IRunway ) => {
      const groundTypeOk =
        this.filtersP.groundType.length === 0 ||
        this.filtersP.groundType.includes( r.type );

      if ( groundTypeOk ) {
        if (
          this.filtersP.airstripLength === 0 ||
          this.filtersP.airstripLength <= r.length
        ) {
          validGrounds[ r.type ] = true;
          atLeastLenght = true;
        }
      }
    } );

    let valid = atLeastLenght;
    Object.values( validGrounds ).forEach( ( b: boolean ) => {
      valid = valid && b;
    } );
    return valid;
  }

  /**
   * Check if a ICoords is inside bounds +- EXTENDS_COORD_LOADING_BY_ZOOM
   *
   * @private
   * @param {ICoords} coords
   * @param {{ maxLat: number, minLat: number, maxLon: number, minLon: number }} bounds
   * @returns {boolean}
   * @memberof MapService
   */
  private isInsideBox(
    coords: ICoords,
    bounds: { maxLat: number; minLat: number; maxLon: number; minLon: number }
  ): boolean {
    let boundaries = MapService.EXTENDS_COORD_LOADING_BY_ZOOM[ this.lastZoom ];
    if ( !boundaries ) {
      boundaries = {
        lat: 0.25,
        lon: 0.25,
      };
    }
    if (
      coords.lat < bounds.maxLat + boundaries.lat &&
      coords.lat > bounds.minLat - boundaries.lat &&
      coords.lon < bounds.maxLon + boundaries.lon &&
      coords.lon > bounds.minLon - boundaries.lon
    ) {
      return true;
    }
    return false;
  }

  private zoneIsInsideBox(
    zone: Zone,
    bounds: { maxLat: number; minLat: number; maxLon: number; minLon: number }
  ): boolean {
    // Zone inside box
    if (
      this.isInsideBox( zone.bounds.NE, bounds ) ||
      this.isInsideBox( zone.bounds.SE, bounds ) ||
      this.isInsideBox( zone.bounds.NW, bounds ) ||
      this.isInsideBox( zone.bounds.SW, bounds )
    ) {
      return true;
    }

    // Box inside zone
    const boundaries = {
      maxLat: zone.bounds.NE.lat,
      minLat: zone.bounds.SW.lat,
      maxLon: zone.bounds.NE.lon,
      minLon: zone.bounds.SW.lon,
    };
    if (
      ( bounds.maxLat < boundaries.maxLat &&
        bounds.maxLat > boundaries.minLat &&
        bounds.maxLon < boundaries.maxLon &&
        bounds.maxLon > boundaries.minLon ) ||
      ( bounds.minLat < boundaries.maxLat &&
        bounds.minLat > boundaries.minLat &&
        bounds.maxLon < boundaries.maxLon &&
        bounds.maxLon > boundaries.minLon ) ||
      ( bounds.minLat < boundaries.maxLat &&
        bounds.minLat > boundaries.minLat &&
        bounds.minLon < boundaries.maxLon &&
        bounds.minLon > boundaries.minLon ) ||
      ( bounds.maxLat < boundaries.maxLat &&
        bounds.maxLat > boundaries.minLat &&
        bounds.minLon < boundaries.maxLon &&
        bounds.minLon > boundaries.minLon )
    ) {
      return true;
    }
    return false;
  }

  /**
   * When a marker is clicked, open the modal with more infos
   *
   * @private
   * @param {string} id
   * @param {L.Marker} marker
   * @memberof MapService
   */
  public onClickMarker(
    param: { id: key; marker: L.Marker },
    coords?: L.LatLng,
    zoom?: number
  ): void {
    // Center view to the left of the marker (the marker should be one quarter of lng to the right of the centered view)
    if ( ( param.marker && param.marker.getLatLng() ) || coords ) {
      const markerLatLng = coords ? coords : param.marker.getLatLng();
      if ( zoom && zoom > this.lastZoom ) {
        // XGOU : Ne faudrait-il pas ici aussi conserver le niveau de zoom utilisateur ?
        this.setView( { lat: markerLatLng.lat, lon: markerLatLng.lng }, zoom );
      }

      setTimeout( () => {
        const bounds = this.map.getBounds();
        const distLng = bounds.getEast() - bounds.getWest();

        this.map.setView(
          [ markerLatLng.lat, markerLatLng.lng - distLng / 5 ],
          this.lastZoom
        );
      }, 300 );
    }

    const vac: IVac = this.cache.getVac( param.id );

    if ( vac ) {
      this.modal
        .presentVacDetailsPopin( {
          vac: new Vac( vac, this.translate.currentLang ),
        } )
        .then( ( vac: any ) => {
          if ( vac.data ) {
            this.modal.presentVacDetails( { vac: vac.data } );
          }
        } );
    }
  }

  public onClickMarkerObs(
    param: { id: string; marker: L.Marker },
    coords1?: ICoords,
    zoom?: number,
    point?: IPointObs,
  ): void {
    // console.log( 'onclickMarkerObs' );
    const coords = !coords1 ? null : new L.LatLng( coords1.lat, coords1.lon );
    // Center view to the left of the marker (the marker should be one quarter of lng to the right of the centered view)
    if ( ( param.marker && param.marker.getLatLng() ) || coords ) {
      const markerLatLng = coords ? coords : param.marker.getLatLng();
      if ( zoom && zoom > this.lastZoom ) {
        this.setView( { lat: markerLatLng.lat, lon: markerLatLng.lng }, zoom );
      }

      setTimeout( () => {
        const bounds = this.map.getBounds();
        const distLng = bounds.getEast() - bounds.getWest();

        this.map.setView(
          [ markerLatLng.lat, markerLatLng.lng - distLng / 5 ],
          this.lastZoom
        );
      }, 300 );
    }

    this.modal
      .presentObsDetailsPopin( { point } );
  }
}
