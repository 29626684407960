import { Injectable } from '@angular/core';
import { WebServices } from '../webservices/web-services.service';
import { Range } from '../../../models/interfaces/range';
import { formatDate } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { IRTBAPolylineOptions } from 'src/models/interfaces/leaf';
import { Activated_AZBA } from 'src/models/interfaces/web-services';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../utils/utils.service';

export enum ON_UPDATE_STATES {
  ACTIVE_ZONE_RECEIVED = 'active_zone',
  RANGE_CHANGED = 'range_changed',
  DOWNLOADING_ZONE = 'downloading_zone',
  INITIALIZING = 'initializing',
}

@Injectable( {
  providedIn: 'root',
} )
export class AzbaManagerService implements Range {
  public static readonly RTBA_ACTIVE: IRTBAPolylineOptions = {
    fillColor: '#ecbbbb',
    // fillColor: '#df3f3f',
    color: '#cf2f2f',
    fillOpacity: 1,
    // fillOpacity: 0.3,
  };

  public static readonly RTBA_ACTIVE_FLOOR: IRTBAPolylineOptions = {
    fillColor: '#bf1f1f',
    color: '#af0f0f',
    fillOpacity: 1,
    // fillOpacity: 0.6,
  };

  public static readonly RTBA_UNACTIVE: IRTBAPolylineOptions = {
    fillColor: '#54b8fb',
    color: '#44a8eb',
    fillOpacity: 0.3,
  };

  public static readonly RTBA_UNACTIVE_FLOOR: IRTBAPolylineOptions = {
    fillColor: '#3498db',
    color: '#2488cb',
    fillOpacity: 0.6,
  };

  public static FORMAT = 'dd/MM/yyyy - HH:mm';

  /* Dernière date du réseau RTBA (systeme d'historisation) */
  private _latestRTBAVersion: string;

  /* Créneau max disponible et renvoyé par le backend
       Les valeurs sont modifiable, mais la reference de l'objet ne doit pas changer (readonly)
    */
  private readonly _range: Range = { start: null, end: null };

  /* Créneau selectionné pour l'utilisateur
       Les valeurs sont modifiable, mais la reference de l'objet ne doit pas changer (readonly)
    */
  private readonly _current: Range = { start: null, end: null };

  private _currentActivatedAZBA: Map<string, Activated_AZBA> = new Map<
    string,
    Activated_AZBA
  >();

  private _onUpdate: BehaviorSubject<ON_UPDATE_STATES> = new BehaviorSubject(
    null
  );

  constructor(
    private webservices: WebServices,
    private translate: TranslateService,
    private utils: UtilsService
  ) { }

  /* Observateur permettant de savoir quand une donnée à changée :
     - si la selection de l'utilisateur a changée
     - si les données provenant du backend ont changées (ex: dates)
     */
  public get onUpdate$(): Observable<ON_UPDATE_STATES> {
    return this._onUpdate.asObservable();
  }

  public get latestRTBAVersion(): string {
    return this._latestRTBAVersion;
  }

  /**
   * Renvoi le range maximum disponible
   */
  get availableRange(): Range {
    return this._range;
  }

  /**
   * Renvoi la date de début de selection par l'utilisateur
   */
  get start(): string {
    return this._current.start;
  }

  set start( value: string ) {
    // Empeche l'utilisateur de selectionner une date avant le créneau autorisé
    /*if ( value.getTime() < this.availableRange.start.getTime() ) {
            value = this.availableRange.start;
        };*/

    this._current.start = value;

    // Préviens le souscripteur qu'une donnée à changée
    this._onUpdate.next( ON_UPDATE_STATES.RANGE_CHANGED );

    // Raffraichi les données avec la plage indiquée
    this.refresh();
  }

  /**
   * Renvoi la date de fin de selection par l'utilisateur
   */
  get end(): string {
    return this._current.end;
  }

  set end( value: string ) {
    // Empeche l'utilisateur de selectionner une date après le créneau autorisé
    /*if ( value.getTime() > this.availableRange.end.getTime() ) {
            value = this.availableRange.end;
        }*/

    this._current.end = value;

    // Préviens le souscripteur qu'une donnée à changée
    this._onUpdate.next( ON_UPDATE_STATES.RANGE_CHANGED );

    // Raffraichi les données avec la plage indiquée
    this.refresh();
  }

  set currentRange( value: Range ) {
    if ( !value ) { return; }
    this._current.start = value.start;
    this._current.end = value.end;

    // Préviens le souscripteur qu'une donnée à changée
    this._onUpdate.next( ON_UPDATE_STATES.RANGE_CHANGED );

    // Raffraichi les données avec la plage indiquée
    this.refresh();
  }

  get information(): string {
    let startDate: string, endDate: string;
    try {
      startDate = formatDate(
        this._current.start,
        AzbaManagerService.FORMAT,
        'fr',
        '+0000'
      );
      endDate = formatDate(
        this._current.end,
        AzbaManagerService.FORMAT,
        'fr',
        '+0000'
      );
    } catch ( e ) {
      return this.translate.instant( 'AZBA.ERROR' );
    }
    return this.translate.instant( 'AZBA.RANGE', {
      startDate,
      endDate,
    } );
  }

  init(): Promise<void> {
    this._onUpdate.next( ON_UPDATE_STATES.INITIALIZING );

    return new Promise( ( resolve, reject ) => {
      this.webservices.getAzbaRange().subscribe(
        ( data ) => {
          try {
            const todayUTC = this.utils.nowUTC(); // new Date().toISOString();
            this._range.start = data.range.start;
            this._range.end = data.range.end;
            this._current.start = todayUTC;
            this._current.end = data.range.end;
            this._latestRTBAVersion = data.latest_azba_date;
            console.warn(
              `*** Latest AZBA informations {range start:${ this._range.start } end:${ this._range.end }, latestVersion:${ this._latestRTBAVersion }}`
            );
            // console.log(`** Azba dates : ${this.start} to ${this.end}`);
            // la donnée à changée
            this._onUpdate.next( ON_UPDATE_STATES.RANGE_CHANGED );
            resolve();
            // va chercher les zones actives
            this.refresh();
          } catch ( e ) {
            reject( e );
          }
        },
        ( error ) => {
          reject( error );
        }
      );
    } );
  }

  /**
   * Renvoi les informations si la zone est active AZBA/RTBA
   * @param zone
   * @returns
   */
  public getActivatedAZBA( codeId: string ): Activated_AZBA {
    return this._currentActivatedAZBA.get( codeId );
  }

  // TODO : Gérer un refresh (non chiffré)
  // - cas de la date qui a changé au niveau de l'historique (nouveau réseau RTBA)
  // - cas de la donnée qui a changée en cours de route (activité des zones par rapport a la selection des creneaux)
  refresh(): void {
    this._onUpdate.next( ON_UPDATE_STATES.DOWNLOADING_ZONE );
    const startDate: String = encodeURIComponent( this.start );
    const endDate: string = encodeURIComponent( this.end );
    this.webservices
      .getActiveAZBA( this.latestRTBAVersion, startDate, endDate )
      .subscribe( ( data ) => {
        this._currentActivatedAZBA.clear();

        if ( data ) {
          data.forEach( ( value ) => {
            this._currentActivatedAZBA.set( '_RTBA_' + value.codeId, value );
          } );
          // console.log( data[ 1 ] );
        }

        this._onUpdate.next( ON_UPDATE_STATES.ACTIVE_ZONE_RECEIVED );
      } );
  }
}
