import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Range } from '../../../models/interfaces/range';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils/utils.service';

declare const window: any;

@Component( {
  selector: 'app-date-popup',
  templateUrl: './date-popup.component.html',
  styleUrls: [ './date-popup.component.scss' ],
} )
export class DatePopupComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    public datepipe: DatePipe,
    public utilsService: UtilsService
  ) {
    this.isDatetimeSupported = ( window as any ).isDatetimeLocalSupported;
  }

  public availableRange: Range;
  public current: Range;

  public selectedRange: Range = { start: null, end: null };
  public isValid = true;
  public isTimeSlotValid = true;
  public isTimeFormatValid = true;
  public isTimeEndBeforeDebutValid = true;

  public startDateDisplay: string = null;
  public endDateDisplay: string = null;
  public currentStartDateDisplay: string = null;
  public currentEndDateDisplay: string = null;
  public startRange: string = null;
  public endRange: string = null;

  private tsStartRange: number;
  private tsEndRange: number;

  public isDatetimeSupported = true;
  @ViewChild( 'startInput' ) startInput: ElementRef;
  @ViewChild( 'endInput' ) endInput: ElementRef;
  private startInputElem: any;
  private endInputElem: any;

  formatDate( date: any ) {
    return this.datepipe.transform( date, 'dd/MM/yyyy HH:mm', '+0000' );
  }

  formatNotDateTime( date: any ) {
    return this.datepipe.transform( date, 'dd/MM/yyyy HH:mm' );
  }

  ngAfterViewInit() {
    this.startInputElem = ( this.startInput as any ).nativeElement;
    this.endInputElem = ( this.endInput as any ).nativeElement;
  }

  ngOnInit() {
    this.startDateDisplay = this.formatDate( this.availableRange.start );
    this.endDateDisplay = this.formatDate( this.availableRange.end );

    this.selectedRange.start = this.utilsService.dateUTC(
      new Date( this.current.start )
    );

    this.selectedRange.end = this.utilsService.dateUTC(
      new Date( this.current.end )
    );

    if ( !this.isDatetimeSupported ) {
      this.selectedRange.start = this.formatNotDateTime(
        this.utilsService.dateUTC( new Date( this.current.start ) )
      );

      this.selectedRange.end = this.formatNotDateTime(
        this.utilsService.dateUTC( new Date( this.current.end ) )
      );
    }

    this.startRange = this.utilsService.dateUTC(
      new Date( this.availableRange.start )
    );

    this.endRange = this.utilsService.dateUTC(
      new Date( this.availableRange.end )
    );

    this.tsStartRange = new Date( this.availableRange.start ).getTime();
    this.tsEndRange = new Date( this.availableRange.end ).getTime();
  }

  onSelectStart( event: any ) {
    this.selectedRange.start = event.target.value;
    this.checkValidity();
  }

  onSelectEnd( event: any ) {
    this.selectedRange.end = event.target.value;
    this.checkValidity();
  }

  checkValidity( range = this.selectedRange ) {
    if (
      range === null ||
      range.start === null ||
      range.end === null ||
      range.start.length === 0 ||
      range.end.length === 0
    ) {
      this.isValid = false;
      this.isTimeSlotValid = false;
      return;
    }

    let tsSelectStart;
    let tsSelectEnd;

    if ( !this.isDatetimeSupported ) {
      if ( !this.matchFormat( range.start ) || !this.matchFormat( range.end ) ) {
        this.isValid = false;
        this.isTimeFormatValid = false;
        return;
      }

      tsSelectStart = this.parseDate( range.start ).getTime();
      tsSelectEnd = this.parseDate( range.end ).getTime();
    } else {
      tsSelectStart = new Date( range.start + '+00:00' ).getTime();
      tsSelectEnd = new Date( range.end + '+00:00' ).getTime();
    }

    // console.log('tsSelectStart', tsSelectStart);
    // console.log('tsSelectEnd', tsSelectEnd);

    if ( isNaN( tsSelectStart ) || isNaN( tsSelectEnd ) ) {
      this.isValid = false;
      this.isTimeFormatValid = false;
      return;
    }

    if ( tsSelectStart > tsSelectEnd ) {
      this.isValid = false;
      this.isTimeEndBeforeDebutValid = false;
      return;
    }

    if (
      tsSelectStart < this.tsStartRange ||
      tsSelectStart > this.tsEndRange ||
      tsSelectEnd < this.tsStartRange ||
      tsSelectEnd > this.tsEndRange
    ) {
      this.isValid = false;
      this.isTimeSlotValid = false;
      return;
    }

    this.isValid = true;
    this.isTimeSlotValid = true;
    this.isTimeFormatValid = true;
    this.isTimeEndBeforeDebutValid = true;
  }

  cancel() {
    this.modalCtrl.dismiss( null );
  }

  select() {
    this.checkValidity();
    if ( !this.isValid ) {
      return;
    }

    if ( !this.isDatetimeSupported ) {
      const realDateStart = this.utilsService.dateUTC(
        this.parseDate( this.selectedRange.start )
      ) as string;
      const realDateEnd = this.utilsService.dateUTC(
        this.parseDate( this.selectedRange.end )
      ) as string;

      this.modalCtrl.dismiss( {
        start: realDateStart + '+00:00',
        end: realDateEnd + '+00:00',
      } );
    } else {
      this.modalCtrl.dismiss( {
        start: this.selectedRange.start + '+00:00',
        end: this.selectedRange.end + '+00:00',
      } );
    }
  }

  force2Digits( s ) {
    return s.length === 1 ? '0' + s : s;
  }

  matchFormat( s: string ): boolean {
    const rgx: RegExp = new RegExp(
      '^([0-9]{1,2})/([0-9]{1,2})/([0-9]{4}) ([0-1]?[0-9]|2[0-3]):([0-5][0-9])$'
    );
    const match: Array<string> = rgx.exec( s );
    return match != null;
  }

  parseDate( s: any ) {
    const rgx: RegExp = new RegExp(
      '^([0-9]{1,2})/([0-9]{1,2})/([0-9]{4}) ([0-1]?[0-9]|2[0-3]):([0-5][0-9])$'
    );
    const match: Array<string> = rgx.exec( s );
    if ( match != null ) {
      // ne pas utiliser des virgules, car cela est lu en GMT et non pas UTC
      // .000Z force le format UTC
      const result = new Date(
        `${ match[ 3 ] }-${ this.force2Digits( match[ 2 ] ) }-${ this.force2Digits(
          match[ 1 ]
        ) }T${ match[ 4 ] }:${ match[ 5 ] }:00.000Z`
      );
      return result;
    }
    // cas impossible car on valide avec l'attribut pattern
    return new Date();
  }
}
