import { Component } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from 'src/app/services/network/network.service';
import { VACS_TYPES } from 'src/models/enums';
import { Desubscribe } from 'src/models/classes/desubscribe';
import { Vac } from 'src/models/classes/vac';
import { WebServices } from 'src/app/services/webservices/web-services.service';
import { PdfManagerService } from 'src/app/services/pdf-manager/pdf-manager.service';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { IVac, key } from 'src/models/interfaces/vac';
import { AlertManagerService } from 'src/app/services/alert-manager/alert-manager.service';
import { environment } from 'src/environments/environment';
import { CacheService } from 'src/app/services/cache/cache.service';
import { UtilsService } from '../../services/utils/utils.service';

declare let cordova: any;

@Component( {
  selector: 'app-vac-details',
  templateUrl: './vac-details.component.html',
  styleUrls: [ './vac-details.component.scss' ],
} )
export class VacDetailsComponent extends Desubscribe {
  vac: Vac;
  currentLang: string;
  isConnected: boolean;
  env: any = environment;

  VACS_TYPES = VACS_TYPES;

  isBeingDll = false;

  constructor(
    protected pdfManager: PdfManagerService,
    protected loader: LoadingController,
    protected modalCtrl: ModalController,
    protected network: NetworkService,
    protected translate: TranslateService,
    protected webServices: WebServices,
    protected myMaps: MyMapsService,
    protected alerts: AlertManagerService,
    protected cache: CacheService,
    protected utils: UtilsService
  ) {
    super();
    this.currentLang = this.translate.currentLang;
    this.isConnected = this.network.isConnected;
    this.addSubscription( this.network.checkConnection().subscribe( ( b: boolean ) => this.isConnected = b ) );
  }

  ngOnInit() {
    this.addSubscription( this.myMaps.dllMaps.subscribe( ( listDll: Map<key, IVac> ) => {
      if ( this.vac.config.map ) {
        const map = listDll.get( this.vac.config.key );
        this.vac.config.map.downloadedMap = map ? map.map.downloadedMap : null;
      }
    } ) );
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  get isFavorite(): boolean {
    return this.cache.isFavoriteMap( this.vac.config.key );
  }

  toggleFav(): void {
    const map = this.vac.config.map;
    if ( this.isFavorite ) {
      this.myMaps.changeFav( this.vac.config.key, this.vac.config, false );
    } else {
      if ( map && map.downloadedMap ) {
        this.myMaps.changeFav( this.vac.config.key, this.vac.config, true );
      } else {
        this.downloadPDF( false, false ).then( ( newVac: IVac ) => this.myMaps.changeFav( newVac.key, newVac, true ) );
      }
    }
  }

  async trashOrDll(): Promise<any> {
    const map = this.vac.config.map;
    if ( map && map.downloadedMap ) {
      return this.alerts.basicChoice( {
        header: 'ALERTS.SUPPRESS.ONE.TITLE',
        message: 'ALERTS.SUPPRESS.ONE.MESSAGE',
        confirm: {
          text: 'ALERTS.BASIC_CHOICE.DELETE',
          class: 'red'
        }
      } ).then( ( b: boolean ) => {
        if ( b ) { this.myMaps.changeDll( this.vac.config.key ); }
      } );
    } else {
      return this.downloadPDF( false, false );
    }
  }

  async openPDF(): Promise<any> {
    const map = this.vac.config.map;
    if ( map && map.downloadedMap ) {
      // console.log( 'openPDFvac : ' + cordova.file.dataDirectory + this.vac.config.map.downloadedMap.fileName );
      /* map.downloadedMap.filePath */
      this.pdfManager.openPdf( { filePath: cordova.file.dataDirectory + this.vac.config.map.downloadedMap.fileName, nameToDisplay: map.downloadedMap.fileName } );
    } else {
      return this.downloadPDF();
    }
  }

  async downloadPDF( temp: boolean = true, open: boolean = true ): Promise<IVac> {
    this.isBeingDll = true;
    return this.myMaps.downloadPDF( this.vac.config, temp, open, false ).then(
      ( v: IVac ) => {
        this.isBeingDll = false;
        return v;
      },
      ( v: IVac ) => {
        this.isBeingDll = false;
        return v;
      }
    );
  }

  formatCoord( coord: any, type: string ) {
    return UtilsService.formatCoord( coord, type );
  }
}
