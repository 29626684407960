import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DgacHeaderComponent } from './dgac-header/dgac-header.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { FormsModule } from '@angular/forms';
import { SearchVacComponent } from './inputs/search-vac/input/search-vac.component';
import { ResultVacComponent } from './inputs/search-vac/result/result-vac.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NbFiltersComponent } from './nb-filters/nb-filters.component';
import { ProfilComponent } from './profil/profil.component';
import { VacDetailsPopinComponent } from './vac-details/vac-details-popin/vac-details-popin.component';
import { ObsDetailsPopinComponent } from './obstacles-details-popin/obstacles-details-popin.component';
import { VacDetailsComponent } from './vac-details/vac-details.component';
import { NextUpdateComponent } from './next-update/next-update.component';
import { EditablePageComponent } from './editable-page/editable-page.component';
import { MyMapsModule } from './my-maps/my-maps.module';
import { GroundsMenuComponent } from './appear-menu/grounds-menu/grounds-menu.component';
import { MapsMenuComponent } from './appear-menu/maps-menu/maps-menu.component';
import { LayersMenuComponent } from './appear-menu/layers-menu/layers-menu.component';
import { AzbaMenuComponent } from './appear-menu/azba-menu/azba-menu.component';
import { ProgressPopupComponent } from './progress-popup/progress-popup.component';
import { DatePopupComponent } from './azba-date-popup/date-popup.component';
import { DownloadingComponent } from './downloading/downloading.component';
import { ChooseTilesZoneComponent } from './choose-tiles-zone/choose-tiles-zone.component';
import { TooltipsModule } from 'ionic4-tooltips';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule( {
  declarations: [
    DgacHeaderComponent,
    LegalNoticesComponent,
    //    ScaleComponent,
    SearchVacComponent,
    ResultVacComponent,
    GroundsMenuComponent,
    AzbaMenuComponent,
    LayersMenuComponent,
    NbFiltersComponent,
    ProfilComponent,
    VacDetailsPopinComponent,
    VacDetailsComponent,
    ObsDetailsPopinComponent,
    NextUpdateComponent,
    EditablePageComponent,
    ProgressPopupComponent,
    DatePopupComponent,
    MapsMenuComponent,
    DownloadingComponent,
    ChooseTilesZoneComponent,
  ],
  entryComponents: [
    LegalNoticesComponent,
    VacDetailsPopinComponent,
    VacDetailsComponent,
    ObsDetailsPopinComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    PipesModule,
    NgxSliderModule,
    MyMapsModule,
    ScrollingModule,
       // NgxExtendedPdfViewerModule,
    TooltipsModule.forRoot(),
  ],
  providers: [],
  exports: [
    DgacHeaderComponent,
    LegalNoticesComponent,
    //    ScaleComponent,
    SearchVacComponent,
    ResultVacComponent,
    GroundsMenuComponent,
    AzbaMenuComponent,
    LayersMenuComponent,
    NbFiltersComponent,
    ProfilComponent,
    VacDetailsPopinComponent,
    VacDetailsComponent,
    ObsDetailsPopinComponent,
    NextUpdateComponent,
    EditablePageComponent,
    MapsMenuComponent,
    DownloadingComponent,
    ChooseTilesZoneComponent,
  ],
} )
export class ComponentsModule { }
