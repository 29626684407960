import { Injectable } from '@angular/core';
import { DexieConfigService } from './dexie-config.service';
import { IVac, key, keyToString } from 'src/models/interfaces/vac';
import { IEditablePage } from 'src/models/interfaces/web-services';
import { IAlert } from 'src/models/interfaces/alert';
import { IZone, IPoint, IPointObs } from 'src/models/interfaces/points';
import { CacheService } from '../cache/cache.service';
import { IDownloadedMap } from 'src/models/interfaces/map';
import { IFavorite } from 'src/models/interfaces/favorite';
import { PromiseExtended } from 'dexie';

@Injectable( {
  providedIn: 'root'
} )
export class DatabaseService {

  constructor( private dexie: DexieConfigService, private cache: CacheService ) { }

  public putVac( vac: IVac ) {
    this.cache.updateVac( vac );
    return this.dexie.vacsTable.put( vac );
  }

  public async putVacs( vacs: IVac[] ) {
    // console.log('In put VACS');
    await this.dexie.vacsTable.clear();
    this.cache.updateVacs( vacs );
    return this.dexie.vacsTable.bulkPut( vacs );
  }

  public getVacs(): Promise<IVac[]> {
    const p = this.dexie.vacsTable.toArray();
    p.then( ( vacs ) => { this.cache.updateVacs( vacs ); } );
    return p;
  }

  // --- Favorites ---------------------------------------------------------------------------------

  public getFavorites() {
    const p = this.dexie.favoriteMapsTable.toArray();
    p.then( ( favoriteMaps ) => { this.cache.updateFavoriteMaps( favoriteMaps ); } );
    return p;
  }

  public setFavorite( key: key, state: boolean ): Promise<key | void> {
    this.cache.setFavoriteMap( key, state );
    return state ? this.dexie.favoriteMapsTable.put( { key } as IFavorite ) : this.dexie.favoriteMapsTable.delete( key );
  }

  // --- Downloaded maps ---------------------------------------------------------------------------

  public putDownloadedMap( downloadedMap: IDownloadedMap ) {
    this.cache.updateDownloadedMap( downloadedMap );
    return this.dexie.downloadedMapsTable.put( downloadedMap );
  }

  public async putDownloadedMaps( downloadedMaps: IDownloadedMap[] ) {
    await this.dexie.downloadedMapsTable.clear();
    this.cache.updateDownloadedMaps( downloadedMaps );
    return this.dexie.downloadedMapsTable.bulkPut( downloadedMaps );
  }

  public getDownloadedMaps(): Promise<IDownloadedMap[]> {
    const p = this.dexie.downloadedMapsTable.toArray();
    p.then( ( downloadedMaps ) => { this.cache.updateDownloadedMaps( downloadedMaps ); } );
    return p;
  }

  public getDownloadedMap( key: key ): Promise<IDownloadedMap> {
    const p = this.dexie.downloadedMapsTable.where( { key: keyToString( key ) } ).first();
    p.then( ( downloadedMap ) => { this.cache.updateDownloadedMap( downloadedMap ); } );
    return p;
  }

  public async removeDownloadedMap( key: key ): Promise<any> {
    this.cache.deleteMap( key );
    if ( this.cache.isFavoriteMap( key ) ) { await this.setFavorite( key, false ); }
    return this.dexie.downloadedMapsTable.delete( key );
  }

  // -----------------------------------------------------------------------------------------------

  public putEditable( editable: IEditablePage ) {
    return this.dexie.editablesTable.put( editable );
  }

  public getEditables(): Promise<IEditablePage[]> {
    return this.dexie.editablesTable.toArray();
  }

  public getEditable( title: string ): Promise<IEditablePage> {
    return this.dexie.editablesTable.where( { title } ).first();
  }

  public putAlert( alert: IAlert ) {
    return this.dexie.alertsTable.put( alert );
  }

  public getAlerts(): Promise<IAlert[]> {
    return this.dexie.alertsTable.toArray();
  }

  public getAlert( id: string ): Promise<IAlert> {
    return this.dexie.alertsTable.where( { id } ).first();
  }

  public removeAlert( id: number ): Promise<any> {
    return this.dexie.alertsTable.delete( id );
  }

  public getZones(): Promise<IZone[]> {
    return this.dexie.zonesTable.toArray();
  }

  public async putZones( zones: IZone[] ) {
    // console.log('putZone');
    await this.dexie.zonesTable.clear();
    return this.dexie.zonesTable.bulkPut( zones );
  }

  public getPoints(): Promise<IPoint[]> {
    return this.dexie.pointsTable.toArray();
  }

  public async putPoints( points: IPoint[] ) {
    await this.dexie.pointsTable.clear();
    return this.dexie.pointsTable.bulkPut( points );
  }

  public getPointsObs(): Promise<IPointObs[]> {
    return this.dexie.pointsObsTable.toArray();
  }

  public async putPointsObs( points: IPointObs[] ) {
    await this.dexie.pointsObsTable.clear();
    return this.dexie.pointsObsTable.bulkPut( points );
  }

  public isWorking(): PromiseExtended {
    return this.dexie.open();
  }
}
