import { NgModule } from '@angular/core';
import { NoMoreNinePipe } from './no-more-nine/no-more-nine.pipe';
import { HighLightSearchPipe } from './high-light-search/high-light-search.pipe';
import { DmsToDdPipe } from './dms-to-dd/dms-to-dd.pipe';
import { DdToDmsPipe } from './dd-to-dms/dd-to-dms.pipe';
import { FilterByPipe } from './filter-by/filter-by.pipe';
import { DistanceToUserPipe } from './distance-to-user/distance-to-user.pipe';
import { OrderByParamPipe } from './order-by-param/order-by-param.pipe';
import { MapToArrayPipe } from './map-to-array/map-to-array.pipe';
import { AttributeExactPipe } from './attribute-exact/attribute-exact.pipe';
import { AttributeContainsPipe } from './attribute-contains/attribute-contains.pipe';

@NgModule( {
    declarations: [
        NoMoreNinePipe,
        HighLightSearchPipe,
        DmsToDdPipe,
        DdToDmsPipe,
        FilterByPipe,
        DistanceToUserPipe,
        OrderByParamPipe,
        MapToArrayPipe,
        AttributeExactPipe,
        AttributeContainsPipe
    ],
    imports: [
    ],
    providers: [
    ],
    exports: [
        NoMoreNinePipe,
        HighLightSearchPipe,
        DmsToDdPipe,
        DdToDmsPipe,
        FilterByPipe,
        DistanceToUserPipe,
        OrderByParamPipe,
        MapToArrayPipe,
        AttributeExactPipe,
        AttributeContainsPipe
    ]
} )
export class PipesModule { }
