import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LegalNoticesComponent } from 'src/app/components/legal-notices/legal-notices.component';
import { ComponentRef } from '@ionic/core';
import { SettingsPage } from 'src/app/pages/settings/settings.page';
import { VacDetailsPopinComponent } from 'src/app/components/vac-details/vac-details-popin/vac-details-popin.component';
import { VacDetailsComponent } from 'src/app/components/vac-details/vac-details.component';
import { ObsDetailsPopinComponent } from 'src/app/components/obstacles-details-popin/obstacles-details-popin.component';
import { InformationsPage } from 'src/app/pages/informations/informations.page';
import { MyMapsComponent } from 'src/app/components/my-maps/my-maps.component';
import { Observable } from 'rxjs';
import { ChooseTilesZoneComponent } from 'src/app/components/choose-tiles-zone/choose-tiles-zone.component';
import { DatePopupComponent } from 'src/app/components/azba-date-popup/date-popup.component';

@Injectable( {
  providedIn: 'root',
} )
export class ModalManagerService {
  constructor( private modalController: ModalController ) { }

  /**
   * Generic modal opening
   *
   * @private
   * @param {ComponentRef} page
   * @returns
   * @memberof ModalManagerService
   */
  private async presentModal(
    page: ComponentRef,
    data?: any,
    cssClass: string = 'modal-full-size'
  ) {
    // console.log( 'presentModal' );
    const modal = await this.modalController.create( {
      component: page,
      cssClass,
      componentProps: data,
    } );
    modal.present();

    return await modal.onDidDismiss();
  }

  /**
   * Open the legal notices modal
   *
   * @returns {Promise<any>}
   * @memberof ModalManagerService
   */
  public async presentLegalNotices(): Promise<any> {
    return this.presentModal( LegalNoticesComponent );
  }

  /**
   * Open the settings modal
   *
   * @returns {Promise<any>}
   * @memberof ModalManagerService
   */
  public async presentSettings(): Promise<any> {
    return this.presentModal( SettingsPage );
  }

  public async presentChooseTilesZone( data: any ): Promise<any> {
    return this.presentModal(
      ChooseTilesZoneComponent,
      data,
      'modal-wrapper-choose-zone'
    );
  }

  public async presentDesktopAzbaDates( data: any ): Promise<any> {
    return this.presentModal(
      DatePopupComponent,
      data,
      'modal-half-size'
    );
  }

  public async presentVacDetailsPopin( data: any ): Promise<any> {
    return this.presentModal(
      VacDetailsPopinComponent,
      data,
      'vac-details-popin'
    );
  }

  public async presentObsDetailsPopin( data: any ): Promise<any> {
    return this.presentModal(
      ObsDetailsPopinComponent,
      data,
      'vac-details-popin'
    );
  }

  public async presentVacDetails( data: any ): Promise<any> {
    return this.presentModal( VacDetailsComponent, data );
  }

  public async presentInformations( id: string = null ): Promise<any> {
    return this.presentModal( InformationsPage, id ? { idToFocus: id } : null );
  }

  public async presentMyMaps(): Promise<any> {
    return this.presentModal( MyMapsComponent );
  }
}
