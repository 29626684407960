import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Desubscribe } from 'src/models/classes/desubscribe';
import { ModalManagerService } from 'src/app/services/modal-manager/modal-manager.service';
import { VACS_TYPES } from 'src/models/enums';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { MyMapsService } from 'src/app/services/my-maps/my-maps.service';
import { MapSizes } from 'src/models/types';
import { Observable, Subject, Subscription } from 'rxjs';

@Component( {
  selector: 'dgac-header',
  templateUrl: './dgac-header.component.html',
  styleUrls: [ './dgac-header.component.scss' ],
} )
export class DgacHeaderComponent extends Desubscribe implements OnInit {

  nbAlerts: number;
  nbFavToUpdate: number; // TODO get the nb to dll
  authorizedTypes: VACS_TYPES[];

  @Output() groundsToggle = new EventEmitter<void>();
  private eventsSubscription: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();

  @Input() events: Observable<void>;

  constructor(
    private modal: ModalManagerService,
    private notifications: NotificationsService,
    private myMaps: MyMapsService ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.eventsSubscription = this.events.subscribe(() => this.propagateEventToChild());

    this.listenToNumbersOfAlerts();
    this.listenToAuthorizedTypes();

    this.addSubscription( this.myMaps.mapSizes.subscribe( ( m: MapSizes ) => {
      this.nbFavToUpdate = m.nbFavToUpdate;
    } ) );
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  propagateEventToChild(){
    // console.log('dgac header : event click on map');
    this.eventsSubject.next();
  }
  /**
   * Start listening to the numbers of alerts
   *
   * @memberof DgacHeaderComponent
   */
  private listenToNumbersOfAlerts(): void {
    this.addSubscription(
      this.notifications.unreadAlerts.subscribe( ( n: number ) => {
        this.nbAlerts = n;
      } )
    );
  }

  /**
   * Start listening to the authorized types (from settings)
   *
   * @memberof DgacHeaderComponent
   */
  private listenToAuthorizedTypes(): void {
    this.addSubscription( this.myMaps.authorizedTypes.subscribe(
      ( types: VACS_TYPES[] ) => this.authorizedTypes = types
    ) );
  }

  toggleGrounds(): void {
    this.groundsToggle.emit();
  }

  toggleSettings(): void {
    this.modal.presentSettings();
  }

  toggleAlerts( id: string = null ): void {
    this.modal.presentInformations( id );
  }

  toggleMyMaps(): void {
    this.modal.presentMyMaps();
  }

}
