import { TMA_TYPE, ZONES_TYPE } from '../enums';
import { IZone } from './points';

export interface IEditablePage {
    '@id': string;
    '@type': string;
    id: number;
    key: string;
    title: string;
    content: {
        fr: string;
        en: string;
    };
}

export interface IGround_WS {
    id: number;
    type: string;
    latitude: string;
    longitude: string;
    elevation: string;
}

export interface IMap_WS {
    id: number;
    type: string;
    fileName: string;
    version: string;
    fileSize: number;
}

export interface IVac_WS extends IVacDetails_WS {
    grounds: any[];
    maps: any[];
    runways: any[];
    frequencies: any[];
    information: any[];
}

export interface IVacDetails_WS {
    code: string;
    id: number;
    city: string;
}

export interface ICoordinates_WS {
    latitude: string;
    longitude: string;
    geoLatArc?: string;
    geoLongArc?: string;
    valRadiusArc?: number;
    codeType: COORDINATES_TYPE_WS;
}

export interface IZone_WS {
    coordinates: ICoordinates_WS[];
    initialCodeType: ZONES_TYPE_WS;
    codeClass: ZONES_CODE_CLASS_WS;
    frequency: any;
    zones: IZone_WS[];
    codeId: string;
    name: string;
    isRtba: boolean;
    uomDistVerLower: string;
    uomDistVerUpper: string;
    valDistVerLower: number;
    valDistVerUpper: number;
    codeDistVerUpper: string;
    codeDistVerLower: string;
}

export interface Activated_AZBA {
    codeId: string;
    timeSlots: AZBA_TimeSlot[];
}

export interface AZBA_Day {
    startDate: string;
    endDate: string;
}

export interface AZBA_TimeSlot {
    from: number;
    to: number;
    startDate: string;
    endDate: string;
    startHour: string;
    endHour: string;
}

export interface IAzba_Timeslot_WS {
    startTime: string;
    endTime: string;
}

export interface IAzba_Range_WS {
    rtba: string;
    startDate: string;
    endDate: string;
}

export enum COORDINATES_TYPE_WS {
    CIRCLE = 'CIRCLE',
    CCA = 'CCA',
    CWA = 'CWA',

}

export enum ZONES_TYPE_WS {
    RTBA = 'RTBA',
    FIR = 'FIR',
    SIV = 'SIV',
    TMA = 'TMA',
    CTR = 'CTR',
    R = 'R',
    D = 'D',
    P = 'P'
}

enum ZONES_CODE_CLASS_WS {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E'
}

export function toZoneType( value: ZONES_TYPE_WS ) {
    // console.log('toZoneType : ' + value);
    switch ( value ) {
        case ZONES_TYPE_WS.FIR: return ZONES_TYPE.FIR;
        case ZONES_TYPE_WS.D:
            return ZONES_TYPE.ZONES_D;
        case ZONES_TYPE_WS.R: return ZONES_TYPE.ZONES_R;
        case ZONES_TYPE_WS.SIV: return ZONES_TYPE.SIV;
        case ZONES_TYPE_WS.CTR: return ZONES_TYPE.CTR;
        case ZONES_TYPE_WS.P: return ZONES_TYPE.ZONES_P;
        case ZONES_TYPE_WS.TMA: return ZONES_TYPE.TMA;
        case ZONES_TYPE_WS.RTBA: return ZONES_TYPE.RTBA;
        default: console.warn( '[FIXME] unknow zone type : ' + value );
    }
}

export function toZoneId( zoneType: ZONES_TYPE, name: string ): string {
    return zoneType + '-' + name;
}

export function toZoneInfo( zone: IZone, isLower: boolean ) {
    const val: number = isLower ? zone.valDistVerLower : zone.valDistVerUpper;
    const uom: string = isLower ? zone.uomDistVerLower : zone.uomDistVerUpper;
    let cod: string = isLower ? zone.codeDistVerLower : zone.codeDistVerUpper;

    switch ( cod.toUpperCase() ) {
        case 'STD':
            cod = null;
            break;
        case 'HEI':
            cod = 'AGL';
            break;
        case 'ALT':
            cod = 'AMSL';
            break;
    }

    return `${ val } ${ uom ? '- ' + uom : '' } ${ cod ? '- ' + cod : '' }`;

}

export function toCodeClass( value: ZONES_CODE_CLASS_WS | string ): TMA_TYPE | string {
    switch ( value ) {
        case ZONES_CODE_CLASS_WS.A: return TMA_TYPE.A;
        case ZONES_CODE_CLASS_WS.B: return TMA_TYPE.B;
        case ZONES_CODE_CLASS_WS.C: return TMA_TYPE.C;
        case ZONES_CODE_CLASS_WS.D: return TMA_TYPE.D;
        case ZONES_CODE_CLASS_WS.E: return TMA_TYPE.E;
        default: return value;
    }
}
