import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
/**
 * SQLite Service
 */
export interface ISqlResult {
  /**
   * Id of the inserted row
   */
  insertId: number;

  /**
   * rows
   */
  rows: any;

  /**
   * Number of rows affected
   */
  rowsAffected: number;
}

@Injectable({
  providedIn: 'root',
})
export class SqliteService {
  /**
   * Contains the DB Object
   */
  private _DB: SQLiteObject = null;

  /**
   * Contains the DB name
   */
  private readonly _DB_NAME: string = 'data.mbtiles';
  private data: string = null;

  /**
   * Creates an instance of SqliteService.
   */
  constructor(
    private readonly sql: SQLite,
    private readonly platform: Platform
  ) {}

  isOpened(): boolean {
    return this._DB !== null;
  }

  /**
   * Initialize the database
   */
  async init(data: string): Promise<void> {
    if (this.data !== data) {
      this.data = data;
      if (this._DB !== null) {
        await this._DB.close();
      }

      this._DB = await this.sql.create({
        name: data,
        location: 'default',
      });
    }

    return;
  }

  /** Get parcel info */
  async getImageData(x, y, z): Promise<string> {
    const request =
      'SELECT BASE64(tile_data) as data FROM tiles WHERE zoom_level = ? AND tile_column = ? AND tile_row = ?';
    const params = [z, x, y];
    try {
      let result = await this.executeSql(request, params);
      result = this.fetch(result);
      return result ? result.data : '';
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  /**
   * Execute the SQL passed in parameter with the params passed in second parameter
   */
  async executeSql(request: string, params?: Array<any>) {
    const message = `Request : ${request} \n Params : ${JSON.stringify(
      params
    )}`;

    return this._DB.executeSql(request, params).catch(async (error) => {
      console.error('SQL ERROR:', `${error.message} / Request: ${request}`);
    });
  }

  /**
   * Fetch All results of sql result
   */
  fetchAll(result: ISqlResult): any[] {
    const sqlResult = [];
    for (let i = 0; i < result.rows.length; i++) {
      sqlResult.push(result.rows.item(i));
    }

    return sqlResult;
  }

  /**
   * Fetch the result of sql request
   */
  fetch(result: ISqlResult) {
    if (result.rows.length > 0) {
      return result.rows.item(0);
    } else {
      return null;
    }
  }

  /**
   * Generic function to execute a sql request
   */
  request(request: string, params?: Array<string | number>) {
    return new Promise((resolve, reject) => {
      const sqlResult = [];
      this._DB.transaction((tx) => {
        tx.executeSql(
          request,
          params,
          (_, result) => {
            for (let i = 0; i < result.rows.length; i++) {
              sqlResult.push(result.rows.item(i));
            }
            if (result.insertId) {
              resolve(result.insertId);
            }
            resolve(sqlResult);
          },
          (error) => {
            reject(error);
          }
        );
      });
    });
  }
}
