import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Subscription, BehaviorSubject, Observable, timer, from } from 'rxjs';
import { ICoords } from 'src/models/interfaces/ground';
import { Platform } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';

@Injectable( {
  providedIn: 'root',
} )
export class GeolocationService {
  static DEFAULT_COORDS = { lat: 46.7111, lon: 1.7191 };
  static INTERVAL_TO_CHECK = 5000;
  static OPTIONS = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 20000,
  };

  private isGeolocationActivated = new BehaviorSubject<boolean>( false );
  get geolocActive(): boolean {
    return this.isGeolocationActivated.value;
  }

  private geolocationSubscription: Subscription;
  private geolocationSubject: BehaviorSubject<ICoords> =
    new BehaviorSubject<ICoords>( null );
  get coords(): ICoords {
    return this.geolocationSubject.getValue();
  }

  private actualTimeout: NodeJS.Timer;

  constructor( private geolocation: Geolocation, private platform: Platform ) {
    this.platform.ready().then( () => {
      if ( this.platform.is( 'cordova' ) ) {
        this.watchPosition();
      } else {
        console.warn(
          '*** geolocation:deactivated for web. Default coords used !'
        );
        // this.isGeolocationActivated.next( false );
        this.watchPosition();
      }
    } );
  }

  /**
   * Listen to geolocation changes
   *
   * @private
   * @memberof GeolocationService
   */
  private watchPosition(): void {
    this.geolocationSubscription = timer(
      0,
      GeolocationService.INTERVAL_TO_CHECK
    )
      .pipe(
        switchMap( () =>
          from( this.geolocation.getCurrentPosition( GeolocationService.OPTIONS ) )
        )
      )
      .subscribe(
        ( data ) => {
          // console.log('**** CHECKING GEOLOC ****');
          if ( data.coords ) {
            if ( !this.geolocActive ) {
              this.isGeolocationActivated.next( true );
            }
            this.geolocationSubject.next( {
              lat: data.coords.latitude,
              lon: data.coords.longitude,
              heading: data.coords.heading,
            } );
          }
        },
        () => {
          if ( this.geolocActive ) {
            this.isGeolocationActivated.next( false );
          }
        }
      );
  }

  /**
   * Check on a interval if the geolocation is authorized
   *
   * @private
   * @param {boolean} [instant=false]
   * @memberof GeolocationService
   */
  private checkConnection( instant: boolean = false ): void {
    clearTimeout( this.actualTimeout );
    this.actualTimeout = setTimeout(
      () => {
        if ( this.geolocationSubscription ) {
          this.geolocationSubscription.unsubscribe();
        }
        this.geolocation.getCurrentPosition().then(
          () => {
            this.watchPosition();
          },
          () => {
            if ( this.geolocActive ) {
              this.isGeolocationActivated.next( false );
            }
            this.geolocationSubject.next( {
              lat: GeolocationService.DEFAULT_COORDS.lat,
              lon: GeolocationService.DEFAULT_COORDS.lon,
            } );
            this.checkConnection();
          }
        );
      },
      instant ? 0 : GeolocationService.INTERVAL_TO_CHECK
    );
  }

  public listenToUserLocation(): Observable<ICoords> {
    return this.geolocationSubject.asObservable();
  }

  public listenToActivation(): Observable<boolean> {
    return this.isGeolocationActivated.asObservable();
  }

  public askPermission(): Promise<any> {
    return this.geolocation.getCurrentPosition();
  }
}
